export const shows = [
  {
    action: {
      action_id: "https://tv.apple.com/us/episode/pilot/umc.cmc.1d03um8ktplxosrzsl65diew6?at=1000l3V2",
      action_type: "VIEW_DEEPLINK",
    },
    image_l: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/TMDB/image_repo/shows/tt16912512/backdrop.webp",
    thumbnail: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt16912512_secondary_thumbnail_tv.webp",
    intended_application: "web",
    title: "Manhunt",
    subtitle: "3.4 ⭐ · 2024",
    description:
      "A conspiracy thriller about one of the best known but least understood crimes in history. This is the astonishing story of the hunt for John Wilkes Booth in the aftermath of Abraham Lincoln's assassination—as the fate of the country hangs in the balance.",
    item_type: "show",
    item_id: "tt16912512",
    banner_web_m: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt16912512_secondary_tv.webp",
    meta: {
      year: "1970",
      genres: [
        {
          id: 18,
          name: "Drama",
        },
        {
          id: 10768,
          name: "War & Politics",
        },
      ],
    },
  },
  {
    action: { action_id: "https://www.netflix.com/us/title/81614129", action_type: "VIEW_DEEPLINK" },
    image_l: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/TMDB/image_repo/shows/tt26227818/backdrop.webp",
    thumbnail: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt26227818_secondary_thumbnail_tv.webp",
    intended_application: "web",
    title: "Turning Point: The Bomb and the Cold War",
    subtitle: "3.9 ⭐ · 2024",
    description:
      "With firsthand accounts and access to prominent figures around the world, this comprehensive docuseries explores the Cold War and its aftermath.",
    item_type: "show",
    item_id: "tt26227818",
    banner_web_m: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt26227818_secondary_tv.webp",
    meta: {
      year: "2024",
      genres: [
        {
          id: 99,
          name: "Documentary",
        },
      ],
    },
  },
];
