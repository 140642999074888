import React from "react";
import styles from "./ContentYouLike.module.css";
import { useMst } from "../../store";
import ContentCard from "./ContentCard";
import { observer } from "mobx-react-lite";
import FolksEmptyMessage from "../../components/folks-empty-message/FolksEmptyMessage";

const ContentYouLike = () => {
  const { auditStore, actionStore } = useMst();

  const handleDelete = (media_id) => {
    actionStore.setLikeDislike(media_id, null);
  };

  return (
    <section className={styles.contentSection}>
      <h2 className={styles.sectionTitle}>Content You Like</h2>
      <div className={styles.contentGrid}>
        {auditStore?.likedContent?.map((content, index) => (
          <ContentCard key={content + index} mediaId={content} handleDelete={handleDelete} />
        ))}
      </div>
      {!auditStore?.likedContent?.length ? (
        <div className={styles.loadingWrapper}>
          <FolksEmptyMessage />
        </div>
      ) : null}
    </section>
  );
};

export default observer(ContentYouLike);
