import React, { useRef, useState } from "react";
import { useMst } from "../../store";
import { validateEmail, validatePasswordText } from "../../utils/helper";

import { Row, Col, Modal } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Logo from "../component/logo/Logo";
import Footer from "../component/footer/Footer";
import { AuthButton } from "../component/AuthButton";
import { AuthInput } from "../component/AuthInput";
import { AuthInputPassWord } from "../component/AuthInputPassWord";

import styles from "./forgotPass.module.css";

const ForgotPass = ({ history, setForgotpage }) => {
  const { auth, notificationStore } = useMst();

  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrorMsg] = useState("");

  const [isOtpGenerated, setIsOtpGenerated] = useState(false);

  const [OTP, setOTP] = useState("");
  const [OTPErrorMsg, setOTPErrorMsg] = useState("");

  const [password, setPassword] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [cnfPasswordErrorMsg, setCnfPasswordErrorMsg] = useState("");

  const [loading, setLoading] = useState(false);
  const [apiValidationError, setApiValidationError] = useState("");

  const backToLogin = () => {
    // reset fields.
    setEmail("");
    setEmailErrorMsg("");

    setIsOtpGenerated(false);
    setOTP("");
    setOTPErrorMsg("");
    setApiValidationError("");

    setPassword("");
    setConfirmPassword("");
    setPasswordErrorMsg("");

    setLoading(false);

    // Go to login screen.
    // history.push("/login");
    setForgotpage(false);
  };

  const getOtp = async (e) => {
    e.preventDefault();

    if (!email) {
      setEmailErrorMsg("Email is required!");
      return;
    }
    if (!validateEmail(email)) {
      setEmailErrorMsg("Please Enter a valid email address");
      return;
    }

    const payload = {
      username: email,
    };

    setLoading(true);
    try {
      const res = await auth.forgotPassword(payload);
      setLoading(false);
      setIsOtpGenerated(true);
      notificationStore.triggerSuccess(res?.message);
    } catch (error) {
      setLoading(false);
      if (error?.status === 401) {
        setEmailErrorMsg("This e-mail is not registered");
      } else {
        notificationStore.triggerError(error?.message);
      }
    }
  };
  const backBtnVisible = () => {
    if (OTP || password || confirmPassword) {
      return false;
    } else {
      return true;
    }
  };

  const confirmReset = async (e) => {
    e.preventDefault();

    if (!OTP.trim()) {
      setOTPErrorMsg("OTP is required");
      return;
    }

    if (!password) {
      setPasswordErrorMsg("Password is required");
      return;
    }

    if (!validatePasswordText(password).status) {
      setApiValidationError(validatePasswordText(password).message);
      return;
    }

    if (password !== confirmPassword) {
      setCnfPasswordErrorMsg("password do not match");
      return;
    }

    const payload = {
      username: email,
      code: OTP,
      password: password,
    };

    setLoading(true);
    try {
      await auth.confirmForgotPassword(payload);
      setLoading(false);
      notificationStore.triggerSuccess("Reset successful, Login with your new credentials.");
      backToLogin();
    } catch (error) {
      setLoading(false);
      if (error?.status === 400 && error?.message === "Invalid code.") {
        setApiValidationError("Entered OTP is invalid, Please enter correct OTP");
      } else if (error?.status === 400 && error?.message === "Limit Exceeded") {
        setApiValidationError("Attempt limit exceeded! Try again after some time.");
      } else {
        notificationStore.triggerError("Server Error, Please Try again!");
      }
    }
  };

  const ForgotPassForm = () => (
    <form className={styles.form}>
      {isOtpGenerated ? (
        <div className={styles.otpContainer}>
          <div>
            <div>
              <div style={{ fontSize: "1.4rem", textAlign: "center", marginBottom: "5px", marginTop: "-10px" }}>
                Enter the 6 digit code sent to <br />
                {email}
              </div>
              <div className={styles.authText}>Code</div>
              <AuthInput
                type="text"
                value={OTP}
                onChange={(e) => {
                  setOTP(e.target.value);
                  setOTPErrorMsg("");
                  setPasswordErrorMsg("");
                  setCnfPasswordErrorMsg("");
                  if (apiValidationError) {
                    setApiValidationError("");
                  }
                }}
                placeholder="Enter Code"
                autoComplete="one-time-code"
              />
              {/* {OTPErrorMsg ? <p className={styles.errorText}>{OTPErrorMsg}</p> : null} */}
            </div>
            <div>
              <div className={styles.authText}>Password</div>
              <AuthInputPassWord
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  setOTPErrorMsg("");
                  setPasswordErrorMsg("");
                  setCnfPasswordErrorMsg("");
                  if (apiValidationError) {
                    setApiValidationError("");
                  }
                }}
                placeholder="Enter Password"
              />
              {/* {passwordErrorMsg ? <p className={styles.errorText}>{passwordErrorMsg}</p> : null} */}
            </div>
            <div>
              <div className={styles.authText}>Confirm Password</div>
              <AuthInputPassWord
                type="password"
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setOTPErrorMsg("");
                  setPasswordErrorMsg("");
                  setCnfPasswordErrorMsg("");
                  if (apiValidationError) {
                    setApiValidationError("");
                  }
                }}
                placeholder="Enter Confirm Password"
              />
              {/* {cnfPasswordErrorMsg ? <p className={styles.errorText}>{cnfPasswordErrorMsg}</p> : null} */}
            </div>
          </div>
          <Row justify="center">
            <div>
              <AuthButton
                className={styles.submitbtn}
                onClick={confirmReset}
                loading={loading}
                disabled={loading || backBtnVisible()}
              >
                Submit
              </AuthButton>
            </div>
            <div>
              {backBtnVisible() && (
                <AuthButton className={styles.backbtn} onClick={backToLogin} loading={loading} disabled={loading}>
                  Back
                </AuthButton>
              )}
              {apiValidationError && <div className={styles.apiValidationError}>{apiValidationError}</div>}
              {OTPErrorMsg || passwordErrorMsg || cnfPasswordErrorMsg ? (
                <p className={styles.apiValidationError}>
                  <div>
                    <div>{OTPErrorMsg}</div>
                    <div>{passwordErrorMsg}</div>
                    <div>{cnfPasswordErrorMsg}</div>
                  </div>
                </p>
              ) : null}
            </div>
          </Row>
        </div>
      ) : (
        <div style={{ marginTop: "6rem" }}>
          <div style={{ fontSize: "20px" }}>Email</div>
          <AuthInput
            type="email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (emailErrorMsg) {
                setEmailErrorMsg("");
              }
            }}
            placeholder="Registered E-mail"
            onPressEnter={getOtp}
          />

          <Row justify="center">
            <AuthButton className={styles.button} onClick={getOtp} loading={loading} disabled={loading}>
              Next
            </AuthButton>
          </Row>
          {emailErrorMsg ? (
            <p className={styles.apiValidationError} style={{ marginTop: "100px" }}>
              {emailErrorMsg}
            </p>
          ) : null}
        </div>
      )}
    </form>
  );

  return (
    <div className={styles.formContainer}>
      <Row justify="center">
        <Col span={12}>
          <Row justify="center">
            <div className={styles.mainContainer}>
              <div className={styles.tabContainer}>
                <div className={styles.tabMenu}>
                  {isOtpGenerated ? <div>OTP Verification</div> : <div>Forgot Password</div>}
                </div>
              </div>
              <div>{ForgotPassForm()}</div>
            </div>
          </Row>
        </Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default ForgotPass;
