import * as localforage from "localforage";

localforage.config({
  name: "localStorage", // Name of the database
  storeName: "localStorage", // Name of the data store
  version: 1.0, // Database version
  description: "Local storage for my web app", // Description for the database
  size: 5 * 1024 * 1024, // Size of the database in bytes (5 MB in this example)
  driver: [localforage.WEBSQL, localforage.INDEXEDDB], // Preferred storage drivers in order
});

const localForageWrapper = {
  async setItem(key, value) {
    await localforage.setItem(key, value);
  },

  async getItem(key) {
    return await localforage.getItem(key);
  },

  async removeItem(key) {
    await localforage.removeItem(key);
  },

  async clear() {
    await localforage.clear();
  },

  key(index) {
    return localforage.keys().then((keys) => keys[index]);
  },

  async length() {
    return await localforage.length();
  },

  async iterate(callback) {
    await localforage.iterate(callback);
  },
};

// Polyfill for StorageEvent, because localForage does not support it
// localForageWrapper.__proto__ = Storage.prototype;

export default localForageWrapper;
