import styled from "styled-components";

export const SliderContainer = styled.div`
  height: 100%;
  max-height: 100%;
  position: relative;
  .arrowButton {
    opacity: 0;
    transition: opacity 1s;
  }

  &:hover {
    .arrowButton:not(.disable-arrow-button) {
      opacity: 1;
    }
  }

  .disable-arrow-button {
    opacity: 0;
  }

  .slick-list {
    overflow: ${(props) => (props.overflowVisible ? "visible" : "hidden")};
  }

  .slick-track {
    margin: 0;
  }
`;
