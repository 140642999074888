import { types } from "mobx-state-tree";

export const playlistModel = types
  .model({
    analytics_data: types.maybeNull(
      types.model({
        browse_count: types.maybeNull(types.number),
        score: types.maybeNull(types.number),
        subscribed_count: types.maybeNull(types.number),
        watch_count: types.maybeNull(types.number),
      })
    ),
    author_name: "",
    image_hd_url: types.maybeNull(types.string),
    image_url: types.maybeNull(types.string),
    is_subscribe: types.boolean,
    privacy: "",
    timestamp: "",
    user_id: "",
    video_count: types.maybeNull(types.number),
    watchlist_id: types.number,
    watchlist_name: "",
    is_owner: types.boolean,
  })
  .actions((self) => ({
    updatePlaylist(name, privacy) {
      self.watchlist_name = name;
      self.privacy = privacy;
    },
  }));

export const initialPlaylistState = {
  analytics_data: {
    browse_count: 0,
    score: 0,
    subscribed_count: 0,
    watch_count: 0,
  },
  author_name: "",
  image_hd_url: null,
  image_url: null,
  is_subscribe: false,
  privacy: "",
  timestamp: "",
  user_id: "",
  video_count: 0,
  watchlist_id: 0,
  watchlist_name: "",
  is_owner: false,
};
