// function for size parsing
export function parseSize(size) {
  const reSize = /^([-+]?(?:\d+(?:\.\d+)?|\.\d+))([a-z]{2,4}|%)?$/;
  size = "" + size;

  const [, value = 0, unit = "px"] = reSize.exec(size) || [];

  return {
    value: parseFloat(value),
    str: value + unit,
    unit,
  };
}

// function for slicing name string
export const getInitialAlphabet = (string) => {
  let names = string?.toString().trim().split(" "),
    initials = names[0][0]?.toUpperCase();

  if (names.length > 1) {
    initials += names[names.length - 1][0]?.toUpperCase();
  }
  return initials;
};
