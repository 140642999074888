import { types } from "mobx-state-tree";

export const ottAppModel = types.model("ott_app_model", {
  id: types.number,
  image: types.maybeNull(types.string),
  image_hd: types.maybeNull(types.string),
  is_supported: types.maybeNull(types.boolean),
  title: types.maybeNull(types.string),
});

const channelModel = types.model("sling_channel_model", {
  id: types.maybeNull(types.string),
  channel_group_id: types.maybeNull(types.string),
  name: types.string,
  // callSign: types.string,
  // priority: types.number,
  // channel_logo: types.string,
  // category: types.string,
});

const packageModel = types.model("sling_package_model", {
  id: types.number,
  title: types.string,
  channels: types.optional(types.array(channelModel), []),
});

export const bundleModel = types.model("sling_bundle_model", {
  title: types.string,
  multiple_select: types.boolean,
  packages: types.optional(types.array(packageModel), []),
});

const slingSettingModel = types.model("sling_setting_model", {
  base_packages: types.number,
  domestic_extras: types.optional(types.array(types.number), []),
});

export const contentSettingsModel = types.model("content_settings_model", {
  showExperimentalFeatures: false,
  showSlingExperimentalFeature: true,
  showShopExperimentalFeature: true,
  showFriendsExperimentalFeature: true,

  showInstagramFeed: false,
  showMediaProviderIconsOnTile: true,
  showContentFeedTitle: true,
  showAllOttApps: false,
  showTutorial: false,
  hasSlingTV: true,

  coachmarkFrequency: 0,
  showNotifications: true,
  selectottapps: types.optional(types.array(ottAppModel), []),
  newSelectottapps: types.optional(types.array(types.number), []),
  slingSelectedPackages: slingSettingModel,

  // App settings: (do not modify).
  categoriesType: types.maybe(types.number),
  discoveryMXTImeout: types.maybe(types.number),
  syncContactsCount: types.maybe(
    types.model("contacts_sync_data_model", {
      count: types.maybe(types.number),
      lastSyncTimestamp: types.maybe(types.number),
    })
  ),
  showHapticFeedback: types.maybe(types.boolean),
});

export const initialContentSettingsStoreState = {
  contentSettings: {
    showExperimentalFeatures: false,
    showSlingExperimentalFeature: true,
    showShopExperimentalFeature: true,
    showFriendsExperimentalFeature: true,

    showInstagramFeed: true,
    showMediaProviderIconsOnTile: true,
    showContentFeedTitle: false,
    showAllOttApps: false,
    showTutorial: false,
    hasSlingTV: true,

    coachmarkFrequency: 0,
    showNotifications: false,
    selectottapps: [],
    newSelectottapps: [],
    slingSelectedPackages: {
      base_packages: 3,
      domestic_extras: [],
    },
  },
  allOttApps: [],
};
