import { flow, types } from "mobx-state-tree";
import ContentAPI from "../../api/ContentServiceAPI";
import SearchAPI from "../../api/SearchApi";
import { EP_ROOT as __ } from "../../utils/endpoints";

import { ssReadObj, ssWriteObj } from "../../api/session-cache/sessionCache";
import { ssDetailsKeyGenerator, ssGenreDetailsKeyGenerator } from "../../api/session-cache/sessionCacheKeys";

import { sanitizeMediaRows } from "./filters/contentFilter";
import { nestedSeasonsToEpisodeMediaRow } from "./filters/mdpFilter";

const MdpStoreModel = {};

const MdpStoreActions = (self) => ({
  // **ott details**
  getOttDetails: flow(function* getOttDetails(imdb_id) {
    // Session Storage.
    try {
      const { success, data, message } = ssReadObj(ssDetailsKeyGenerator(imdb_id));
      if (success) {
        const filteredMediaRows = sanitizeMediaRows(data?.rowData);
        return Promise.resolve({ success: true, mediaHeader: data?.mediaHeader, rowData: filteredMediaRows });
      } else {
        console.info(`Cache miss on getting details for ${imdb_id}! \nReason: ${message} `);
      }
    } catch (e) {
      console.error(e);
    }

    // API call.
    try {
      const getParams = { params: { content_id: imdb_id } };
      const res = yield SearchAPI.get(__.SEARCH_SERVICE.MEDIA_DETAILS, getParams);
      if (res?.status === 200 && res?.data?.data?.media_header && res?.data?.data?.media_rows) {
        ssWriteObj(ssDetailsKeyGenerator(imdb_id), {
          mediaHeader: res.data.data.media_header,
          rowData: res.data.data.media_rows,
        });
        const filteredMediaRows = sanitizeMediaRows(res.data.data.media_rows);
        return Promise.resolve({ success: true, mediaHeader: res.data.data.media_header, rowData: filteredMediaRows });
      } else {
        throw new Error("Something went wrong while fetching ott details!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error?.message || "Something went wrong while fetching ott details!",
      });
    }
  }),

  // **show Details**
  getShowDetails: flow(function* getShowDetails(imdb_id) {
    // Session storage.
    try {
      const { success, data, message } = ssReadObj(ssDetailsKeyGenerator(imdb_id));
      if (success) {
        const filteredMediaRows = sanitizeMediaRows(data?.rowData);
        return Promise.resolve({ success: true, mediaHeader: data?.mediaHeader, rowData: filteredMediaRows });
      } else {
        console.info(`Cache miss on getting details for ${imdb_id}! \nReason: ${message} `);
      }
    } catch (e) {
      console.error(e);
    }

    // API call.
    try {
      const getParams = { params: { content_id: imdb_id } };
      const res = yield SearchAPI.get(__.SEARCH_SERVICE.MEDIA_DETAILS, getParams);
      if (res?.status === 200 && res?.data?.data?.media_header && res?.data?.data?.media_rows) {
        const episodeMediaRow = nestedSeasonsToEpisodeMediaRow(res?.data?.media_header?.item?.seasons);
        const consolidatedMediaRow = [
          ...episodeMediaRow,
          ...(res.data.data.media_rows?.length ? res.data.data.media_rows : []),
        ];
        ssWriteObj(ssDetailsKeyGenerator(imdb_id), {
          mediaHeader: res.data.data.media_header,
          rowData: consolidatedMediaRow,
        });
        const filteredMediaRows = sanitizeMediaRows(consolidatedMediaRow);
        return Promise.resolve({ success: true, mediaHeader: res.data.data.media_header, rowData: filteredMediaRows });
      } else {
        throw new Error("Something went wrong while fetching show details!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching show details!",
      });
    }
  }),

  // **person details**
  getPersonDetails: flow(function* getPersonDetails(imdb_id) {
    // Session Storage.
    try {
      const { success, data, message } = ssReadObj(ssDetailsKeyGenerator(imdb_id));
      if (success) {
        const filteredMediaRows = sanitizeMediaRows(data?.rowData);
        return Promise.resolve({ success: true, mediaHeader: data?.mediaHeader, rowData: filteredMediaRows });
      } else {
        console.info(`Cache miss on getting details for ${imdb_id}! \nReason: ${message} `);
      }
    } catch (e) {
      console.error(e);
    }

    // API call.
    try {
      const getParams = { params: { person_id: imdb_id } };
      const res = yield SearchAPI.get(__.SEARCH_SERVICE.PERSON_DETAILS, getParams);
      if (res?.status === 200 && res?.data?.media_header && res?.data?.media_rows) {
        ssWriteObj(ssDetailsKeyGenerator(imdb_id), {
          mediaHeader: res.data.media_header,
          rowData: res.data.media_rows,
        });
        const filteredMediaRows = sanitizeMediaRows(res.data.media_rows);
        return Promise.resolve({ success: true, mediaHeader: res.data.media_header, rowData: filteredMediaRows });
      } else {
        throw new Error("Something went wrong while fetching person details!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching person details!",
      });
    }
  }),

  // **genre details**
  getGenreDetails: flow(function* getGenreDetails(genre_id) {
    // Session Storage.
    try {
      const { success, data, message } = ssReadObj(ssGenreDetailsKeyGenerator(genre_id));
      if (success) {
        const filteredMediaRows = sanitizeMediaRows(data?.rowData);
        return Promise.resolve({ success: true, mediaHeader: data?.mediaHeader, rowData: filteredMediaRows });
      } else {
        console.info(`Cache miss on getting genre details for ${genre_id}! \nReason: ${message} `);
      }
    } catch (e) {
      console.error(e);
    }

    // API call.
    try {
      const baseURL = __.SEARCH_SERVICE.GENRE_DETAILS + genre_id;
      const res = yield ContentAPI.get(baseURL);
      if (res?.status === 200 && res?.data?.data?.media_header && res?.data?.data?.media_rows) {
        ssWriteObj(ssGenreDetailsKeyGenerator(genre_id), {
          mediaHeader: res.data.data.media_header,
          rowData: res.data.data.media_rows,
        });
        const filteredMediaRows = sanitizeMediaRows(res.data.data.media_rows);
        return Promise.resolve({ success: true, mediaHeader: res.data.data.media_header, rowData: filteredMediaRows });
      } else {
        throw new Error("Something went wrong while fetching genre details!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching genre details!",
      });
    }
  }),

  // setMediaHeaderFromCard(cardData) {
  //   self.mediaHeader = {
  //     item_id: cardData?.item_id || cardData?.external_ids?.imdb_id,
  //     item_type: cardData?.item_type,
  //     title: cardData?.title,
  //     subtitle: cardData?.subtitle,
  //     image: cardData?.image,
  //     image_hd: cardData?.image_hd,
  //     video: cardData?.video,
  //     description: cardData?.description,
  //     actions: cardData?.actions?.filter(isValidAction),
  //   };
  // },

  // setMediaHeader(resData) {
  //   self.mediaHeader = {
  //     ...self.mediaHeader,
  //     item_id: self.mediaHeader?.item_id || resData?.item?.item_id || resData?.item?.external_ids?.imdb_id,
  //     item_type: self.mediaHeader?.item_type || resData?.item?.item_type,
  //     title: self.mediaHeader?.title || resData?.item?.title,
  //     subtitle: self.mediaHeader?.subtitle || resData?.item?.subtitle,
  //     image: self.mediaHeader?.image || resData?.item?.image,
  //     image_hd: self.mediaHeader?.image_hd || resData?.item?.image_hd,
  //     video: self.mediaHeader?.video || resData?.item?.video,
  //     description: self.mediaHeader?.description || resData?.item?.description || resData?.description,
  //     actions: headerActionMerger(self.mediaHeader?.actions, resData?.item?.actions?.filter(isValidAction)),
  //   };
  // },

  // resetDetailsPage() {
  //   self = initialMdpState;
  // },

  // if (self.currentItemImdbId === imdb_id) {
  //   self.setMediaHeader(data?.mediaHeader);
  //   self.mediaRows = {
  //     loading: false,
  //     success: true,
  //     message: "success",
  //     data: filteredMediaRows,
  //   };
  // }

  // loadDetailsPageData({ imdb_id, media_type }) {
  //   self.currentItemImdbId = imdb_id.toString();
  //   if (media_type === "ott") {
  //     self.getOttDetails(imdb_id);
  //   } else if (media_type === "show") {
  //     self.getShowDetails(imdb_id);
  //   } else if (media_type === "facet") {
  //     self.getPersonDetails(imdb_id);
  //   } else if (media_type === "genre") {
  //     self.getGenreDetails(imdb_id);
  //   }
  // },
});

const MdpStoreViews = (self) => ({});

export const mdpStore = types.model("mdpStore", MdpStoreModel).actions(MdpStoreActions).views(MdpStoreViews);
