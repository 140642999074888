import React, { useState, useEffect, useCallback } from "react";
import { useMst } from "../../store";
import { useHistory, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { REDIRECTION_ROUTES } from "../../utils/redirectionSettings";
import { URLS } from "../../routes/routeURL";

// Components
import FolksAvatar from "../../components/folks-avatar/FolksAvatar";
import FolksModal from "../../components/folks-modal/FolksModal";
import { ModalHeadingText } from "../../components/typography/ModalText";
import { ModalFooterButton } from "../../components/folks-modal/ModalFooterButton";
import { Drawer, Modal, Space } from "antd";
import { FolksMobileHeader } from "./styledComponent";

// Icons
import { CloseOutlined } from "@ant-design/icons";
import { MdOutlineSearch, MdMenu, MdClose } from "react-icons/md";
import hamburger from "../../assets/images/hamburger.svg";

// CSS Module [Scope => Header Component]
import styles from "./header.module.css";

// Images
import logo from "../../assets/images/fm_logo.svg";
import LoginFolksModal from "../../components/folks-modal/LoginFolksModal";
import Login from "../../auth/login/Login";
import Register from "../../auth/register/Register";
import Logo from "../../auth/component/logo/Logo";
import loginImg from "../../assets/images/Log_In_bg_image.png";
import Tab from "../../auth/component/tab/Tab";
import ForgotPass from "../../auth/forget-password/ForgotPass";
import { observer } from "mobx-react-lite";
import PrivacyPolicy from "../../folks-landing-web/privacy-policy/PrivacyPolicy";
import TermsOfUse from "../../folks-landing-web/terms-of-use/TermsOfUse";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import OTP from "../../auth/otp/OTP";

const AppHeader = (props) => {
  const { headerData, mobileHeader } = props;
  const { auth, profileStore, loginModalVisible, setLoginModalVisible, setAuthActiveTabIndex, authActiveTabIndex } =
    useMst();
  const history = useHistory();
  const location = useLocation();
  const [isLogoutModalVisible, setIsLogoutModalVisible] = useState(false);
  const [headerDark, setHeaderDark] = useState(false);
  const [currentMenuKey, setCurrentMenuKey] = useState(location?.pathname);
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;
  const [forgotpage, setForgotpage] = useState(false);
  const [waitingForOTP, setWaitingForOTP] = useState(false);
  const [termsOfUse, setTermsOfUse] = useState(false);
  const [privacyPolicy, setPrivacyPolicy] = useState(false);
  const [resetPassPage, setResetPassPage] = useState({
    status: false,
    email: "",
  });
  const [preloadedImage, setPreloadedImage] = useState(null);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  window.onscroll = function () {
    scrollFunction();
  };
  function scrollFunction() {
    if (document.body.scrollTop > 30 || document.documentElement.scrollTop > 30) {
      setHeaderDark(true);
    } else {
      setHeaderDark(false);
    }
  }

  const handleOk = async () => {
    setIsLogoutModalVisible(false);
    try {
      await auth.logout();
      window.location.href = REDIRECTION_ROUTES.HEADER_LOGOUT;
    } catch (e) {
      // do something
    }
  };

  const handleLogoutModalCancel = () => {
    setIsLogoutModalVisible(false);
  };

  const handleLogout = () => {
    setIsLogoutModalVisible(true);
  };

  const handleMenuItemClick = (tab) => {
    if (tab.item_id === "log-out") {
      handleLogout();
    } else if (tab.item_id === "log-in") {
      setLoginModalVisible(true); // open auth modal
    } else if (tab.path) {
      setCurrentMenuKey(tab?.path);
      history.push(tab.path);
    }
  };

  const handleClose = () => {
    setLoginModalVisible(false); // close auth modal
    setTermsOfUse(false);
    setPrivacyPolicy(false);
    setForgotpage(false);
    setAuthActiveTabIndex("login"); // set login active tab
  };

  const handleTermPrivacy = () => {
    if (termsOfUse) {
      setTermsOfUse(false);
    } else {
      setPrivacyPolicy(false);
    }
  };

  const handleHomeButton = () => {
    if (mobileHeader) {
      // Redirect to app.
      window.location = `https://go.folksmedia.com?apn=com.folksmediaapp&ibi=com.adcuratio.folksmedia&isi=1579558800&link=${encodeURIComponent(
        `https://app.folksmedia.com/`
      )}`;
    } else {
      history.push(URLS.CONTENT_HOME);
    }

    // history.push(URLS.CONTENT_HOME);
  };

  useEffect(() => {
    headerData?.left?.forEach((obj) => {
      if (obj.path === location.pathname) {
        // setCurrentLeftMenuKey(obj.item_id);
      }
    });
  });

  useEffect(() => {
    if (location?.pathname.includes(URLS.ACCOUNT_SETTINGS)) {
      const conditionalKey = auth.isGuestUser ? URLS.ACCOUNT_SETTINGS : URLS.ACCOUNT_EDIT_PROFILE;
      setCurrentMenuKey(conditionalKey);
    } else if (location?.pathname === URLS.CONTENT_HOME) {
      setCurrentMenuKey(URLS.CONTENT_HOME);
    }
  }, [location?.pathname]);

  const selectedMenuStyle = useCallback(
    (item, icon = "") => {
      if (icon === "seach-icon" && currentMenuKey === item?.path) {
        return { paddingRight: "5px" };
      }
      if (currentMenuKey === item?.path) {
        return { fontFamily: "notosans-bold", fontSize: "17px" };
      }
      return {};
    },
    [currentMenuKey]
  );

  const headerItemsLeft = headerData?.left?.map((item) => {
    return {
      key: `${item?.item_id}`,
      label: (
        <span
          key={`${item?.item_id}`}
          className={styles.headerMenuItem}
          onClick={() => handleMenuItemClick(item)}
          style={{ ...selectedMenuStyle(item) }}
        >
          {item.title}
        </span>
      ),
    };
  });

  const headerItemsRight = headerData?.right?.map((item) => {
    return {
      key: `${item?.item_id}`,
      label: (
        <div
          key={`${item?.item_id}`}
          style={{
            position: "relative",
            ...(item.item_id === "my-account" && {
              marginRight: "20px",
              display: "flex",
              alignItems: "center",
              gap: "15px",
            }),
            ...selectedMenuStyle(item),
          }}
          className={
            item.item_id === "sign-up"
              ? styles.signUpBtn
              : item.item_id === "log-in"
              ? styles.signUpBtn
              : styles.headerMenuItem
          }
          onClick={() => handleMenuItemClick(item)}
        >
          {item.item_id === "search" ? (
            <div
              style={{
                position: "absolute",
                top: "-6px",
                right: "45px",
                fontSize: "22px",
                ...selectedMenuStyle(item, "seach-icon"),
              }}
            >
              <MdOutlineSearch />
            </div>
          ) : null}
          {item.title}

          {item.item_id === "my-account" ? (
            <div>
              <FolksAvatar
                name={profileStore?.getUserName || ""}
                round={true}
                size="32px"
                src={profileStore?.getAvatarImageUrl || ""}
              />
            </div>
          ) : null}
        </div>
      ),
    };
  });

  const handleRedirection = (name, message = "") => {
    onClose();
    const route_name = name;
    history.push({ pathname: URLS.COMMON_MESSAGE({ route_name }), state: { message } });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const openLoginModal = params.get("openLoginModal");
    if (openLoginModal === "true") {
      setLoginModalVisible(true);
    }
    const img = new Image();
    img.src = loginImg;
    img.onload = () => setPreloadedImage(loginImg);
  }, []);

  const desktopView = () => {
    return (
      <>
        {/* Desktop Header */}
        <div className={styles.header}>
          <div className={`${styles.headerContainer} ${headerDark ? styles.headerDark : ""}`}>
            {/* Logo */}
            <div className={styles.logoContainer} onClick={handleHomeButton}>
              <img src={logo} alt="Folkslogo" className={styles.headerLogo} />
            </div>
            {/* Left Menu */}
            <div className={styles.headerContainerLeft}>{headerItemsLeft.map((mObj) => mObj.label)}</div>
            {/* Right Menu */}
            <div className={styles.headerContainerRight}>{headerItemsRight.map((mObj) => mObj.label)}</div>
          </div>
        </div>
        {/* Logout Confirmation Modal */}
        <FolksModal visible={isLogoutModalVisible} onCancel={handleLogoutModalCancel}>
          <div className={styles.modalContainer}>
            <div>
              <ModalHeadingText>Are you sure you want to logout ?</ModalHeadingText>
              <div className={styles.modalFooterContainer}>
                <div>
                  <ModalFooterButton
                    className={styles.modalFooterBtn}
                    color="#A6A6A6"
                    onClick={handleLogoutModalCancel}
                  >
                    No
                  </ModalFooterButton>
                  <ModalFooterButton className={styles.modalFooterBtn} color="#289E5E" onClick={handleOk}>
                    Yes
                  </ModalFooterButton>
                </div>
              </div>
            </div>
          </div>
        </FolksModal>
        <LoginFolksModal visible={loginModalVisible} onCancel={handleClose} centered={true} zIndex={1001}>
          {termsOfUse || privacyPolicy ? (
            <div className={styles.privacyTermsContainer}>
              <div className={styles.backbtnContainer}>
                <IoArrowBackCircleOutline className={styles.backbtn} onClick={handleTermPrivacy} />
                {<div className={styles.privacyTermsText}>{termsOfUse ? "Terms Of Use" : "Privacy Policy"}</div>}
              </div>
              <div className={styles.privacyTermsBody}>{termsOfUse ? <TermsOfUse /> : <PrivacyPolicy />}</div>
            </div>
          ) : (
            <div className={styles.modalContainer}>
              <div className={styles.loginLeftContainer}>
                <Logo />
                <div className={styles.loginLeftContainerText}>
                  Create a profile now for personalized movie & TV shows recommendation, unlock rewards, and more!
                </div>
                <img src={preloadedImage} className={styles.loginLogo} alt=" " />
              </div>
              <div className={styles.loginRightContainer}>
                {forgotpage || waitingForOTP || resetPassPage?.status ? null : <Tab />}
                <div>
                  {forgotpage ? (
                    <ForgotPass setForgotpage={setForgotpage} />
                  ) : authActiveTabIndex === "register" ? (
                    <Register
                      setTermsOfUse={setTermsOfUse}
                      setPrivacyPolicy={setPrivacyPolicy}
                      waitingForOTP={waitingForOTP}
                      setWaitingForOTP={setWaitingForOTP}
                    />
                  ) : resetPassPage?.status ? (
                    <OTP resetPassPage={resetPassPage} setResetPassPage={setResetPassPage} />
                  ) : (
                    <Login setForgotpage={setForgotpage} setResetPassPage={setResetPassPage} />
                  )}
                </div>
              </div>
            </div>
          )}
        </LoginFolksModal>
      </>
    );
  };

  const mobileView = () => {
    return (
      <>
        <FolksMobileHeader>
          <div className={styles.mobileHeaderContainer}>
            <div className={styles.mobileLogoConatiner}>
              <img src={hamburger} alt="hamburger" className={styles.mobileHamburger} onClick={() => showDrawer()} />
              <img
                src={logo}
                alt="Folkslogo"
                className={styles.mobileHeaderLogo}
                onClick={() => history.push(URLS.LANDING)}
              />
            </div>
          </div>
        </FolksMobileHeader>

        <Drawer
          placement={"left"}
          closable={false}
          onClose={onClose}
          open={open}
          key={"key"}
          width={"75%"}
          extra={
            <Space>
              <p>Some contents...</p>
            </Space>
          }
        >
          <div className={styles.headerDrawerContainer}>
            <CloseOutlined className={styles.closeIcon} onClick={() => onClose()} />
            <img src={logo} alt="Folkslogo" className={styles.headerDrawerLogo} />
          </div>
          <br /> <br />
          <p
            onClick={() =>
              handleRedirection("Streaming Guide", "To find and watch Movies & Shows\nDownload the FolksMedia App")
            }
          >
            Streaming Guide
          </p>
          <p onClick={() => handleRedirection("Movies")}>Search Movies</p>
          <p onClick={() => handleRedirection("Rewards")}>Rewards</p>
        </Drawer>
      </>
    );
  };

  if (mobileHeader || isMobile) {
    return mobileView();
  } else {
    return desktopView();
  }

  // return desktopView();
};

AppHeader.propTypes = {
  headerData: PropTypes.object,
  mobileHeader: PropTypes.bool,
};

AppHeader.defaultProps = {
  headerData: {},
  mobileHeader: false,
};

export default observer(AppHeader);
