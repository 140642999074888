export const nestedSeasonsToEpisodeMediaRow = (seasonData) => {
  const episodeMediaItems = seasonData
    ?.map((item) => {
      return item?.episodes?.reduce((acc, curVal) => {
        return [...acc, { ...curVal, group_ids: [item.season_id] }];
      }, []);
    })
    ?.reduce((acc, curVal) => {
      return [...acc, ...curVal];
    }, [])
    ?.map((item) => {
      return {
        item_type: item?.item_type,
        item_id: item?.item_id,
        image: item?.image,
        title: item?.episode_title,
        image_hd: item?.image_hd,
        description: item?.description,
        actions: item?.actions,
        external_ids: { imdb_id: item?.item_id },
        group_ids: item.group_ids,
      };
    });

  const episodeMediaRow = [
    {
      title: "Episodes",
      display_config: {
        height: 192,
        width: 128,
      },
      media_item_groups: seasonData?.map((item) => ({ id: item.season_id, title: item.season_title })),
      media_items: episodeMediaItems,
    },
  ];

  return episodeMediaRow;
};

export const headerActionMerger = (actArr1, actArr2) => {
  if (actArr1?.length && actArr2?.length) {
    const map = {};
    [...actArr1, ...actArr2].forEach((action) => (map[action.title] ? null : (map[action.title] = action)));
    return Object.values(map);
  } else {
    if (!(actArr1?.length || actArr2?.length)) {
      return [];
    } else if (actArr1?.length) {
      return actArr1;
    } else if (actArr2?.length) {
      return actArr2;
    } else {
      return [];
    }
  }
};
