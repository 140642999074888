import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { useMst } from "../../store";
import { accountRoutes } from "../../routes/routes";
import { REDIRECTION_ROUTES } from "../../utils/redirectionSettings";
import { navCardData } from "./NavData";
import { guestNavCardData } from "./NavData";

import { Layout, Menu, Tooltip } from "antd";
import { AiOutlineLogout, AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { toast } from "react-toastify";
import styled from "styled-components";

import FolksModal from "../../components/folks-modal/FolksModal";
import FolksAvatar from "../../components/folks-avatar/FolksAvatar";
import { ModalHeadingText } from "../../components/typography/ModalText";
import { ModalFooterButton } from "../../components/folks-modal/ModalFooterButton";
import localforage from "localforage";

// CSS imports
import styles from "./accountsSidebar.module.css";
import { getInitialAlphabet } from "../../components/folks-avatar/utils";
import { MdOutlineModeEdit } from "react-icons/md";
import AvatarEditor from "react-avatar-editor";
import { LoadingOutlined } from "@ant-design/icons";
import Footer from "../../auth/component/footer/Footer";
import { AuthButton } from "../../auth/component/AuthButton";
import { limitText } from "../../utils/helper";
import FrontLoader from "../../components/front-loader/FrontLoader";

const { Sider } = Layout;

const SiderContainer = styled(Sider)`
  &&& {
    min-width: 18vw !important;
    background: #252525;
    overflow-x: hidden;
    height: 90vh;
    margin: 5.5vw 1.4vw 5.5vw 4vw;
    border-radius: 4px;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    align-items: center;
  }
`;

const MenuContainer = styled.div`
  /* override antd classes */
  .ant-menu {
    background: #252525;
    border: none;
    font-size: 1vw;
    font-weight: 600;
    margin-bottom: 120px;
  }

  .ant-menu .ant-menu-item {
    width: 13vw;
    height: 4vh;
    border: 1px solid white;
    border-radius: 5px;
    justify-content: center;
    margin: 15px 0px;
    &:hover {
      color: #b7b7b7;
    }
  }

  .ant-menu-item-icon {
    font-size: 1.2vw;
  }

  .ant-menu .ant-menu-item-selected {
    // background: #ea3a1b;
    width: 13vw;
    height: 4vh;
    border-radius: 5px;
    // border-color: #ea3a1b;
  }

  .ant-menu-item a:hover {
    color: #fff;
  }

  .ant-menu-item-selected,
  .ant-menu-item-selected a,
  .ant-menu-item-selected a {
    color: #fff;

    &:after {
      display: none;
      visibility: hidden;
    }
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    flex: none;
  }

  /* sub menu css */

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    color: #b7b7b7;
    height: 3.6vw;

    &:hover {
      color: #fff;
    }
  }

  .ant-menu-submenu .ant-menu .ant-menu-item-selected {
    border: none;
    background: none;
  }

  .ant-menu-submenu .ant-menu .ant-menu-item-selected a {
    color: #fc5252;
  }

  .ant-menu-submenu .ant-menu .ant-menu-item-selected {
    border: none;
    background: none;
  }

  .ant-menu .ant-menu-sub {
    background: rgba(255, 255, 255, 0.05);
  }

  .ant-menu-submenu-selected > div {
    border-left: 0.4vw solid #fc5252;
    padding: 1.7vw 1.5vw;
    background: #080e14;
  }
`;

const AccountsSidebar = () => {
  const { auth, profileStore, setLoginModalVisible, setAuthActiveTabIndex } = useMst();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showImgEditorModal, setShowImgEditorModal] = useState(false);
  const [imgFileToEdit, setImgFileToEdit] = useState(null);
  const [isImageUploading, setIsImageUploading] = useState(false);
  const avatarEditorRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  const handleAccountLogout = () => {
    setIsModalVisible(true);
  };

  const siderMenuItem = Object.values(accountRoutes)?.find((item) => {
    return item.path === location.pathname && item.id;
  });

  const handleOk = async () => {
    setIsLoading(true);
    setIsModalVisible(false);
    try {
      await auth.logout();
      window.location.href = REDIRECTION_ROUTES.ACC_SIDER_LOGOUT;
    } catch (e) {
      setIsLoading(false);
      toast.error("Error logging out!", e);
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const siderDataMenu = (auth?.folksUserType === "GUEST" ? guestNavCardData : navCardData).map((item) => {
    return {
      label: <Link to={item.path}>{item.title}</Link>,
      key: `${item?.id}`,
      icon: item?.logo,
      path: item.path,
    };
  });

  const isSelected = (path) => location.pathname === path;

  // Render menu items with conditional background color
  const menuItems = siderDataMenu.map((item) => (
    <Menu.Item
      key={item.key}
      icon={item.icon}
      style={{
        backgroundColor: isSelected(item.path) ? "#ea3a1b" : "transparent",
        borderColor: isSelected(item.path) ? "#ea3a1b" : "",
      }}
    >
      {item.label}
    </Menu.Item>
  ));

  const closeAvatarEditor = () => {
    setShowImgEditorModal(false);
    setImgFileToEdit(null);
  };
  const handleProfileOk = () => {
    uploadProfilePic();
  };

  const handleProfileCancel = () => {
    setShowImgEditorModal(false);
    setImgFileToEdit(null);
  };

  const uploadProfilePic = async () => {
    if (avatarEditorRef?.current?.canvas) {
      const base64URL = avatarEditorRef?.current?.getImageScaledToCanvas().toDataURL(); // Canvas to Base64
      try {
        setIsImageUploading(true);
        await profileStore.updateProfilePhoto(base64URL);
        setIsImageUploading(false);
        closeAvatarEditor();
      } catch (e) {
        setIsImageUploading(false);
      }
    }
  };

  const handleImgUpdate = () => {
    // Opens a file dialog box.
    const fileElem = document.getElementById("fileElem");
    if (fileElem) {
      fileElem.click();
    }
  };

  const onImgFileSelection = (e) => {
    if (!e?.target?.files?.length) {
      console.log("no file selected!");
    } else {
      setImgFileToEdit(e.target.files[0]);
      setShowImgEditorModal(true);
    }
  };

  return (
    <SiderContainer>
      {auth?.folksUserType === "NONE" && !profileStore?.getAvatarImageUrl && isLoading ? (
        <div className={styles.loader}>Logging out...</div>
      ) : (
        <>
          <div className={styles.detailsContainer}>
            {auth?.folksUserType !== "GUEST" ? (
              <div style={{ width: "16vw" }}>
                <div className={styles.profileImageContainer}>
                  <div className={styles.edit} onClick={handleImgUpdate}>
                    <MdOutlineModeEdit />
                  </div>
                  <div style={{ display: "none" }}>
                    <input onChange={onImgFileSelection} type="file" id="fileElem" accept="image/*" />
                  </div>
                  {profileStore?.getAvatarImageUrl ? (
                    <img className={styles.profileImage} src={profileStore?.getAvatarImageUrl || ""} />
                  ) : (
                    <div className={styles.nameLetter}>{getInitialAlphabet(profileStore?.getUserName)}</div>
                  )}
                </div>
                <div className={styles.details}>
                  <div className={styles.nameContainer}>
                    <span className={styles.name}>
                      <Tooltip title={profileStore?.getUserName || ""}>
                        {limitText(profileStore?.getUserName || "", 28)}
                      </Tooltip>
                    </span>
                  </div>

                  <div className={styles.nameContainer}>
                    <p className={styles.email}>
                      <Tooltip title={profileStore?.getEmail || ""}>
                        {limitText(profileStore?.getEmail || "", 35)}
                      </Tooltip>
                    </p>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <MenuContainer>
            <Menu
              defaultSelectedKeys={[siderMenuItem?.id]}
              mode="inline"
              expandIcon={(event) => (event.isOpen ? <AiOutlineUp /> : <AiOutlineDown />)}
            >
              {menuItems}
            </Menu>
          </MenuContainer>
          {auth?.folksUserType !== "GUEST" ? (
            <div className={styles.logout} onClick={handleAccountLogout}>
              <div>
                <span>Logout</span>
              </div>
            </div>
          ) : (
            <div className={styles.yourGuest}>
              <div>
                <span>
                  You are guest user <br />
                  Click to{" "}
                  <span
                    style={{ color: "#ea3a1b", cursor: "pointer" }}
                    onClick={() => {
                      setAuthActiveTabIndex("register");
                      setLoginModalVisible(true);
                    }}
                  >
                    <u>join now</u>
                  </span>
                </span>
              </div>
            </div>
          )}

          <FolksModal visible={isModalVisible} onCancel={handleCancel}>
            <div className={styles.modalContainer}>
              <div>
                <div className={styles.emoji}>😔</div>
                <ModalHeadingText>Are you sure you want to logout?</ModalHeadingText>
                <div className={styles.modalFooterContainer}>
                  <div>
                    <AuthButton className={styles.logoutYesBtn} onClick={handleOk}>
                      Yes
                    </AuthButton>
                    <AuthButton className={styles.logoutNoBtn} onClick={handleCancel}>
                      No
                    </AuthButton>
                  </div>
                </div>
              </div>
            </div>
          </FolksModal>

          <FolksModal visible={showImgEditorModal} onCancel={handleProfileCancel}>
            <div className={styles.modalContainer}>
              <div style={{ textAlign: "center" }}>
                <ModalHeadingText>Edit Profile Picture</ModalHeadingText>
                <AvatarEditor
                  ref={avatarEditorRef}
                  image={imgFileToEdit}
                  border={20}
                  color={[255, 255, 255, 0.6]} // RGBA
                  scale={1.2}
                  rotate={0}
                  style={{ height: "20vw", width: "20vw", margin: "1vw 0" }}
                />
                <div className={styles.modalFooterContainer}>
                  <div>
                    <AuthButton className={styles.logoutYesBtn} onClick={handleProfileCancel}>
                      Cancel
                    </AuthButton>
                    <AuthButton className={styles.logoutNoBtn} onClick={handleProfileOk}>
                      {isImageUploading && <LoadingOutlined style={{ color: "white" }} spin />}
                      Update
                    </AuthButton>
                  </div>
                </div>
              </div>
            </div>
          </FolksModal>
        </>
      )}
    </SiderContainer>
  );
};

export default observer(AccountsSidebar);
