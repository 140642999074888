const SS_MAX_OBJECT_SIZE_BYTES = 2097152; // 2MiB

export const ssReadObj = (key) => {
  const obj = JSON.parse(sessionStorage.getItem(`${key}`));
  if (obj) {
    if (typeof obj === "object") {
      return { success: true, data: obj, message: "Success!" };
    } else {
      return { success: false, data: null, message: "Error:  datatype is not object" };
    }
  } else {
    return { success: false, data: null, message: "Error: Object missing" };
  }
};

export const ssWriteObj = (key, data) => {
  try {
    if (data) {
      if (typeof data === "object") {
        if (Buffer.from(JSON.stringify(data)).length <= SS_MAX_OBJECT_SIZE_BYTES) {
          sessionStorage.setItem(`${key}`, JSON.stringify(data));
          return { success: true };
        } else {
          throw new Error("Object exceeds max allowable size.");
        }
      } else {
        throw new Error("Datatype is not object!");
      }
    } else {
      throw new Error("Object missing!");
    }
  } catch (err) {
    return { success: false, message: `Error: ${err.message}` };
  }
};

export const ssDeleteObj = (key) => {
  if (sessionStorage.getItem(`${key}`)) {
    sessionStorage.removeItem(`${key}`);
    return { success: true };
  } else {
    return { success: false, message: "Error: Key does not exist!" };
  }
};
