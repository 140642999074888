import React, { useState } from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Row, Col, Typography, Button, Tooltip } from "antd";
import styles from "../../../content-common/components/media-row/mediaRow.module.css";
import playlistCradStyles from "./playlistCard.module.css";
import { useMst } from "../../../../store";
import { observer } from "mobx-react-lite";

import { EyeOutlined, LoadingOutlined, ShareAltOutlined } from "@ant-design/icons";
import { CardLevelOneHeading } from "../../../../components/typography/ModalText";
import { BsPeople } from "react-icons/bs";
import PotraitFallback from "../../../../assets/images/playlist.svg";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { URLS } from "../../../../routes/routeURL";
import { BiEditAlt } from "react-icons/bi";
import { DeleteOutlined } from "@ant-design/icons";
import { HiOutlineGlobeAlt } from "react-icons/hi";
import { RiGitRepositoryPrivateLine } from "react-icons/ri";
import { deProxyJSONData, pluralize } from "../../../../utils/helper";

const { Paragraph, Text } = Typography;

const width = 240;
const CardImage = styled(LazyLoadImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
  border-top-left-radius: 5%;
  border-top-right-radius: 5%;
  aspect-ratio: 3/2;
`;

const DetailsContainer = styled.div`
  width: ${(props) => props.width + "px"};
  padding: 5px 10px;
  background-color: rgb(38 35 35 / 80%);
  overflow: hidden;
  border-bottom-left-radius: 5%;
  border-bottom-right-radius: 5%;
`;

const PlaylistCard = (props) => {
  const { playlistItemData, title, type, onShare, onEdit, onDeletePlaylist } = props;

  const { playlistStore, analyticsStore, searchStore, notificationStore } = useMst();

  const [load, setLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const Msg = () => (
    <div>
      Subscription Added
      <Button type="text" onClick={handleToastClick} style={{ color: "green" }}>
        Manage Playlist
      </Button>
    </div>
  );

  const handleToastClick = async () => {
    history.push(URLS.MANAGE_PLAYLIST);
  };

  const handleCardClick = async (playlist_id) => {
    history.push(
      URLS.PUBLIC_USER_PLAYLIST({
        playlist_id,
      }),
      {
        title,
        count: deProxyJSONData(playlistItemData?.analytics_data?.browse_count || 0),
      }
    );

    if (playlistItemData?.is_owner) return null;
    const payload = {
      data: {
        watchlist_id: playlist_id,
      },
    };

    const res = await analyticsStore.getAnalyticsPageView("watchlist_view", payload);
    if (res.success) {
      if (title === "Subscribed Playlists") {
        playlistStore.setViewAnalyticsDataForSubscribedPlaylist(playlist_id);
      } else if (title === "Playlists") {
        searchStore.setViewAnalyticsDataForSearchedPlaylist(playlist_id);
      }
    }
  };

  const handleSubscribe = async (e, playlist_id, type) => {
    e.preventDefault();
    e.stopPropagation();
    if (isLoading) return;
    try {
      setIsLoading(true);
      const result = await playlistStore.subscribeToPlaylist(playlist_id);
      if (result.success) {
        // await fetchPLaylistdata();
        if (type === "subscribe") {
          searchStore.setSubscribeAnalytics(playlist_id, type);
          searchStore.addToSubscribelist(playlist_id);
          notificationStore.triggerSuccess(<Msg />);
          analyticsStore.getSubscribeAnalytics(playlist_id, type);
        } else if (type === "unsubscribe") {
          if (title !== "Subscribed Playlists") {
            searchStore.setSubscribeAnalytics(playlist_id, type);
            playlistStore.setUnSubscribeAnalytics(playlist_id);
          }
          playlistStore.setUnSubscribeAnalytics(playlist_id);
          analyticsStore.getSubscribeAnalytics(playlist_id, type);
        }
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const handleSharePlaylist = (e, playlist) => {
    e.preventDefault();
    e.stopPropagation();
    onShare({
      title: playlist?.watchlist_name,
      watchlist_id: playlist?.watchlist_id,
    });
  };

  const handleDeleteModal = (e, watchlist_id) => {
    e.stopPropagation();
    e.preventDefault();
    onDeletePlaylist(watchlist_id);
  };

  const handleEditWatchlist = (e, watchlist_id) => {
    e.stopPropagation();
    e.preventDefault();
    onEdit(watchlist_id);
  };

  const isUsersPlaylist = type === "normal";

  return (
    <>
      <div
        className={styles.mediaCardContainer}
        key={playlistItemData?.watchlist_id}
        style={{
          width: width + "px",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleCardClick(playlistItemData?.watchlist_id);
        }}
      >
        <div style={{ width: width + "px", position: "relative" }} className={playlistCradStyles.watchlistContainer}>
          {isUsersPlaylist && <PrivacyIndicator privacy={playlistItemData?.privacy} />}

          <CardImage
            src={playlistItemData?.image_hd_url || PotraitFallback}
            alt="playlistImage"
            display={load === true ? "none" : "block"}
            beforeLoad={() => setLoad(true)}
            afterLoad={() => setLoad(false)}
          />
        </div>

        <DetailsContainer width={width}>
          <div className={playlistCradStyles.CardMetaContainer}>
            <Row justify="space-between">
              <Tooltip title={playlistItemData?.watchlist_name} placement="bottom">
                <Paragraph
                  ellipsis={true ? { rows: 1, expandable: false } : false}
                  style={{ cursor: "pointer", margin: "0px", maxWidth: "60%" }}
                >
                  <CardLevelOneHeading>{`Playlist: ${playlistItemData?.watchlist_name}`}</CardLevelOneHeading>
                </Paragraph>
              </Tooltip>
              <CardLevelOneHeading>{`(${pluralize(playlistItemData?.video_count, "Title")})`}</CardLevelOneHeading>
            </Row>
            {!isUsersPlaylist ? (
              <Tooltip title={playlistItemData?.author_name} placement="bottom">
                <Text
                  ellipsis={true ? { rows: 1, expandable: false } : false}
                  className={playlistCradStyles.watchListTitle}
                  style={{ cursor: "pointer", maxWidth: "200px", fontSize: "14px", color: "#929292" }}
                >
                  Created by {playlistItemData?.author_name}
                </Text>
              </Tooltip>
            ) : null}

            <Row justify="space-between" style={{ fontSize: "14px", textAlign: "center", marginTop: "15px" }}>
              <Row
                style={{
                  display: "flex",
                  gap: "3px",
                }}
                align="middle"
              >
                <EyeOutlined style={{ display: "flex" }} />
                {!playlistItemData?.analytics_data?.browse_count ? (
                  <Typography>{pluralize(playlistItemData?.analytics_data?.browse_count, "View")}</Typography>
                ) : (
                  <Typography>{playlistItemData?.analytics_data?.browse_count} Viewed</Typography>
                )}
              </Row>
              <span
                style={{
                  border: "1px solid gray",
                }}
              />
              <Row
                style={{
                  display: "flex",
                  gap: "4px",
                }}
                align="middle"
              >
                <BsPeople style={{ display: "flex" }} />
                <Typography>{pluralize(playlistItemData?.analytics_data?.subscribed_count, "Subscriber")}</Typography>
              </Row>
            </Row>
          </div>
          <Row
            justify="space-around"
            style={{
              gap: "2px",
            }}
          >
            {!isUsersPlaylist && playlistItemData?.privacy === "public" && !playlistItemData?.is_owner ? (
              <Col
                style={{
                  flex: "1 1 40%",
                }}
              >
                {playlistItemData?.privacy === "public" &&
                  !playlistItemData?.is_owner &&
                  !playlistItemData.is_subscribe && (
                    <button
                      className={playlistCradStyles.subscribeButton}
                      onClick={(e) => handleSubscribe(e, playlistItemData?.watchlist_id, "subscribe")}
                    >
                      {isLoading && <LoadingOutlined style={{ marginRight: "5px" }} />}Subscribe
                    </button>
                  )}
                {playlistItemData?.privacy === "public" &&
                  !playlistItemData?.is_owner &&
                  playlistItemData.is_subscribe && (
                    <button
                      className={playlistCradStyles.unsubscribeButton}
                      onClick={(e) => handleSubscribe(e, playlistItemData?.watchlist_id, "unsubscribe")}
                    >
                      {isLoading && <LoadingOutlined style={{ marginRight: "5px" }} />}Unsubscribe
                    </button>
                  )}
              </Col>
            ) : null}
            {playlistItemData?.privacy === "public" && playlistItemData?.video_count ? (
              <Col
                style={{
                  flex: !isUsersPlaylist ? "1 1 40%" : "auto",
                }}
              >
                <button
                  className={playlistCradStyles.shareButton}
                  onClick={(e) => handleSharePlaylist(e, playlistItemData)}
                >
                  <ShareAltOutlined /> <span style={{ marginLeft: "5px" }}>Share</span>
                </button>
              </Col>
            ) : null}
            {isUsersPlaylist ? (
              <Col>
                <button
                  className={playlistCradStyles.shareButton}
                  onClick={(e) => handleEditWatchlist(e, playlistItemData?.watchlist_id)}
                >
                  <BiEditAlt /> <span style={{ marginLeft: "5px" }}>Edit</span>
                </button>
              </Col>
            ) : null}
            {isUsersPlaylist ? (
              <Col>
                <button
                  className={playlistCradStyles.shareButton}
                  onClick={(e) => handleDeleteModal(e, playlistItemData?.watchlist_id)}
                >
                  <DeleteOutlined /> <span style={{ marginLeft: "5px" }}>Delete</span>
                </button>
              </Col>
            ) : null}
          </Row>
        </DetailsContainer>
      </div>
    </>
  );
};

PlaylistCard.propTypes = {
  playlistItemData: PropTypes.object,
};

PlaylistCard.defaultProps = {
  playlistItemData: {},
};

export default observer(PlaylistCard);

const PrivacyIndicator = ({ privacy }) => {
  return (
    <div className={playlistCradStyles.visibilityBadge}>
      <div className={playlistCradStyles.badgeContent}>
        {privacy === "public" && <HiOutlineGlobeAlt className={playlistCradStyles.badgeIcon} />}
        {privacy === "private" && (
          <RiGitRepositoryPrivateLine className={`${playlistCradStyles.badgeIcon} primary-color`} />
        )}
        {privacy === "public" && <span className={playlistCradStyles.badgeText}>Public</span>}
        {privacy === "private" && <span className={`${playlistCradStyles.badgeText} primary-color`}>Private</span>}
      </div>
    </div>
  );
};
