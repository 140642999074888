import React from "react";
import styles from "./folksLoader.module.css";
import { FaSpinner } from "react-icons/fa";

const FolksLoader = () => {
  return (
    <div className={styles.loader}>
      <FaSpinner className={styles.spinner} color="#c65243" />
      <span> Loading...</span>
    </div>
  );
};

export default FolksLoader;
