import React from "react";
import { useHistory } from "react-router-dom";
import ErrorImage from "../../assets/images/Error-404.png";
import styles from "./pageNotFound.module.css";
import { URLS } from "../../routes/routeURL";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {" "}
        <img src={ErrorImage} alt="" height={"100%"} width={"100%"} style={{ objectFit: "contain" }} />
      </div>

      <h2>Oops! Page not found.</h2>

      <button className={styles.homeButton} onClick={() => history.push(URLS.CONTENT_HOME)}>
        Back to Homepage
      </button>
    </div>
  );
};

export default PageNotFound;
