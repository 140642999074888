import { types } from "mobx-state-tree";

export const addressObjectModel = types.model("address_object_model", {
  address_id: types.optional(types.string, ""),
  full_name: types.optional(types.string, ""),
  building_address: types.optional(types.string, ""),
  area: types.optional(types.string, ""),
  city: types.optional(types.string, ""),
  pin_code: types.optional(types.string, ""),
  landmark: types.optional(types.string, ""),
  user_state: types.optional(types.string, ""),
  country: types.optional(types.string, ""),
  mobile_no: types.optional(types.string, ""),
  address_type: types.optional(types.string, ""),
  user_id: types.optional(types.string, ""),
});

export const initialProfileStoreState = {
  userName: "",
  avatarImageUrl: "",
  dob: "",
  gender: "",
  email: "",
  bio: "",
  addressList: [],

  referralCode: "",
};
