import React from "react";
import styles from "./shareLinkModal.module.css";
import FolksModal from "../../../../components/folks-modal/FolksModal";
import PropTypes from "prop-types";
import { useMst } from "../../../../store";

import {
  TwitterShareButton,
  FacebookShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  EmailShareButton,
  TwitterIcon,
  FacebookIcon,
  WhatsappIcon,
  TelegramIcon,
  EmailIcon,
} from "react-share";
import { ModalHeadingText } from "../../../../components/typography/ModalText";
import twitterX from "../../../../assets/images/twitter_x.svg";
import Gmail from "../../../../assets/images/Gmail.svg";

export default function ShareLinkModal({ shareUrl, isShareModalVisible, handleCancel, movieTitle, sharePlaylist }) {
  const { referralStore } = useMst();
  const shareMessage = {
    title: movieTitle,
    subject: sharePlaylist ? `Checkout this Playlist on Folksmedia` : `Try out exclusive Folksmedia app now!`,
    message: `Hey! Checkout "${movieTitle}" available on Folksmedia app at ${
      shareUrl + "?ref=" + referralStore?.referralCode
    }. If you still haven't installed the
    Folksmedia app, Signup and use my referral code '${referralStore?.referralCode}'`,
    hashtags: ["folksMedia", "folksOffers"],
  };

  return (
    <>
      <FolksModal visible={isShareModalVisible} onCancel={handleCancel} width={"27%"}>
        <div className={styles.shareModalContainer}>
          <ModalHeadingText>Share Via</ModalHeadingText>
          <div className={styles.linkCointainer}>
            <TelegramShareButton url={shareUrl} title={shareMessage.message} disabled={shareUrl ? false : true}>
              <TelegramIcon className={styles.icon} />
            </TelegramShareButton>

            <FacebookShareButton
              url={shareUrl}
              quote={shareMessage.message}
              hashtags={shareMessage.hashtags}
              disabled={shareUrl ? false : true}
            >
              <FacebookIcon className={styles.icon} />
            </FacebookShareButton>

            <WhatsappShareButton url={shareUrl} title={shareMessage.message} disabled={shareUrl ? false : true}>
              <WhatsappIcon className={styles.icon} />
            </WhatsappShareButton>

            <TwitterShareButton
              url={shareUrl}
              title={shareMessage.message}
              hashtags={shareMessage.hashtags}
              disabled={shareUrl ? false : true}
            >
              <img src={twitterX} className={styles.twitterXIcon} />
            </TwitterShareButton>

            <EmailShareButton
              url={shareUrl}
              subject={shareMessage.subject}
              body={shareMessage.message}
              disabled={shareUrl ? false : true}
              onClick={(_, link) => {
                window.open(link, "_blank");
              }}
            >
              <img src={Gmail} className={styles.gmailIcon} />
            </EmailShareButton>
          </div>
        </div>
      </FolksModal>
    </>
  );
}

ShareLinkModal.propsType = {
  shareUrl: PropTypes.string,
  isShareModalVisible: PropTypes.bool,
  handleCancel: PropTypes.func,
  movieTitle: PropTypes.string,
  sharePlaylist: PropTypes.bool,
};
ShareLinkModal.defaultProps = {
  shareUrl: "",
  isShareModalVisible: false,
  handleCancel: () => {},
  movieTitle: "",
  sharePlaylist: false,
};
