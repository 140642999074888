import { flow, types, getParent } from "mobx-state-tree";
import { mediaRowModel } from "./models/contentModel";
import { playlistModel } from "./models/playlistModel";
import { sanitizeMediaRows } from "./filters/contentFilter";

import ContentAPI from "../../api/ContentServiceAPI";
import SearchApi from "../../api/SearchApi";
import { EP_ROOT as __ } from "../../utils/endpoints";
import { SEARCH_COUNT } from "../../utils/constants";

const SearchStoreModel = {
  searchVal: types.optional(types.string, ""),
  searchMediaRows: types.optional(types.array(mediaRowModel), []),
  searchPlaylists: types.optional(types.array(playlistModel), []),
  trendingMediaRows: types.optional(types.array(mediaRowModel), []),
};

const SearchStoreActions = (self) => ({
  // Actions for maintaining Search page context.
  setSearchVal(search_text) {
    self.searchVal = search_text;
  },

  setSearchMediaRows(rowData) {
    try {
      self.searchMediaRows = rowData;
    } catch (err) {
      console.log(err);
    }
  },

  setSearchPlaylists(playlistArr) {
    self.searchPlaylists = playlistArr;
  },
  setTredingRows(data) {
    self.trendingMediaRows = data;
  },
  setViewAnalyticsDataForSearchedPlaylist(watchlist_id) {
    const foundIndex = self.searchPlaylists.findIndex((x) => x.watchlist_id === watchlist_id);
    const count = self.searchPlaylists[foundIndex]?.analytics_data?.browse_count + 1;
    self.searchPlaylists[foundIndex].analytics_data.browse_count = count;
  },

  setSubscribeAnalytics(watchlist_id, type) {
    const foundIndex = self.searchPlaylists?.findIndex((x) => x.watchlist_id === watchlist_id);
    if (foundIndex === -1) return;
    if (type === "subscribe") {
      const count = self.searchPlaylists[foundIndex]?.analytics_data.subscribed_count + 1;
      self.searchPlaylists[foundIndex].analytics_data.subscribed_count = count;
      self.searchPlaylists[foundIndex].is_subscribe = true;
    } else if (type === "unsubscribe") {
      const count =
        self.searchPlaylists[foundIndex]?.analytics_data.subscribed_count === 0
          ? 0
          : self.searchPlaylists[foundIndex]?.analytics_data.subscribed_count - 1;
      self.searchPlaylists[foundIndex].analytics_data.subscribed_count = count;
      self.searchPlaylists[foundIndex].is_subscribe = false;
    }
  },

  addToSubscribelist(watchlist_id) {
    getParent(self).playlistStore.addToSubscribePlaylist(
      self.searchPlaylists.find((item) => item.watchlist_id === watchlist_id)
    );
  },

  updateSearchData(watchlist_id) {
    const foundIndex = self.searchPlaylists?.findIndex((x) => x.watchlist_id === watchlist_id);
    self.searchPlaylists[foundIndex].is_subscribe = false;
  },

  removeSubscribelist(watchlist_id) {
    getParent(self).playlistStore.listOfSubscribedPlaylist.filter((item) => item.watchlist_id !== watchlist_id);
  },

  // --------- search suggestions ----------
  getSearchSuggestions: flow(function* getSearchSuggestions(search_text) {
    try {
      const res = yield SearchApi.get(__.SEARCH_SERVICE.GET_SUGGESTIONS(search_text));
      if (res.status === 200 && res.data?.suggestions?.length) {
        return Promise.resolve({ success: true, data: res.data.suggestions });
      } else {
        throw new Error("Problem occured fetching search suggestions!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Problem occured fetching search suggestions!",
      });
    }
  }),

  // --------------- get search feed -----------------
  getSearchFeed: flow(function* getSearchFeed(search_str) {
    try {
      const getParams = { params: { query: search_str, category: "", skip: 0, limit: 10 } };
      const res = yield SearchApi.get(__.SEARCH_SERVICE.GET_RESULTS, getParams);

      if (res.status === 200 && res.data?.media_rows?.length) {
        const sanitizedMediaRows = sanitizeMediaRows(res?.data?.media_rows, true);
        const paginationReadyMediaRows = sanitizedMediaRows?.map((row) => ({
          ...row,
          has_pagination: true,
          pagination_module: "search",
          skip: 0, // dummy
          limit: 10, // dummy
          count: 10, // dummy
        }));
        self.setSearchMediaRows(paginationReadyMediaRows);
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Problem occured fetching search results!");
      }
    } catch (error) {
      return Promise.reject({ success: false, message: error.message || "Problem occured fetching search results!" });
    }
  }),

  // --------------- get search feed paginated data -----------------
  getPaginatedSearchFeed: flow(function* getSearchFeed(search_str, category) {
    if (!(search_str || category)) return Promise.reject("Params not present!");
    const observablePaginatedMediaRow = self.searchMediaRows.find((row) => row.title === category);
    const limitVal = observablePaginatedMediaRow?.limit || 10;
    const skipVal = observablePaginatedMediaRow?.skip + limitVal;

    // const countVal = observablePaginatedMediaRow?.count;
    // Stop pagination.
    // if (skipVal >= countVal) return Promise.resolve({ success: true });
    const getParams = { params: { query: search_str, category: category, skip: skipVal, limit: limitVal } };

    try {
      const res = yield SearchApi.get(__.SEARCH_SERVICE.GET_RESULTS, getParams);
      if (res.status === 200 && res.data?.media_rows?.length) {
        const sanitizedMediaRow = sanitizeMediaRows(res.data?.media_rows, true);
        const rowMediaItems = sanitizedMediaRow?.[0]?.media_items;
        if (rowMediaItems?.length) {
          observablePaginatedMediaRow.addMediaItems(rowMediaItems);
        }
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while fetching paginated search results!");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Problem occured fetching paginated search results!",
      });
    }
  }),

  // --------------- playlist search -----------------
  getSearchPlaylistFeed: flow(function* getSearchPlaylistFeed(search_text) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.SEARCH_PLAYLISTS(search_text));
      if (res?.status === 200 && res?.data?.data) {
        return Promise.resolve({ success: true, data: res?.data?.data?.watchlist });
      } else {
        throw new Error("Something went wrong while fetching Playlist!");
      }
    } catch (error) {
      return Promise.reject({ success: false, message: error.message || "Unable to Find Playlist" });
    }
  }),
  // --------------- playlist search -----------------
  getTrendingSearch: flow(function* getTrendingSearch() {
    try {
      const mediaRow = self.trendingMediaRows?.[0];
      const skip = mediaRow?.skip + SEARCH_COUNT || 0;
      const res = yield SearchApi.get(__.SEARCH_SERVICE.TRENDING_SEARCH({ skip, limit: SEARCH_COUNT }));
      if (res?.status === 200 && res?.data) {
        const sanitizedMediaRows = sanitizeMediaRows([
          {
            title: "Trending Search",
            media_items: res?.data?.trending_suggestion,
            content_type: 23,
            is_title_clickable: false,
          },
        ]);

        if (skip > 0) {
          mediaRow.addMediaItems(sanitizedMediaRows?.[0].media_items);
        } else {
          const paginationMediaRows = sanitizedMediaRows?.map((row) => ({
            ...row,
            has_pagination: true,
            pagination_module: "trending_search",
            skip: skip, // dummy
            limit: SEARCH_COUNT, // dummy
            count: SEARCH_COUNT, // dummy
          }));
          self.setTredingRows(paginationMediaRows);
        }
        return Promise.resolve({ success: true });
      } else {
        throw new Error("Something went wrong while fetching Playlist!");
      }
    } catch (error) {
      return Promise.reject({ success: false, message: error.message || "Unable to Find Playlist" });
    }
  }),
});

const SearchStoreViews = (self) => ({
  getTredingRows() {
    return self.trendingMediaRows;
  },
});

export const searchStore = types
  .model("playlistStore", SearchStoreModel)
  .actions(SearchStoreActions)
  .views(SearchStoreViews);
