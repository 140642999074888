import { applySnapshot, types, flow } from "mobx-state-tree";
import { createContext, useContext } from "react";

// Stores
// Auth Store
import { AuthStore } from "./auth_store/authStore";

// Content Stores
import { feedsStore } from "./content_store/feedsStore";
import { mdpStore } from "./content_store/mdpStore";
import { ActionStore } from "./content_store/actionStore";
import { BackgroundImageStore } from "./content_store/backgroundImageStore";
import { AuditStore } from "./content_store/auditStore";
import { playlistStore } from "./content_store/playlistStore";
import { searchStore } from "./content_store/searchStore";

// Account Stores
import { profileStore } from "./accounts_store/profileStore";
import { contentSettingsStore } from "./accounts_store/contentSettingsStore";
import { publicProfileStore } from "./accounts_store/publicProfileStore";

// Other Stores
import { paymentsStore } from "./paymentsStore";
import { rewardsStore } from "./rewardsStore";
import { referralStore } from "./referralStore";
import { AnalyticsStore } from "./analytics_store/analyticsStore";

// Common Stores
import { browserStore } from "./browser_store/browserStore";
import { loaderStore } from "./loaderStore";
import { notificationStore } from "./notificationStore";

import { initialAuthStoreState } from "./auth_store/models/authModel";
import { logoutAuthStoreState } from "./auth_store/models/authModel";
import { initialFeedsStoreState } from "./content_store/models/contentModel";
import { initialMdpState } from "./content_store/models/mdpModel";
import { initialSearchStoreState } from "./content_store/models/searchModel";
import { initialProfileStoreState } from "./accounts_store/models/profileModel";
import { initialContentSettingsStoreState } from "./accounts_store/models/contentSettingsModel";
import { initialPlaylistState } from "./content_store/models/playlistModel";
import { modalControllerStore } from "./modalController_store/modalControllerStore";
import { initialBrowserStoreState } from "./browser_store/models/browserModel";

const intialStateData = {
  auth: initialAuthStoreState,
  browserStore: initialBrowserStoreState,
  feedsStore: initialFeedsStoreState,
  mdpStore: initialMdpState,
  playlistStore: initialPlaylistState,
  searchStore: initialSearchStoreState,
  paymentsStore: {},
  rewardsStore: {},
  loaderStore: {},
  actionStore: {},
  backgroundImageStore: { img: "" },
  auditStore: {},
  notificationStore: {},
  profileStore: initialProfileStoreState,
  contentSettingsStore: initialContentSettingsStoreState,
  publicProfileStore: {},
  referralStore: {},
  analyticsStore: {},
  modalControllerStore: {},
  authActiveTabIndex: "login",
  loginModalVisible: false,
};

const RootModel = types
  .model({
    browserStore: browserStore,
    auth: AuthStore,
    paymentsStore: paymentsStore,
    feedsStore: feedsStore,
    mdpStore: mdpStore,
    playlistStore: playlistStore,
    searchStore: searchStore,
    rewardsStore: rewardsStore,
    loaderStore: loaderStore,
    actionStore: ActionStore,
    backgroundImageStore: BackgroundImageStore,
    auditStore: AuditStore,
    notificationStore: notificationStore,
    profileStore: profileStore,
    contentSettingsStore: contentSettingsStore,
    publicProfileStore: publicProfileStore,
    referralStore: referralStore,
    analyticsStore: AnalyticsStore,
    modalControllerStore: modalControllerStore,
    authActiveTabIndex: types.optional(types.string, ""),
    loginModalVisible: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    reset: (shouldBeTrue = false) => {
      applySnapshot(self, {
        ...intialStateData,
        auth: logoutAuthStoreState,
        loginModalVisible: !!shouldBeTrue,
        authActiveTabIndex: "login",
      });
    },

    // set active tab on auth modal
    setAuthActiveTabIndex(tab) {
      self.authActiveTabIndex = tab;
    },

    // open/close auth modal
    setLoginModalVisible(value) {
      self.loginModalVisible = value;
    },

    loadData: flow(function* loadData() {
      try {
        yield self.contentSettingsStore.loadContentSettings();
        yield self.feedsStore.getContentMeta();
      } catch (e) {}

      try {
        yield Promise.allSettled([self.feedsStore.getFolksBannerData(), self.feedsStore.getFolksDeeplinks()]);
        self.feedsStore.getGenreDeeplinks();
        self.feedsStore.getSlingShows(); // Sets sling TV show rows
        self.feedsStore.getMovieShowsBanners();
      } catch (e) {}
      return Promise.resolve({ success: true });
    }),
    // eslint-disable-next-line require-yield
    lazyLoad: flow(function* lazyLoad() {
      try {
        yield self.profileStore.loadProfileData();
      } catch (error) {}
      self.playlistStore.loadUserPlaylistData();
      self.referralStore.loadReferralData();
      self.auditStore.fetchFeedbackContent();
      self.actionStore.fetchEmotions({ landing: false });
      self.actionStore.fetchEmotions({ landing: true }); // Sets emotion deeplinks
    }),
  }));

const initialState = (window.store = RootModel.create(intialStateData));

export const store = (window.store = initialState);
export const RootStoreContext = createContext(null);
export const Provider = RootStoreContext.Provider;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error("Store cannot be null, please add a context provider");
  }
  return store;
}
