export function getUTCDateTime() {
  const localDate = new Date();
  localDate.setUTCHours(
    localDate.getHours(),
    localDate.getMinutes(),
    localDate.getSeconds(),
    localDate.getMilliseconds()
  );
  return localDate.toISOString();
}
