import React, { useEffect } from "react";
import googleStore from "../../../assets/images/google-store.png";
import appleStore from "../../../assets/images/apple-store.png";
import styles from "./footer.module.css";
import { APP_LINKS } from "../../../utils/redirectionSettings";
import logo from "../../../assets/images/fm_logo.svg";
import footerBg from "../../../assets/images/Footer_bg.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

export default function Footer() {
  const location = useLocation();

  const checkPath = () => {
    if (location.pathname === "/about-us") {
      return false;
    } else {
      return true;
    }
  };

  const basedOnHeight = () => {
    if (location.pathname === "/reset-password") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      {checkPath() ? (
        <div
          className={basedOnHeight() ? styles.storeContainer : styles.storeContainer1}
          style={{ backgroundImage: `url(${footerBg})` }}
        >
          <div className={styles.leftSide}>
            <img className={styles.footerImage} src={logo} alt="" />
            <div className={styles.copyRight}>@2024-Copyright Folksmedia</div>
          </div>
          <div className={styles.rightSide}>
            <div className={styles.textContainer}>
              <Link to="/about-us" className={styles.links}>
                About Us
              </Link>
              <Link to="/my-account/license" className={styles.links}>
                License
              </Link>
              <Link to="/terms-of-use" className={styles.links}>
                Terms of use
              </Link>
              <Link to="/privacy-policy" className={styles.links}>
                Privacy Policy
              </Link>
            </div>
            <div className={styles.storeSubContainer1}>
              <div className={styles.footerImageContainer}>
                <a href={APP_LINKS.APPLE_STORE} target="_blank" rel="noreferrer">
                  <img className={styles.footerImage1} src={appleStore} alt="" />
                </a>
              </div>
              <div className={styles.footerImageContainer}>
                <a href={APP_LINKS.ANDROID_STORE} target="_blank" rel="noreferrer">
                  <img className={styles.footerImage1} src={googleStore} alt="" />
                </a>
              </div>
            </div>
            <div className={styles.versionDescriber}>
              {process.env.REACT_APP_VERSION || process.env.REACT_APP_WEBSITE_NAME}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}
