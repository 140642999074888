function isValidPlaylistObj(obj) {
  return (
    obj?.watchlist_name?.length &&
    obj?.privacy &&
    obj?.user_id &&
    obj?.author_name?.length &&
    typeof obj?.is_owner === "boolean" &&
    obj?.watchlist_id
  );
}

export const sanitizePlaylistData = (data) => {
  const playlistData = data?.filter(isValidPlaylistObj);
  return playlistData?.length ? playlistData : [];
};
