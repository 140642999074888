import { Checkbox } from "antd";
import styled from "styled-components";

export const AuthCheckBox = styled(Checkbox)`
  &&& {
    font-size: 1vw;
    .checkbox-link > a {
      color: #ea3a1b;
    }

    .ant-checkbox .ant-checkbox-inner {
      width: 1vw;
      height: 1vw;
      border-color: #ea3a1b;
      font-size: 1vw;
      border: 0.13vw solid #fff;
    }

    .ant-checkbox-checked .ant-checkbox-inner {
      width: 1vw;
      height: 1vw;
      background-color: #ea3a1b;
      border-color: #ea3a1b;
      border: 0.13vw solid #ea3a1b;
      font-size: 1vw;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 0.25vw;
      left: 0;
      border: 0.1vw solid #fff;
      border-top: 0;
      border-left: 0;
    }

    .ant-checkbox-inner::after {
      width: 0.4vw;
      height: 0.7vw;
    }

    .ant-checkbox-checked::after {
      border: none;
    }
  }
  @media (max-width: 480px) {
    &&& {
      font-size: 3vw;
      .ant-checkbox .ant-checkbox-inner {
        width: 3vw;
        height: 3vw;
        font-size: 3vw;
        margin-bottom: 0.5vw;
      }
      .ant-checkbox-checked .ant-checkbox-inner {
        width: 3vw;
        height: 3vw;
        font-size: 3vw;
      }
      .ant-checkbox-inner::after {
        width: 1.5vw;
        height: 1.5vw;
        margin-top: 1vw;
        margin-left: 0.2vw;
      }
    }
  }
`;
