import { Checkbox } from "antd";
import React from "react";
import styled from "styled-components";

const FolksCheckbox = (props) => {
  return (
    <CheckboxContainer>
      <Checkbox {...props}>{props.children}</Checkbox>
    </CheckboxContainer>
  );
};

const CheckboxContainer = styled.div`
  /* readio button override */

  // ---- color ----
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #c65243;
    background: #c65243;
  }

  .ant-checkbox-inner::after {
    background-color: #c65243;
  }

  .input.ant-checkbox-input {
    background-color: #c65243;
  }
  /* Unchecked state with blue border  */
  .ant-checkbox:not(.ant-checkbox-checked) .ant-checkbox-inner {
    border-color: white;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #c65243;
  }
`;

export default FolksCheckbox;
