// Constants:

// Local storage keys.
export const LS_KEY_COGNITO_ID_TOKEN = "folks_cognito_id_token";
export const LS_KEY_FOLKS_USER_TOKEN = "folks_user_token";
export const LS_KEY_FOLKS_USER_TYPE = "folks_user_type";
export const LS_KEY_FOLKS_USER_ID = "folks_user_id";

// cookie storage keys.
export const CO_KEY_FIREBASE_DEVICE_TOKEN = "fbase_device_token";

// Background image URLs.
export const BG_IMG_URL_RANDOM_CARDS =
  "https://sensara.co/media/images_v2/HH0Xxs-g0HIxL7ojYvE6zlgrBAk=/900x0/smart/hips.hearstapps.com%2Fhmg-prod.s3.amazonaws.com%2Fimages%2Fnetflix-shows-1586555714.jpg";

// interceptors key
export const MAX_REQ_TIME_OUT_MS = 120000;

// cookies expiration time in days
export const MAX_COOKIE_TIME_OUT_DAYS = 90;

// Media card scale factor:
export const CONTENT_MEDIA_CARD_SCALE_FACTOR = 1.4;

// sling web base url:
export const SLING_WEB_BASE_URL = "https://watch.sling.com/1/asset/";

// people content type
export const peopleContentTypeValues = [22, 27, 28, 29];

export const SEARCH_COUNT = 20;

export const MIN_ITEMS_PER_ROW = 9;
