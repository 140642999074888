import { Modal } from "antd";
import { IoMdCloseCircleOutline } from "react-icons/io";
import styled from "styled-components";

const LoginFolksModal = styled(Modal).attrs(({ width, centered, zIndex }) => ({
  footer: null,
  width: width || "90vw",
  centered: centered || false,
  zIndex: zIndex || 1000,
  destroyOnClose: true,
  closeIcon: <IoMdCloseCircleOutline style={{ color: "white", fontSize: "2vw" }} />,
}))`
  &&& {
    .ant-modal-body {
      padding: 0vw;
      border-radius: 1.5vw;
      // height: 92vh;
      height: 95vh;
      overflow-y: auto;
    }
    .ant-modal-content {
      background-color: #1a1a1c;
      border-radius: 1vw;
    }

    // .ant-modal-close-x {
    //   height: 3vw;
    //   width: 3vw;
    //   line-height: 3vw;
    //   font-size: 1vw;
    // }
    // .ant-modal-close-x .anticon {
    //   border: solid 2px white;
    //   padding: 4px;
    //   border-radius: 20px;
    // }
    // .ant-modal-close-x .anticon svg {
    //   font-weight: bold;
    //   color: white;
    // }
  }
`;

export default LoginFolksModal;
