// keys should be unique.
const SS_CACHE_DETAILS_KEY_PREFIX = "ss_cache_details_";
const SS_CACHE_BASIC_DETAILS_KEY_PREFIX = "ss_cache_basic_details_";
const SS_CACHE_GENRE_DETAILS_KEY_PREFIX = "ss_cache_genre_details_";

export const ssDetailsKeyGenerator = (key) => {
  return `${SS_CACHE_DETAILS_KEY_PREFIX}${key}`;
};

export const ssBasicDetailsKeyGenerator = (key) => {
  return `${SS_CACHE_BASIC_DETAILS_KEY_PREFIX}${key}`;
};

export const ssGenreDetailsKeyGenerator = (key) => {
  return `${SS_CACHE_GENRE_DETAILS_KEY_PREFIX}${key}`;
};
