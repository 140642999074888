import React from "react";
import Loader from "../../assets/images/front-loader.mp4";
import styles from "./frontLoader.module.css";

const FrontLoader = () => {
  return (
    <div className={styles.loaderContainer}>
      <video autoPlay playsInline loop muted preload="auto" src={Loader} height="125px" width="125px" />
    </div>
  );
};

export default FrontLoader;
