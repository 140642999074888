import React, { useState, useEffect } from "react";
import { Button } from "antd";
import { useMst } from "../../../../store";
import { LoadingOutlined } from "@ant-design/icons";
import { IoMdGlobe, IoMdLock } from "react-icons/io";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { PlaylistInput, CustomPlaylistModal, PlaylistParagraph, CustomRadio } from "./styledComponent";
import { FeedBackBtnP } from "../../../../components/folks-modal/ModalFooterButton";
// CSS imports [Scope => Playlist modal]
import styles from "./playlistModal.module.css";
import FolksCheckbox from "./FolksCheckbox";
import { URLS } from "../../../../routes/routeURL";
import { ModalHeadingText } from "../../../../components/typography/ModalText";

const PlaylistModal = ({
  imdbId,
  visiblity,
  handleClose,
  isEdit,
  userAccountPlaylist,
  watchlistId,
  setPlaylistDataById,
  playlistDataById,
}) => {
  const { playlistStore, notificationStore } = useMst();
  const history = useHistory();

  const [wlistArr, setWlistArr] = useState([]);
  const [orginalWlistArr, setOrginalWlistArr] = useState([]);

  const [createWatchlistScreen, setCreateWatchlistScreen] = useState(false);
  const [newWatchlistName, setNewWatchlistName] = useState("");
  const [newWatchlistPrivacy, setNewWatchlistPrivacy] = useState("public");
  const [emptyTitle, setEmptyTitle] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addingToWatchlist, setAddingToWatchlist] = useState(false);

  const handlePlaylistOnToastClick = (wlistArr) => {
    if (wlistArr.length > 1) {
      history.push(URLS.MANAGE_PLAYLIST);
    } else {
      const playlist_id = wlistArr[0];
      history.push(URLS.PUBLIC_USER_PLAYLIST({ playlist_id }));
    }
  };

  const Msg = ({ wlistArr }) => (
    <p>
      Playlist updated successfully
      <span>
        <Button
          type="text"
          onClick={() => handlePlaylistOnToastClick(wlistArr)}
          style={{ color: "green", margin: "0px" }}
        >
          View Playlist
        </Button>
      </span>
    </p>
  );

  // useEffect(() => {
  //   if (wlistArr?.length) {
  //     setOrginalWlistArr(wlistArr);
  //   }
  // }, [wlistArr]);

  useEffect(() => {
    if (isEdit) {
      const data = playlistStore.listOfPlaylists?.filter((item) => item?.watchlist_id === watchlistId)[0];

      // fetch from store if playlist exist in store or get from passed props
      const playlistDetails = {
        watchlist_name:
          data?.watchlist_name || data?.title || playlistDataById?.title || playlistDataById?.watchlist_name,
        privacy: data?.privacy || playlistDataById?.privacy,
      };
      setNewWatchlistName(playlistDetails?.watchlist_name);
      setNewWatchlistPrivacy(playlistDetails?.privacy);
      setCreateWatchlistScreen(true);
    } else {
      setNewWatchlistName("");
      setNewWatchlistPrivacy("public");
    }
  }, [isEdit, watchlistId]);

  useEffect(() => {
    if (userAccountPlaylist) {
      setCreateWatchlistScreen(true);
    }
    setWlistArr(playlistStore.userPlaylistIds);
    setOrginalWlistArr(JSON.parse(JSON.stringify(playlistStore.userPlaylistIds)));
  }, [userAccountPlaylist, visiblity]);

  const updateWatchlist = async () => {
    setAddingToWatchlist(true);
    try {
      const res = await playlistStore.addTitleToPlaylists(wlistArr, imdbId);
      if (res?.success) {
        if (wlistArr.length !== 0) {
          notificationStore.triggerSuccess(<Msg wlistArr={wlistArr} />);
        }
        handleClose();
      }
      setAddingToWatchlist(false);
    } catch (error) {
      setAddingToWatchlist(false);
    }
  };

  const addToWatchlistUpdate = (wlist_id, is_checked) => {
    if (is_checked) {
      setWlistArr([...wlistArr, wlist_id]);
    } else {
      setWlistArr(wlistArr.filter((item) => item !== wlist_id));
    }
  };

  const createNewWatchlist = async () => {
    if (newWatchlistName.trim()) {
      setIsLoading(true);
      try {
        const result = await playlistStore.createNewPlaylist(newWatchlistName.trim(), newWatchlistPrivacy);
        setIsLoading(false);
        if (result.success) {
          addToWatchlistUpdate(result.data, true);
          setNewWatchlistName("");
          setNewWatchlistPrivacy("public");
          if (userAccountPlaylist) {
            handleClose();
          } else {
            setCreateWatchlistScreen(false);
          }
        }
      } catch (err) {
        notificationStore.triggerError(err?.msg);
        setIsLoading(false);
      }
    } else {
      setEmptyTitle(true);
    }
  };

  const closeWatchlistModal = () => {
    setWlistArr([]);
    setAddingToWatchlist(false);
    setEmptyTitle(false);
    setNewWatchlistName("");
    if (userAccountPlaylist || isEdit) {
      setCreateWatchlistScreen(true);
    } else {
      setCreateWatchlistScreen(false);
    }
    handleClose();
  };

  const cancelCreateScreen = () => {
    setEmptyTitle(false);
    setIsLoading(false);
    setNewWatchlistName("");
    // Go back to add to watchlist.
    if (userAccountPlaylist || isEdit) {
      handleClose();
    } else {
      setCreateWatchlistScreen(false);
    }
  };

  const handleEditPlaylist = async () => {
    if (newWatchlistName.trim().length > 0) {
      try {
        setIsLoading(true);
        const res = await playlistStore.updatePlaylist(watchlistId, newWatchlistName.trim(), newWatchlistPrivacy);
        setIsLoading(false);
        if (res?.success) {
          if (setPlaylistDataById) {
            setPlaylistDataById((prev) => ({
              ...prev,
              title: newWatchlistName.trim(),
              privacy: newWatchlistPrivacy,
            }));
          }
          handleClose();
        }
      } catch (err) {
        setIsLoading(false);
      }
    } else {
      setEmptyTitle(true);
    }
  };

  const areArraysEqual = (arr1, arr2) => {
    if (arr1.length === 0 && arr2.length === 0) return true;
    const set1 = new Set(arr1);
    const set2 = new Set(arr2);
    if (set1.size !== set2.size) return false;

    return [...set1].every((element) => set2.has(element));
  };

  return (
    <>
      <CustomPlaylistModal title="" visible={visiblity} onCancel={closeWatchlistModal} style={{ fontSize: "20px" }}>
        <div className={styles.modalPlayListContainer}>
          <div className={styles.modalPlayList}>
            {createWatchlistScreen ? (
              <>
                <h4 className={styles.playlistText}>{isEdit ? "Edit Playlist" : "New Playlist"}</h4>
                <p className={styles.title}>Title</p>
                <div className={styles.playlistInputContainer}>
                  <div className={styles.watchlistInput}>
                    <PlaylistInput
                      placeholder="Enter Title"
                      value={newWatchlistName}
                      onChange={(e) => {
                        setNewWatchlistName(e.target.value);
                        if (emptyTitle) {
                          setEmptyTitle(false);
                        }
                      }}
                    />
                  </div>
                </div>

                {emptyTitle ? <span className={styles.emptyTitle}>* Title is required</span> : null}

                <div className={styles.privacyContainer}>
                  <div className={styles.privacyText}>Privacy</div>

                  <div className={styles.privacyButtonContainer}>
                    <div
                      className={styles.privacyDivision}
                      onClick={(e) => {
                        e.stopPropagation();
                        setNewWatchlistPrivacy("public");
                      }}
                    >
                      <CustomRadio className={styles.radio} checked={newWatchlistPrivacy === "public" ? true : false} />
                      <div className={styles.privContainer}>
                        <div className={styles.privTitle}>Public</div>
                        <div className={styles.privDesc}>Anyone can search for and view</div>
                      </div>
                    </div>

                    <div
                      className={styles.privacyDivision}
                      onClick={(e) => {
                        e.stopPropagation();
                        setNewWatchlistPrivacy("private");
                      }}
                    >
                      <CustomRadio
                        className={styles.radio}
                        checked={newWatchlistPrivacy === "private" ? true : false}
                      />
                      <div className={styles.privContainer}>
                        <div className={styles.privTitle}>Private</div>
                        <div className={styles.privDesc}>Only you can view</div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.watchlistButtonContainer}>
                  <FeedBackBtnP
                    onClick={(e) => {
                      e.stopPropagation();
                      cancelCreateScreen();
                    }}
                    style={{ marginRight: "15px" }}
                  >
                    Cancel
                  </FeedBackBtnP>

                  <FeedBackBtnP
                    positive={true}
                    onClick={(e) => {
                      e.stopPropagation();
                      isEdit
                        ? handleEditPlaylist(watchlistId, newWatchlistName.trim(), newWatchlistPrivacy)
                        : createNewWatchlist();
                    }}
                  >
                    {isLoading && <LoadingOutlined color="white" spin />} {isEdit && <span>Update</span>}{" "}
                    {userAccountPlaylist && <span>Create</span>}{" "}
                    {!isEdit && !userAccountPlaylist && <span>Create</span>}
                  </FeedBackBtnP>
                </div>
              </>
            ) : (
              <>
                <div className={styles.addToContainer}>
                  <h1 className={styles.addToText}>Add to</h1>
                  <span
                    className={styles.newPlaylistButton}
                    onClick={(e) => {
                      e.stopPropagation();
                      setCreateWatchlistScreen(true);
                    }}
                  >
                    + New Playlist
                  </span>
                </div>
                <div className={styles.playlistContainer}>
                  {playlistStore.listOfPlaylists?.length ? (
                    playlistStore.listOfPlaylists
                      ?.filter((item) => item?.watchlist_name?.length > 0)
                      .map((obj) => {
                        return (
                          <div className={styles.playlistCheckBoxContainer} key={obj?.watchlist_id}>
                            <FolksCheckbox
                              key={obj?.watchlist_id}
                              checked={wlistArr?.includes(obj?.watchlist_id)}
                              onChange={(e) => {
                                e.stopPropagation();
                                addToWatchlistUpdate(obj?.watchlist_id, e.target.checked);
                              }}
                            />
                            <div className={styles.newPlaylistCont}>
                              <PlaylistParagraph ellipsis={true ? { rows: 1, expandable: false } : false}>
                                {obj?.watchlist_name}
                              </PlaylistParagraph>
                              {obj?.privacy === "public" ? (
                                <IoMdGlobe className={styles.newIcon} />
                              ) : (
                                <IoMdLock className={styles.newIcon} />
                              )}
                            </div>
                          </div>
                        );
                      })
                  ) : (
                    <h3 className={styles.noDataAvailable}>
                      {playlistStore?.playlistLoader ? "Loading..." : "No Data available"}
                    </h3>
                  )}
                </div>

                <FeedBackBtnP
                  positive={!areArraysEqual(wlistArr, orginalWlistArr)}
                  onClick={(e) => {
                    e.stopPropagation();
                    playlistStore.listOfPlaylists?.filter((item) => item?.watchlist_name?.length > 0)?.length
                      ? updateWatchlist()
                      : notificationStore.triggerError("Create Playlist to Update");
                  }}
                  className={styles.seenButton}
                  disabled={areArraysEqual(wlistArr, orginalWlistArr)}
                >
                  {addingToWatchlist && <LoadingOutlined color="white" spin />}
                  <span className={styles.doneBtn}>Done</span>
                </FeedBackBtnP>
              </>
            )}
          </div>
        </div>
      </CustomPlaylistModal>
    </>
  );
};

export default observer(PlaylistModal);
