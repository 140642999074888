import React, { useState } from "react";
import ConfirmModal from "../content-discovery/details/components/modals/confirm-modal/ConfirmModal";
import PlaylistModal from "../content-discovery/details/components/playlist-modal/PlaylistModal";
import { useMst } from "../store";
import ShareLinkModal from "../content-discovery/details/components/share-link-modal/ShareLinkModal";
import { playlistShareUrl } from "../utils/helper";

const withPlaylistHelper = (WrappedComponent) => {
  const NewComponent = (props) => {
    const { playlistStore } = useMst();
    const [isVisible, setIsVisible] = useState(false);
    const [isNewPlaylistModalVisible, setIsNewPlaylistModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [watchlistId, setWatchListId] = useState("");
    const [userAccountPlaylist, setUserAccountPlaylist] = useState(false);
    const [isShareModalVisible, setIsShareModalVisible] = useState(false);
    const [playlistDataById, setPlaylistDataById] = useState({});

    const handleEditWatchlist = async (watchlist_id) => {
      setIsNewPlaylistModalVisible(true);
      setWatchListId(watchlist_id);
      setIsEdit(true);
      setUserAccountPlaylist(false);
    };

    const handleCreateNewPlaylist = async () => {
      setIsNewPlaylistModalVisible(true);
      setUserAccountPlaylist(true);
      setIsEdit(false);
      setWatchListId("");
    };

    const handelNewPlaylistCancel = () => {
      setIsNewPlaylistModalVisible(false);
      setIsEdit(false);
    };

    const handleCancelModal = () => {
      setIsVisible(false);
    };

    const onDeletePlaylist = (playlist_id) => {
      if (!playlist_id) return null;
      setWatchListId(playlist_id);
      setIsVisible(true);
    };

    const handlePlaylistDelete = async (e) => {
      if (e) {
        e?.preventDefault();
        e?.stopPropagation();
      }

      if (!watchlistId || isLoading) return null;
      try {
        setIsLoading(true);
        const res = await playlistStore.deletePlaylist(watchlistId);
        if (res?.success) {
          setIsLoading(false);
          setIsVisible(false);
          setPlaylistDataById({
            isDeleted: true,
          });
        }
      } catch (err) {
        console.log({ depajerror: err });
        setIsLoading(false);
        setIsVisible(false);
        return;
      }
    };

    const handleShareCancel = () => {
      setIsShareModalVisible(false);
    };

    const onShare = (playlist) => {
      setPlaylistDataById(playlist);
      setIsShareModalVisible(true);
    };

    return (
      <>
        <WrappedComponent
          {...props}
          onEdit={handleEditWatchlist}
          onShare={onShare}
          playlistDataById={playlistDataById}
          setPlaylistDataById={setPlaylistDataById}
          onCreatePlaylist={handleCreateNewPlaylist}
          onDeletePlaylist={onDeletePlaylist}
        />
        {isVisible ? (
          <ConfirmModal
            isVisible={isVisible}
            handleCancel={handleCancelModal}
            isLoading={isLoading}
            handleConfirm={handlePlaylistDelete}
            text={"Are you sure you want to delete?"}
          />
        ) : null}
        {isNewPlaylistModalVisible ? (
          <PlaylistModal
            visiblity={isNewPlaylistModalVisible}
            handleClose={handelNewPlaylistCancel}
            isEdit={isEdit}
            watchlistId={watchlistId}
            userAccountPlaylist={userAccountPlaylist}
            setPlaylistDataById={setPlaylistDataById}
            playlistDataById={playlistDataById}
          />
        ) : null}
        {isShareModalVisible && playlistDataById ? (
          <ShareLinkModal
            shareUrl={playlistShareUrl(playlistDataById?.watchlist_id)}
            isShareModalVisible={isShareModalVisible}
            handleCancel={handleShareCancel}
            movieTitle={playlistDataById?.title}
            sharePlaylist={true}
          />
        ) : null}
      </>
    );
  };
  return NewComponent;
};

export default withPlaylistHelper;
