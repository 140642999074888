import React from "react";
import styles from "./termsOfUse.module.css";

export default function TermsOfUse() {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <div className={styles.termsOfUseContainer}>
        <div className={styles.termsOfUseBody}>
          <h6 className="fw-bold">Terms of Service</h6>
          <i>Dated: November 22, 2021</i>
          <br />
          <br />
          <br />
          <div>
            <h5 className="fw-bold">1. Eligibility and Acceptance</h5>

            <p className="lh-lg">
              These terms of service are entered into by and between you and Folksmedia. Folksmedia is a subsidiary of
              “Adcuratio Media, Inc”(“Adcuratio,” “Folkmedia,” “we,” or “us”). Folksmedia and Folks media are registered
              trademarks of M/S Foksmedia Inc. The following terms and conditions, together with any documents they
              expressly incorporate by reference (collectively, “Terms of Service”), govern your access to and use of
              the Services, including, without limitation, Folksmedia’s OTT box and related applications. “Services”
              means Folksmedia’s OTT box, websites, mobile applications, content, tools, functionality, product
              marketplaces and other services, also referred to as FolksMedia. Please read the Terms of Service
              carefully before you start to use the Services. By using the Services or by clicking to accept or agree to
              the Terms of Service when this option is made available to you, you accept and agree to be bound and abide
              by these Terms of Service and by our{" "}
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>
              , incorporated herein by reference. If you do not want to agree to these Terms of Service, you must not
              access or use the Services. The Services are offered and available only to users who:
            </p>

            <ul>
              <li>Reside in the United States or any of its territories or possessions; and</li>
              <li>Are 18 years of age or older</li>
            </ul>

            <p className="lh-lg">
              By using the Services, you represent and warrant that you are of legal age to form a binding contract with
              Folksmedia and meet all of the foregoing eligibility requirements. If you do not meet all of these
              requirements, you must not access or use the Services.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">2. Updates to the Terms</h5>

            <p className="lh-lg">
              We may revise and update these Terms of Use from time to time in our sole discretion. All changes are
              effective immediately when we post them and apply to all access to and use of the Services thereafter.
              However, any changes to the dispute resolution provisions set out in Governing Law and Jurisdiction will
              not apply to any disputes for which the parties have actual notice before the date the change is posted on
              the Website. Your continued use of the Services following the posting of revised Terms of Use means that
              you accept and agree to the changes. You are expected to check this page from time to time so you are
              aware of any changes, as they are binding on you.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">3. Accessing the Services; Account Registration and Security</h5>

            <p className="lh-lg">
              We reserve the right to introduce, withdraw or amend websites, mobile applications or any content,
              material, product offering or other Services, in our sole discretion without notice. We will not be liable
              if for any reason all or any part of the Services is unavailable at any time or for any period. From time
              to time, we may restrict access to some parts of the Services, or the entire Services, to users. You are
              responsible for making all arrangements necessary for you to have access to the Services. To access the
              Services or some of its resources, you may be asked to provide certain registration details or other
              information. It is a condition of your use of the Services that all the information you provide to access
              the Services is correct, current and complete. You agree that all information you provide to register for
              these Services or otherwise, including, without limitation, through the use of any interactive features,
              is governed by our{" "}
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>
              , and you consent to all actions we take with respect to your information consistent with our{" "}
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>
              . If you choose, or are provided with, a user name, password or any other piece of information as part of
              our security procedures, you must treat such information as confidential, and you must not disclose it to
              any other person or entity. You also acknowledge that your account is personal to you and agree not to
              provide any other person with access to the Services or portions of them using your user name, password or
              other security information. You agree to notify us immediately of any unauthorized access to or use of
              your user name or password or any other breach of security. If you are using a public or shared computer,
              you also agree to ensure that you exit from your account at the end of each session. You should use
              particular caution when accessing your account from a public or shared computer so that others are not
              able to view or record your password or other personal information. We have the right to disable any user
              name, password or other identifier, whether chosen by you or provided by us, at any time if, in our
              opinion, you have violated any provision of these Terms of Service.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">4. Intellectual Property Rights</h5>

            <p className="lh-lg">
              The Services and their entire contents, features and functionality (including without limitation all
              information, software, text, displays, images, video and audio, and the design, selection and arrangement
              thereof) are owned by Folksmedia, its licensors or other providers of such material and are protected by
              United States and international copyright, trademark, patent, trade secret and other intellectual property
              or proprietary rights laws. We grant you a limited, personal, non-exclusive and non-transferable license
              to access and use the Services only as expressly permitted in these Terms of Service. You must not
              reproduce, distribute, license, sell, modify, create derivative works of, publicly display, publicly
              perform, republish, broadcast, download, store, or transmit any of the material accessible using our
              Services, except as follows:
            </p>

            <ul className="lh-lg">
              <li>
                Your computer may temporarily store copies of such materials in RAM incidental to your accessing and
                viewing those materials.
                <ul>
                  <li>
                    You may store files that are automatically cached by your Web browser for display enhancement
                    purposes.
                  </li>
                  <li>
                    You may print one copy of a reasonable number of pages of the Services for your own personal,
                    non-commercial use and not for further reproduction, publication or distribution.
                  </li>
                  <li>
                    You may download a single copy of any mobile application to your mobile device solely for your own
                    personal, non-commercial use, provided you agree to be bound by our end user license agreement for
                    such applications.
                  </li>
                  <li>
                    If we provide social media features with certain content, you may take such actions as are enabled
                    by such features.
                  </li>
                </ul>
              </li>
            </ul>

            <p className="lh-lg">You must not:</p>

            <ul className="lh-lg">
              <li>Modify copies of any materials of the Services.</li>
              <li>
                Delete or alter any copyright, trademark or other proprietary rights notices from copies of materials
                from this site.
              </li>
            </ul>

            <p>
              If you print, copy, modify, download or otherwise use or provide any other person with access to any part
              of the Services in breach of the Terms of Service, your right to use the Services will stop immediately,
              and you must, at our option, return or destroy any copies of the materials you have made. No right, title
              or interest in or to the Services or any content on the Services is transferred to you, and all rights not
              expressly granted are reserved by Folksmedia. Any use of the Services not expressly permitted by these
              Terms of Service is a breach of these Terms of Service and may violate copyright, trademark and other
              laws. The Folksmedia name, trademarks and all related names, logos, product and service names, designs and
              slogans are trademarks of Folksmedia. You must not use such marks without the prior written permission of
              Folksmedia. All other names, logos, product and service names, designs and slogans that may appear in the
              Services are the trademarks of their respective owners.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">5. Prohibited Uses</h5>

            <p className="lh-lg">
              You may use the Services only for lawful purposes and in accordance with these Terms of Service. You agree
              not to use the Services:
            </p>

            <ul className="lh-lg">
              <li>In any way that competes with our business of providing our Services.</li>
              <li>
                In any way that violates any applicable federal, state, local or international law or regulation
                (including, without limitation, any laws regarding the export of data or software to and from the US or
                other countries).
              </li>
              <li>
                For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing
                them to inappropriate content, asking for personally identifiable information or otherwise.
              </li>
              <li>
                To send, knowingly receive, upload, download, use or re-use any material that does not comply with the
                Content Standards set out in these Terms of Service.
              </li>
              <li>
                To transmit, or procure the sending of, any mass advertising or promotional material, including, without
                limitation, any “junk mail”, “chain letter”, “spam” or any other similar solicitation.
              </li>
              <li>
                To impersonate or attempt to impersonate Folksmedia, an Folksmedia employee, another user or any other
                person or entity (including, without limitation, by using email addresses or user names associated with
                any of the foregoing).
              </li>
              <li>
                To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Services, or
                which, as determined by us, may harm Folksmedia or users of the Services, or expose them to liability.
              </li>
            </ul>

            <p className="lh-lg">Additionally, you agree not to:</p>

            <ul className="lh-lg">
              <li>
                Use the Services in any manner that could disable, overburden, damage or impair the site or interfere
                with any other party’s use of the Services, including, without limitation, their ability to engage in
                real time activities through the Services.
              </li>
              <li>
                Use any robot, spider or other automatic device, process or means to access the Services for any
                purpose, including, without limitation, monitoring or copying any of the material on the Services.
              </li>
              <li>
                Use any manual process to monitor or copy any of the material on the Services, or for any other purpose
                not expressly authorized in these Terms of Service, without our prior written consent.
              </li>
              <li>Use any device, software or routine that interferes with the proper working of the Services. </li>
              <li>
                Introduce any viruses, Trojan horses, worms, logic bombs or other material that is malicious or
                technologically harmful.
              </li>
              <li>
                Attempt to gain unauthorized access to, interfere with, damage or disrupt any parts of the Services, the
                server on which the Services is stored or any server, computer or database connected to the Services.
              </li>
              <li>Attack the Services via a denial-of-service attack or a distributed denial-of-service attack.</li>
              <li>Otherwise attempt to interfere with the proper working of the Services.</li>
            </ul>
          </div>
          <div>
            <h5 className="fw-bold">6. Text Messaging Terms</h5>

            <p className="lh-lg">
              We and other service providers may send messages (including, without limitation, transactional and
              marketing messages) to recipients who wish to receive them. Topics of such messages may include, without
              limitation, matters relating to the Services. These messages may be sent by means of SMS, MMS or other
              text message technologies (“Text Messages”). To the extent that you opt to have Text Messages sent to you,
              the following terms apply:
            </p>

            <ul className="lh-lg">
              <li>
                Please anticipate receiving up to approximately five (5) Text Messages per month, but we may vary the
                number of Text Messages per month.
              </li>
              <li>
                If you are experiencing issues with Text Messages, please reply to a Text Message with the keyword HELP
                or contact{" "}
                <a href="mailto:support@folksmedia.com" target="_blank" rel="noreferrer" className="primary-text-color">
                  support@folksmedia.com
                </a>
                .
              </li>
              <li>
                In addition to any fees of which you are notified, your mobile provider’s standard message and data
                rates may apply to our confirmation Text Message and all subsequent Text Messages, based on your
                individual rate plan provided by your wireless carrier. Please consult your carrier’s pricing plan to
                determine charges for browsing data and sending and receiving Text Messages. Unders no circumstances
                will we be responsible for any text messaging or other wireless charges incurred by you or anyone else
                who has access to your wireless device or telephone number. If your carrier does not permit Text
                Messages of the type that we or our service providers send, you may not receive the Text Messages from
                us.
              </li>
              <li>
                You also authorize your mobile provider or other service provider to disclose your wireless phone
                number, provider name, name, physical address, email address, customer type, customer role, billing
                type, network status, mobile device identifiers, date and time and content of messages and other user
                and device details, if available, to us and our service providers. We and our service providers may use
                this information for anti-fraud and identity confirmation, to contact you, to provide the Services that
                you request from us and to provide other information that we expect may be of interest to you.
              </li>
              <li>
                If you do not wish to receive Text Messages from us or our service providers, or if you no longer wish
                to permit your mobile provider or other service provider to share information about you with us or our
                service providers, you agree to reply STOP (in precisely the format written in this sentence, without
                modification) to any Text Message sent by or on behalf of us in order to opt out of the Text Message
                notifications. You may receive an additional Text Message reflecting your opt-out.
              </li>
              <li>
                We will not be responsible for any delays in your receipt of any Text Messages. You acknowledge that
                delivery is subject to effective transmission from your network operator. Text Message services are
                provided on an “AS IS” basis.
              </li>
              <li>
                By providing us with your wireless phone number, you agree that we may send to you at that phone number
                information that we think may be of interest to you, including, without limitation, Text Messages at the
                wireless number you provided.
              </li>

              <li>
                If at any time you intend to stop using the mobile telephone number that has been used to subscribe to
                Text Messages, including, without limitation, canceling your service plan or selling or transferring the
                phone number to another party, you agree that you will reply STOP (in precisely the format written in
                this sentence, without modification) to any Text Message sent by or on behalf of us, prior to ending
                your use of the mobile telephone number. You understand and agree that your agreement to do so is a
                material part of these Terms of Service. You further agree that, if you discontinue the use of your
                mobile telephone number without notifying us of such change, you agree that you will be responsible for
                all costs (including, without limitation, attorneys’ fees) and liabilities incurred by us, or any party
                that assists in the delivery of the mobile messages, as a result of claims brought by individual(s) who
                are later assigned that mobile telephone number. This duty and agreement shall survive any cancellation
                or termination of your agreement to receive Services or Text Messages.
              </li>

              <li>
                YOU AGREE THAT YOU SHALL INDEMNIFY, DEFEND AND HOLD US HARMLESS FROM ANY CLAIM OR LIABILITY RESULTING
                FROM YOUR FAILURE TO NOTIFY US OF A CHANGE IN THE INFORMATION YOU HAVE PROVIDED, INCLUDING ANY CLAIM OR
                LIABILITY UNDER THE TELEPHONE CONSUMER PROTECTION ACT, 47 U.S.C. § 227, et seq., OR SIMILAR STATE AND
                FEDERAL LAWS, AND ANY REGULATIONS PROMULGATED THEREUNDER RESULTING FROM US ATTEMPTING TO CONTACT YOU AT
                THE MOBILE TELEPHONE NUMBER YOU PROVIDED.”
              </li>

              <p className="lh-lg">
                For more information on how we use telephone numbers and other personal information, please read our
                <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                  Privacy Policy
                </a>
              </p>
            </ul>
          </div>
          <div>
            <h5 className="fw-bold">7. User Submissions</h5>

            <p className="lh-lg">
              The Services may contain interactive features (collectively, “Interactive Services”) that allow users to
              post, submit, publish, display or transmit to other users or other persons, or otherwise provide
              (hereinafter, “post”), content, materials or other data (collectively, “User Submissions”) on, to or
              through the Services. All User Submissions must comply with the Content Standards set out in these Terms
              of Service. Any User Submission you post through the Services will be considered non-confidential and
              non-proprietary. By providing any User Submission, you grant us and our affiliates and service providers,
              and each of their and our respective licensees, successors and assigns the right to use, reproduce,
              modify, perform, display, distribute and otherwise disclose to third parties any such material for any
              purpose, including, without limitation, for marketing purposes, in accordance with our{" "}
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>{" "}
              and your account settings. You represent and warrant that:
            </p>

            <ul className="lh-lg">
              <li>
                You own or control all rights in and to your User Submissions and have the right to grant the license
                granted above to us and our affiliates and service providers and each of their and our respective
                licensees, successors and assigns.
              </li>

              <li>All of your User Submissions do and will comply with these Terms of Service.</li>

              <p className="lh-lg">
                You understand and acknowledge that you are responsible for any User Submissions you submit or
                contribute, and you, not Folksmedia, have full responsibility for such content, including, without
                limitation, its legality, reliability, accuracy and appropriateness. We are not responsible or liable to
                any third party for the content or accuracy of any User Submissions posted by you or any other user of
                the Services.
              </p>
            </ul>
          </div>
          <div>
            <h5 className="fw-bold">8. Monitoring and Enforcement; Termination</h5>

            <p className="lh-lg">We have the right to:</p>

            <ul className="lh-lg">
              <li>Remove or refuse to post any User Submissions for any or no reason in our sole discretion.</li>
              <li>
                Take any action with respect to any User Submission that we deem necessary or appropriate in our sole
                discretion, including, without limitation, if we believe that such User Submission violates the Terms of
                Service, including, without limitation, the Content Standards, infringes any intellectual property right
                or other right of any person or entity, threatens the personal safety of users of the Services or the
                public or could create liability for Folksmedia.
              </li>
              <li>
                Disclose your identity or other information about you to any third party who claims that material posted
                by you violates their rights, including, without limitation, their intellectual property rights or their
                right to privacy.
              </li>
              <li>
                Take appropriate legal action, including, without limitation, referral to law enforcement, for any
                illegal or unauthorized use of the Services.
              </li>
              <li>
                Terminate or suspend your access to all or part of the Services for any violation of these Terms of
                Service.
              </li>
              <li>
                Terminate your access to all or any part of the Services at any time, with or without cause, with or
                without notice, effective immediately.
              </li>
            </ul>

            <p className="lh-lg">
              Without limiting the foregoing, we have the right to cooperate fully with any law enforcement authorities
              or court order requesting or directing us to disclose the identity or other information of anyone posting
              any materials on or through the Services. YOU WAIVE AND HOLD HARMLESS FOLKSMEDIA AND ITS AFFILIATES,
              LICENSEES AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING FROM ANY ACTION TAKEN BY ANY OF THE FOREGOING
              PARTIES DURING, OR TAKEN AS A CONSEQUENCE OF, INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
              AUTHORITIES. However, we do not undertake to review all material before it is posted to the Services and
              cannot ensure prompt removal of objectionable material after it has been posted. Accordingly, we assume no
              liability for any action or inaction regarding transmissions, communications or content provided by any
              user or third party. We have no liability or responsibility to anyone for performance or nonperformance of
              the activities described in this section.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">9. Content Standards</h5>

            <p className="lh-lg">
              These content standards apply to any and all User Submissions and use of Interactive Services. User
              Submissions must in their entirety comply with all applicable federal, state, local, and international
              laws and regulations. Without limiting the foregoing, User Submissions must not:
            </p>

            <ul className="lh-lg">
              <li>
                Contain any material that is defamatory, obscene, indecent, abusive, offensive, harassing, violent,
                hateful, inflammatory or otherwise objectionable.
              </li>
              <li>
                Promote sexually explicit or pornographic material, violence or discrimination based on race, sex,
                religion, nationality, disability, sexual orientation or age.
              </li>
              <li>
                Infringe any patent, trademark, trade secret, copyright or other intellectual property or other rights
                of any other person, or otherwise contain any content, materials, data or other information that is not
                lawfully provided to us.
              </li>
              <li>
                Violate the legal rights (including, without limitation, the rights of publicity and privacy) of others
                or contain any material that could give rise to any civil or criminal liability under applicable laws or
                regulations or that otherwise may be in conflict with these Terms of Service and our{" "}
                <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                  Privacy Policy
                </a>
                .
              </li>
              <li>Be likely to deceive any person</li>
              <li>Promote any illegal activity or advocate, promote or assist any unlawful act.</li>
              <li>
                Cause annoyance, inconvenience or needless anxiety or be likely to upset, embarrass, alarm or annoy any
                other person.
              </li>
              <li>
                Impersonate any person or misrepresent your identity or affiliation with any person or organization.
              </li>
              <li>
                Give the impression that they emanate from or are endorsed by us or any other person or entity, if this
                is not the case.
              </li>
            </ul>
          </div>
          <div>
            <h5 className="fw-bold">10. Copyright Infringement</h5>

            <p className="lh-lg">
              We take claims of copyright infringement seriously, and we will respond to notices of alleged copyright
              infringement that comply with applicable law. It is our policy in appropriate circumstances to disable
              and/or terminate the accounts of users who are repeat infringers. If you believe any materials accessible
              on or from the Services infringe your copyright, you may request removal of those materials (or access to
              them) from the Services by submitting written notification to our copyright agent designated below. To be
              valid in accordance with the Digital Millennium Copyright Act (17 U.S.C. § 512) (“DMCA”), the written
              notice (the “DMCA Notice”) must include substantially the following:
            </p>

            <ul className="lh-lg">
              <li>Your physical or electronic signature.</li>
              <li>
                Identification of the copyrighted work you believe to have been infringed or, if the claim involves
                multiple works on the Services, a representative list of such works.
              </li>
              <li>
                Identification of the material you believe to be infringing in a sufficiently precise manner to allow us
                to locate that material.
              </li>
              <li>
                Adequate information by which we can contact you (including your name, postal address, telephone number
                and, if available, email address).
              </li>
              <li>
                A statement that you have a good faith belief that use of the copyrighted material is not authorized by
                the copyright owner, its agent or the law.
              </li>
              <li>A statement that the information in the written notice is accurate.</li>
              <li>
                A statement, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.
              </li>
            </ul>

            <p className="lh-lg">
              Our designated copyright agent to receive DMCA Notices is: Copyright Agent Folksmedia Media, Inc.{" "}
              <a href="mailto:support@folksmedia.com" target="_blank" rel="noreferrer" className="primary-text-color">
                support@folksmedia.com
              </a>
              . If you fail to comply with all of the requirements of Section 512(c)(3) of the DMCA, your DMCA Notice
              may not be effective. Please be aware that if you knowingly materially misrepresent that material or
              activity on the Website is infringing your copyright, you may be held liable for damages (including,
              without limitation, costs and attorneys’ fees) under Section 512(f) of the DMCA.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">11. Reliance on Information</h5>

            <p className="lh-lg">
              The information presented on or through the Services is made available solely for entertainment and
              general information purposes. We do not warrant the accuracy, completeness or usefulness of this
              information. Any reliance you place on such information is strictly at your own risk. We disclaim all
              liability and responsibility arising from any reliance placed on such materials by you or any other user
              of the Services, or by anyone who may be informed of any of its contents. The Services include, without
              limitation, content provided by third parties, including, without limitation, materials provided by
              networks, streaming providers, communities, other third-party service providers, retailers and/or other
              users. All statements and/or opinions expressed in these materials, and all articles and responses to
              questions and other content, other than the content provided by Folksmedia, are solely the opinions and
              the responsibility of the person or entity providing those materials. These materials do not necessarily
              reflect the opinion of Folksmedia. We are not responsible, or liable to you or any third party, for the
              content or accuracy of any information, data or other materials provided (whether directly or indirectly)
              by any third parties.{" "}
            </p>
          </div>
          <div>
            <h5 className="fw-bold">12. Updates to the Website</h5>

            <p className="lh-lg">
              We may update the content on our websites from time to time, but the content is not necessarily complete
              or up-to-date. Any of the material on any website may be out of date at any given time, and we are under
              no obligation to update such material.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">13. Information About You and Your Use of the Services</h5>

            <p className="lh-lg">
              All information we collect through the Services is subject to our
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>
              . By using the Services, you consent to all actions taken by us with respect to your information in
              compliance with the
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>
              .
            </p>
          </div>
          <div>
            <h5 className="fw-bold">14. Online Purchases and Pricing</h5>

            <p className="lh-lg">
              All purchases through our site or other transactions for the sale of goods, services or information formed
              through the Services, or resulting from visits made by you, are governed by the terms applicable to the
              corresponding marketplace. You are responsible for reviewing and agreeing to such terms. If you do not
              review or do not agree with the applicable terms for online purchases, do not make any purchases through
              the Services. You acknowledge that pricing with respect to the Services, and pricing of goods, services or
              information formed through the Services, may be modified or introduced at any time.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">15. Links from the Services; Third Parties</h5>

            <p className="lh-lg">
              If the Services contain links to other sites and resources provided by third parties, these links are
              provided for your convenience only. This includes, without limitation, links contained in any third-party
              product marketplaces and in advertisements, including, without limitation, banner advertisements and
              sponsored links. We have no control over the contents of those third-party sites or resources and accept
              no responsibility for them or for any loss or damage that may arise from your use of them. If you decide
              to access any of the third-party websites linked to the Services, you do so entirely at your own risk and
              subject to the terms and conditions of use for, and privacy policies applicable to, such websites. We also
              use various third-party service providers in connection with the Services. You will comply with, and you
              acknowledge that you are subject to, all terms and conditions of use for, and privacy policies appliable
              to, all third parties whose services interoperate with, support or are otherwise accessed from the
              Services.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">16. Geographic Restrictions</h5>

            <p className="lh-lg">
              We provide the Services for use only by persons located in the United States. We make no claims that the
              Services or any of its content is accessible or appropriate outside of the United States. Access to the
              Services may not be legal by certain persons or in certain countries. You are not permitted to access the
              Services from outside the United States.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">17. Disclaimer of Warranties</h5>

            <p className="lh-lg">
              You understand that we cannot and do not guarantee or warrant that files available for downloading from
              the internet, the Website or otherwise through the Services will be free of viruses or other destructive
              code. You are responsible for implementing sufficient procedures and checkpoints to satisfy your
              particular requirements for anti-virus protection and accuracy of data input and output, and for
              maintaining a means external to our site for any reconstruction of any lost data. TO THE FULLEST EXTENT
              PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE
              ATTACK, VIRUSES OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER EQUIPMENT,
              COMPUTER PROGRAMS, DATA OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE SERVICES, THE WEBSITE OR ANY
              FUNCTIONS OR ITEMS OBTAINED THROUGH THE SERVICES OR TO YOUR DOWNLOADING OF ANY POSTED MATERIAL, OR ON ANY
              WEBSITE LINKED TO THE SERVICES. YOUR USE OF THE SERVICES, ITS CONTENT AND ANY FUNCTIONS OR ITEMS OBTAINED
              THROUGH THE SERVICES IS AT YOUR OWN RISK. THE SERVICES, THE WEBSITES, THEIR CONTENT AND ANY FUNCTIONS OR
              ITEMS OBTAINED THROUGH THE SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY
              WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER FOLKSMEDIA NOR ANY PERSON ASSOCIATED WITH
              FOLKSMEDIA MAKES ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY,
              QUALITY, ACCURACY OR AVAILABILITY OF THE SERVICES. WITHOUT LIMITING THE FOREGOING, NEITHER FOLKSMEDIA NOR
              ANYONE ASSOCIATED WITH FOLKSMEDIA REPRESENTS OR WARRANTS THAT THE SERVICES, THE WEBSITES, THEIR CONTENT OR
              ANY FUNCTIONS, INFORMATION, DATA OR OTHER ITEMS OBTAINED THROUGH THE SERVICES WILL BE ACCURATE, RELIABLE,
              ERROR-FREE OR UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR SERVICES OR THE SERVER(S) THAT
              MAKE(S) THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS OR THAT THE WEBSITES OR ANY OTHER
              FUNCTIONS, INFORMATION, DATA OR OTHER ITEMS OBTAINED THROUGH THE SERVICES WILL OTHERWISE MEET YOUR NEEDS
              OR EXPECTATIONS. TO THE FULLEST EXTENT PROVIDED BY LAW, FOLKSMEDIA HEREBY DISCLAIMS ALL WARRANTIES OF ANY
              KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF
              MERCHANTABILITY, NON-INFRINGEMENT AND FITNESS FOR PARTICULAR PURPOSE. THE FOREGOING DOES NOT AFFECT ANY
              WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">18. Limitation on Liability</h5>

            <p className="lh-lg">
              TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL FOLKSMEDIA, ITS AFFILIATES OR THEIR LICENSORS,
              SERVICE PROVIDERS, EMPLOYEES, AGENTS, OFFICERS OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY
              LEGAL THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, THE SERVICES, THE
              WEBSITES, ANY WEBSITES LINKED TO THEM, ANY CONTENT OF THE SERVICES OR SUCH OTHER WEBSITES, INCLUDING,
              WITHOUT LIMITATION, ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES,
              INCLUDING, WITHOUT LIMITATION, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE,
              LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA AND
              WHETHER CAUSED BY TORT (INCLUDING, WITHOUT LIMITATION, NEGLIGENCE), BREACH OF CONTRACT OR OTHERWISE, EVEN
              IF FORESEEABLE. THE LIMITATION OF LIABILITY SET OUT ABOVE DOES NOT APPLY TO LIABILITY RESULTING FROM OUR
              WILLFUL MISCONDUCT. THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR LIMITED UNDER
              APPLICABLE LAW.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">19. Indemnification</h5>

            <p className="lh-lg">
              You agree to defend, indemnify and hold harmless Folksmedia, its affiliates, licensors and service
              providers, and its and their respective officers, directors, employees, contractors, agents, licensors,
              suppliers, successors and assigns from and against any claims, liabilities, damages, judgments, awards,
              losses, costs, expenses or fees (including, without limitation, reasonable attorneys’ fees) arising out of
              or relating to your violation of these Terms of Service or your use of the Services, including, without
              limitation, your User Submissions, any use of the Services’ content, functions and products other than as
              expressly authorized in these Terms of Service or your use of any information obtained from the Services.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">20. Disputes</h5>

            <p className="lh-lg">
              ARBITRATION AND CLASS ACTION WAIVER — IMPORTANT — PLEASE REVIEW AS THIS AFFECTS YOUR LEGAL RIGHTS
            </p>
          </div>
          <div>
            <h5 className="fw-bold">20.1 ARBITRATION; WAIVER OF TRIAL BY JURY</h5>

            <p className="lh-lg">
              YOU AGREE THAT ALL DISPUTES BETWEEN YOU AND US OR ANY OF OUR OFFICERS, DIRECTORS OR EMPLOYEES ACTING IN
              THEIR CAPACITY AS SUCH (WHETHER OR NOT SUCH DISPUTE INVOLVES A THIRD PARTY) WITH REGARD TO YOUR
              RELATIONSHIP WITH US, INCLUDING, WITHOUT LIMITATION, DISPUTES RELATED TO THESE TERMS OF SERVICE, YOUR USE
              OF THE SERVICES AND/OR RIGHTS OF PRIVACY AND/OR PUBLICITY, WILL BE RESOLVED BY BINDING, INDIVIDUAL
              ARBITRATION UNDER THE JAMS, INC. (“JAMS”) RULES FOR ARBITRATION OF CONSUMER-RELATED DISPUTES, AND YOU AND
              WE HEREBY EXPRESSLY WAIVE TRIAL BY JURY; PROVIDED, HOWEVER, THAT WE OR YOU MAY SEEK INJUNCTIVE OR OTHER
              EQUITABLE RELIEF IN ANY STATE OR FEDERAL COURT HAVING JURISDICTION TO GRANT IT IN THE EVENT OF AN ACTUAL
              OR THREATENED INFRINGEMENT OR MISAPPROPRIATION OF INTELLECTUAL PROPERTY RIGHTS. DISCOVERY AND RIGHTS TO
              APPEAL IN ARBITRATION ARE GENERALLY MORE LIMITED THAN IN A LAWSUIT, AND OTHER RIGHTS THAT YOU AND WE WOULD
              HAVE IN COURT MAY NOT BE AVAILABLE IN ARBITRATION.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">20.2 Small Claims Court; Class Action Waiver</h5>

            <p className="lh-lg">
              As an alternative, you may bring your claim in your local “small claims” court, if permitted by that small
              claims court’s rules and if such claim is within such court’s jurisdiction, unless such action is
              transferred, removed or appealed to a different court. You may bring claims only on your own behalf.
              Neither you nor we will participate in a class action or class-wide arbitration for any claims covered by
              this agreement. YOU ARE GIVING UP YOUR RIGHT TO PARTICIPATE AS A CLASS REPRESENTATIVE OR CLASS MEMBER ON
              ANY CLASS CLAIM YOU MAY HAVE AGAINST US, INCLUDING, WITHOUT LIMITATION, ANY RIGHT TO CLASS ARBITRATION OR
              ANY CONSOLIDATION OF INDIVIDUAL ARBITRATIONS. You also agree not to participate in claims brought in a
              private attorney general or representative capacity, or consolidated claims involving another person’s
              account, if we are a party to the proceeding.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">20.3 Procedures</h5>

            <p className="lh-lg">
              These dispute resolution provisions will be governed by the Federal Arbitration Act and not by any state
              law concerning arbitration. In the event that JAMS is unwilling or unable to set a hearing date within one
              hundred and sixty (160) days of filing the case, then either we or you can elect to have the arbitration
              administered instead by the American Arbitration Association. Judgment on the award rendered by the
              arbitrator may be entered in any court having competent jurisdiction. Any provision of applicable law
              notwithstanding, the arbitrator will not have authority to award damages, remedies or awards that conflict
              with these Terms of Service. You agree that regardless of any statute or law to the contrary, any claim or
              cause of action arising out of, related to or connected with the use of the Services or these Terms of
              Service must be filed within one (1) year after such claim or cause of action arose or be forever banned.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">20.4 30-Day Opt-Out Period</h5>

            <p className="lh-lg">
              If you do not wish to be bound by the arbitration and class-action waiver provisions in this Disputes
              section, you must notify us in writing within thirty (30) days of the date that you first accept these
              Terms of Service (unless a longer period is required by applicable law), and then you must litigate any
              disputes against us in accordance with the Governing Law and Jurisdiction section below. Your written
              notification must be mailed to us at the address set out at the end of these Terms of Service. If you do
              not notify us in accordance with this paragraph, you agree to be bound by the terms of this Disputes
              section, including, without limitation, the arbitration and class-action waiver provisions, and further
              including, without limitation, such provisions in any revisions we make to these Terms of Service after
              the date of your first acceptance. Such notification must include: (i) your name; (ii) your email address
              and mailing address; and (iii) a statement that you do not wish to resolve disputes with us through
              arbitration or waive your ability to participate in a class action. If we make any changes to this
              Disputes section (other than a change to the address at which we will receive notices or rejections of
              future changes to this Disputes section), you may reject any such change by sending us written notice,
              within thirty (30) days of the change, to the address set out at the end of these Terms of Service. It is
              not necessary to send us a rejection of a future change to this Disputes section if you had properly opted
              out within the first thirty (30) days after you first accepted the provisions in this Disputes section. If
              you have not properly opted out, then by rejecting a future change, you are agreeing that you will
              arbitrate any dispute between us in accordance with the language of this Disputes section, as modified by
              any changes you did not reject. A notification sent pursuant to this paragraph solely affects these Terms
              of Service; if you previously entered into other arbitration or dispute resolution agreements with us or
              enter into other such agreements in the future, your notification that you are opting out of the
              provisions in this Disputes section shall not affect the other arbitration agreements between you and us.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">20.5 Severability</h5>

            <p className="lh-lg">
              If any of the prohibitions against class actions and other claims brought on behalf of third parties
              contained above is found to be unenforceable, then all of the preceding language in this Disputes section
              will be null and void. This Disputes section, including, without limitation, the arbitration agreement and
              class action waivers contained herein, will survive the termination of your relationship with us.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">21. Governing Law and Jurisdiction</h5>

            <p className="lh-lg">
              All matters relating to the Services and these Terms of Service, and any dispute or claim arising
              therefrom or related thereto (in each case, including, without limitation, non-contractual disputes or
              claims), shall be governed by and construed in accordance with the internal laws of the State of New York
              without giving effect to any choice or conflict of law provision or rule (whether of the State of New York
              or any other jurisdiction). You agree that the Services and all websites provided by or on behalf of
              Folksmedia do not give rise to personal jurisdiction over Folksmedia, either specific or general, in any
              jurisdiction other than the State of New York. Subject to Section 20, any legal suit, action or proceeding
              arising out of or related to these Terms of Service or the Services shall be instituted exclusively in the
              federal courts of the United States or the courts of the State of New York, in each case located in New
              York City. You waive any and all objections to the exercise of jurisdiction over you by such courts and to
              venue in such courts.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">22. Waiver and Severability</h5>

            <p className="lh-lg">
              No waiver by Folksmedia of any term or condition set out in these Terms of Service shall be deemed a
              further or continuing waiver of such term or condition or a waiver of any other term or condition, and any
              failure of Folksmedia to assert a right or provision under these Terms of Service shall not constitute a
              waiver of such right or provision. With the except of the provisions of the Disputes section as
              contemplated in Section 20.5, if any provision of these Terms of Service is held by a court or other
              tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision
              shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of
              Service will continue in full force and effect.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">23. Entire Agreement</h5>

            <p className="lh-lg">
              The Terms of Service and our{" "}
              <a href="/privacy-policy" target="_blank" rel="noreferrer" className="primary-text-color">
                Privacy Policy
              </a>{" "}
              constitute the sole and entire agreement between you and Folksmedia Media, Inc. regarding the Services and
              supersede all prior and contemporaneous understandings, agreements, representations and warranties, both
              written and oral, regarding the Services.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">24. Your Comments and Concerns</h5>

            <p className="lh-lg">
              The Services are operated by Folksmedia Media, Inc. All notices of copyright infringement claims should be
              sent to the copyright agent designated in these Terms of Service in the manner and by the means set out
              therein. All other feedback, comments, requests for technical support and other communications relating to
              the Services should be directed to: :{" "}
              <a href="mailto:support@folksmedia.com" target="_blank" rel="noreferrer" className="primary-text-color">
                support@folksmedia.com
              </a>
              .
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">Folksmedia Marketplace</h5>
            <p className="lh-lg">
              READ THESE TERMS CAREFULLY BEFORE YOU START TO USE OR MAKE ANY PURCHASES THROUGH THE Folksmedia
              MARKETPLACE.
            </p>
            <p className="lh-lg">
              The Services also provide access to an online third-party product marketplace (the “Folksmedia
              Marketplace”). The Terms of Service also govern your use of and any purchases that you make through the
              Folksmedia Marketplace.
            </p>
            <p className="lh-lg">
              Folksmedia provides the Folksmedia Marketplace to allow you to review offers and to purchase products and
              services (“Products”) directly from participating third-party retailers (“Retailers”). When you make a
              purchase through the Folksmedia Marketplace, you are purchasing the Product from a Retailer and not from
              Folksmedia. In selecting a Product for purchase, you may be directed to a third-party website of the
              Retailer and will be subject to the terms of use for the Retailer’s website. We are not the merchant of
              record, and the actual contract for sale is directly between you and the Retailer. When you submit an
              order for a Product through the Folksmedia Marketplace, you are entering into a binding agreement to
              purchase the Product directly from the Retailer through our third-party payment processors. You agree that
              Folksmedia is not an agent for you or for any Retailer and has no authority to act on your or on a
              Retailer’s behalf.
            </p>{" "}
            <p className="lh-lg">
              We are not responsible for examining or evaluating, and we do not warrant, any Product offerings of any
              Retailers. Folksmedia does not assume any responsibility or liability for the actions, product and content
              of all these Retailers and any other third parties. You should carefully review their privacy statements,
              selling policies and other conditions of use.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">1.1 Marketing communications</h5>
            <p className="lh-lg">
              By establishing an account with Folksmedia or by making a purchase through the Folksmedia Marketplace, you
              grant us permission to contact you at your email address and phone number and send you marketing content
              related to the Folksmedia Marketplace, our affiliates and our business partners. You can opt-out of
              receiving our marketing content following the opt-out procedures set forth in such marketing content.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">1.2 Marketplace use</h5>
            <p className="lh-lg">
              Folksmedia may exclude any buyer, including, without limitation, you, from making purchases in the
              Folksmedia Marketplace at any time and for any reason. Resellers may not purchase Products in the
              Folksmedia Marketplace and we reserve the right, in our sole discretion, to cancel such purchase.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">1.3 Folksmedia rewards</h5>
            <p className="lh-lg">
              As a user, you may be rewarded for specific actions that you perform. You may choose to redeem through one
              of the methods as provided by Folksmedia from time to time. Folksmedia reserves the right to change the
              nature of awards, the quantity of awards, the redemption methods, etc. at its sole discretion without any
              notice, including, without limitation, completely stopping rewarding a particular individual or group or
              all users.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">1.4 Product information</h5>
            <p className="lh-lg">
              Any information about and descriptions of Products for purchase from Retailers through the Folksmedia
              Marketplace (“Product Information”) may be based on information provided to us by Retailers. We do not
              warrant the accuracy, completeness or usefulness of this information. There may be information accessible
              through the Folksmedia Marketplace that contains typographical errors, inaccuracies, omissions and other
              types of errors, including, without limitation, errors that relate to descriptions of Products, pricing,
              promotions, offers, minimum order amounts, delivery times and Product availability. Any reliance you place
              on such information is strictly at your own risk. We disclaim all liability and responsibility arising
              from any reliance placed on such materials by you or any other user of the Folksmedia Platform, or by
              anyone who may be informed of any of its contents.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">1.5 Purchase processing</h5>
            <p className="lh-lg">
              When you submit an order for Product through the Folksmedia Marketplace, you are entering into a binding
              agreement to purchase the Product directly from the Retailer.
            </p>
          </div>
          <div>
            <h5 className="fw-bold">1.6 Delivery</h5>
            <p className="lh-lg">
              Delivery of any Products you purchase through the Folksmedia Marketplace, if applicable, is made by the
              Retailer seller and not Folksmedia. You agree that we have no obligation to deliver any Product you
              purchase through the Folksmedia Marketplace. You agree that we may provide to the Retailer your street
              address, email address, name and phone number for supplying to you the Product you purchase from the
              Retailer through the Folksmedia Marketplace.
            </p>
            <p className="lh-lg">
              ANY CLAIMS RELATED TO THE DELIVERY OF A PRODUCT, INCLUDING, WITHOUT LIMITATION, CLAIMS THAT THE PRODUCT
              WAS NOT DELIVERED, CLAIMS OF DAMAGE TO PROPERTY OR CLAIMS RELATED TO PRODUCT QUALITY OR SUITABILITY ISSUES
              MUST BE HANDLED SOLELY BETWEEN YOU AND THE APPLICABLE RETAILER. YOU ARE RESPONSIBLE FOR MAKING ANY CLAIMS
              DIRECTLY WITH THE RETAILER AND AGREE THAT YOU WILL NOT SEEK TO HOLD FOLKSMEDIA RESPONSIBLE FOR ANY OF THE
              FOREGOING CLAIMS.
            </p>
          </div>{" "}
          <div>
            <h5 className="fw-bold">1.7 Changing or cancelling marketplace orders</h5>
            <p className="lh-lg">
              Folksmedia is not responsible for the fulfillment, modification, cancelling or return of any orders placed
              with a Retailer through the Folksmedia Marketplace. Once you have placed an order for a product in the
              Folksmedia Marketplace, the Retailer will handle all aspects of the order fulfillment, including, without
              limitation, changes to orders (if available) and processing of returned orders. Folksmedia will not change
              or cancel any order made by you, including, without limitation, any change to delivery address or billing
              information. You will need to contact the Retailer for any changes or cancellations that you wish to make
              to your order or to process any returns. The Retailer’s contact information may be found in the email sent
              to you confirming your order.
            </p>
            <p className="lh-lg">
              For customer service for orders placed through Folksmedia partners, you can email us at{" "}
              <a
                href="https://www.orders@folksmedia.com/"
                target="_blank"
                rel="noreferrer"
                className="primary-text-color"
              >
                orders@folksmedia.com
              </a>{" "}
              or by using the facility available in our mobile applications which will be handled as per our partners’
              stated policy about returns, warranty claims, cancellations, repairs, replacements, etc.
            </p>{" "}
            <p className="lh-lg">
              In the event of order cancelation or refunds, Folksmedia will retain the processing fee and shipping fee
              where applicable. Currently the processing fee is 1.6% + 30 Cents of the order value. The shipping prices
              vary depending on the source and destination. Folksmedia reserves the right to change the processing fee
              at any point in time without any prior notice.
            </p>
          </div>
          <div className={styles.primaryColor}>Folksmedia © {currentYear}</div>
        </div>
      </div>
    </div>
  );
}
