import { URLS } from "../routes/routeURL";

export const REDIRECTION_ROUTES = {
  HEADER_LOGOUT: URLS.CONTENT_HOME,
  ACC_SIDER_LOGOUT: URLS.CONTENT_HOME,
  REFRESH_FAIL_LOGOUT: URLS.CONTENT_HOME,
  PASS_RESET_LOGOUT: URLS.LOGIN,
};

export const APP_LINKS = {
  ANDROID_STORE: "https://play.google.com/store/apps/details?id=com.folksmediaapp",
  APPLE_STORE: "https://apps.apple.com/app/folksmedia/id1579558800",

  FACEBOOK_PAGE: "https://www.facebook.com/profile.php?id=100085051778413",
  TWITTER_PAGE: "https://twitter.com/Folksmedia_com",
  INSTAGRAM_PAGE: "https://www.instagram.com/folksmedia_community/",
};
