import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { Col, Empty } from "antd";
import { SliderContainer } from "../../../content-common/components/media-row/styledComponent";
import PlaylistCard from "../playlist-card/PlaylistCard";
import playlistCradStyles from "../playlist-card/playlistCard.module.css";
import PropTypes from "prop-types";
import { observer } from "mobx-react-lite";

import { AiOutlineLeftCircle, AiOutlineRightCircle } from "react-icons/ai";
import Slider from "react-slick";
import useWindowSize from "../../../../components/use-window-size-hook/useWindowSize";
import PlaylistExpand from "../../../details/components/../../manage-library/PlaylistExpand";
import { FaArrowRight } from "react-icons/fa";
import withPlaylistHelper from "../../../../hocs/withPlaylistHelper";
import { safeArray } from "../../../../utils/helper";
import FolksEmptyMessage from "../../../../components/folks-empty-message/FolksEmptyMessage";

const PrevArrow = ({ onClick, className }) => (
  <button
    className={` ${playlistCradStyles.mediaButtonLeft} arrowButton ${
      className?.includes("slick-disabled") ? "disable-arrow-button" : ""
    }`}
    onClick={onClick}
  >
    <AiOutlineLeftCircle size={45} />
  </button>
);

const NextArrow = ({ onClick, className }) => (
  <button
    className={` ${playlistCradStyles.mediaButtonRight} arrowButton ${
      className?.includes("slick-disabled") ? "disable-arrow-button" : ""
    }`}
    onClick={onClick}
  >
    <AiOutlineRightCircle size={45} />
  </button>
);

const PlaylistSlider = ({
  playlistData,
  title,
  fetchPLaylistdata,
  propStyles,
  type = "public",
  isNewPlayList = false,
  onChangeExpandType,
  expandView,
  showExpandViewFeature,
  onShare,
  onCreatePlaylist,
  onEdit,
  onDeletePlaylist,
}) => {
  const mediaContainerRef = useRef(null);
  const parentDivisionWidth = useWindowSize(mediaContainerRef);

  const sliderRef = useRef(null);
  const [sliderSettings, setSiderSettings] = useState({
    dots: false,
    infinite: false,
    slidesToShow: Math.floor((mediaContainerRef?.current?.offsetWidth || window?.innerWidth) / 250) || 3,
    slidesToScroll: Math.floor((mediaContainerRef?.current?.offsetWidth || window?.innerWidth) / 250) || 3,
    swipeToSlide: true,
    speed: 500,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
  });

  useLayoutEffect(() => {
    setSiderSettings((prevState) => {
      const cardsOnScreen = Math.floor(mediaContainerRef?.current?.offsetWidth / 250) || 3;
      // console.log({ offsetWidth: mediaContainerRef?.current?.offsetWidth });
      // console.log(cardsOnScreen, prevState);
      return {
        ...prevState,
        slidesToShow: cardsOnScreen,
        slidesToScroll: cardsOnScreen,
      };
    });
  }, [parentDivisionWidth, mediaContainerRef]);

  const handleNewPlaylist = (e) => {
    e.preventDefault();
    onCreatePlaylist();
  };

  const viewExpand = useMemo(() => {
    return expandView;
  }, [expandView]);

  if (showExpandViewFeature) {
    if (viewExpand && viewExpand !== type) {
      return null;
    }
    if (viewExpand === type) {
      return (
        <PlaylistExpand
          list={playlistData}
          title={title}
          type={type}
          onShare={onShare}
          handleClose={() => {
            onChangeExpandType("");
          }}
          onEdit={onEdit}
          onDeletePlaylist={onDeletePlaylist}
        />
      );
    }
  }

  return (
    <div style={propStyles?.container}>
      <Col span={24}>
        <div className={playlistCradStyles.header}>
          {(playlistData?.length > 0 || isNewPlayList) && (
            <div className={playlistCradStyles.leftHeadingSection}>
              <h3 className={playlistCradStyles.watchlistHeading}>{title}</h3>
              {playlistData?.length > 4 && showExpandViewFeature ? (
                <button
                  className={playlistCradStyles.moreButton}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onChangeExpandType(type);
                  }}
                >
                  More
                  <FaArrowRight className={playlistCradStyles.moreIcon} />
                </button>
              ) : null}
            </div>
          )}
          {isNewPlayList ? (
            <button className={playlistCradStyles.newPlaylistButton} onClick={handleNewPlaylist}>
              + New Playlist
            </button>
          ) : null}
        </div>
        <div
          ref={mediaContainerRef}
          style={{
            width: "100%",
          }}
        >
          {!playlistData.length ? (
            <div
              style={{
                padding: "30px 0",
              }}
            >
              <Empty description={false} />
              <FolksEmptyMessage />
            </div>
          ) : (
            <SliderContainer overflowVisible={false}>
              <Slider ref={sliderRef} {...sliderSettings}>
                {safeArray(playlistData)
                  ?.filter((item) => item?.author_name?.length)
                  .map((data) => {
                    return (
                      <PlaylistCard
                        key={data.watchlist_id}
                        playlistItemData={data}
                        onShare={onShare}
                        fetchPLaylistdata={fetchPLaylistdata}
                        title={title}
                        type={type}
                        onEdit={onEdit}
                        onDeletePlaylist={onDeletePlaylist}
                      />
                    );
                  })}
              </Slider>
            </SliderContainer>
          )}
        </div>
      </Col>
    </div>
  );
};

PlaylistSlider.propTypes = {
  playlistData: PropTypes.array,
  title: PropTypes.string,
  isNewPlayList: PropTypes.bool,
  showExpandViewFeature: PropTypes.bool,
};

PlaylistSlider.defaultProps = {
  playlistData: [],
  title: "Playlists",
  isNewPlayList: false,
  showExpandViewFeature: false,
};

export default observer(withPlaylistHelper(PlaylistSlider));
