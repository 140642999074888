export const movies = [
  {
    action: { action_id: "https://www.netflix.com/us/title/80991090", action_type: "VIEW_DEEPLINK" },
    image_l: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/TMDB/image_repo/movies/tt13452446/backdrop.webp",
    thumbnail:
      "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt13452446_secondary_thumbnail_movie.webp",
    intended_application: "web",
    title: "Damsel",
    subtitle: "3.5 ⭐ · 2024 · 1hr 50min",
    description:
      "A young woman's marriage to a charming prince turns into a fierce fight for survival when she's offered up as a sacrifice to a fire-breathing dragon.",
    item_type: "ott",
    item_id: "tt13452446",
    banner_web_m: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt13452446_secondary_movie.webp",
    meta: {
      year: "2024",
      genres: [
        {
          id: 14,
          name: "Fantasy",
        },
        {
          id: 28,
          name: "Action",
        },
      ],
    },
  },
  {
    action: {
      action_id: "https://www.hulu.com/watch/29809252-8c07-4162-95c4-289b95ba6f7b",
      action_type: "VIEW_DEEPLINK",
    },
    image_l: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/TMDB/image_repo/movies/tt31113004/backdrop.webp",
    thumbnail:
      "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt31113004_secondary_thumbnail_movie.webp",
    intended_application: "web",
    title: "Sacred Soil: The Piney Woods School Story",
    subtitle: "1.0 ⭐ · 2024 · 1hr 45min",
    description:
      "A documentary film set against the culturally historical backdrop of one of America's oldest Black boarding schools. The film provides a window into the ever-evolving, complex layers of the school and its students.",
    item_type: "ott",
    item_id: "tt31113004",
    banner_web_m: "https://s3.amazonaws.com/fm-cdn.folksmedia.com/banners/content/tt31113004_secondary_movie.webp",
    meta: {
      year: "2024",
      genres: [
        {
          id: 99,
          name: "Documentary",
        },
      ],
    },
  },
];
