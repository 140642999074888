import React, { useEffect, useMemo, useState } from "react";
import styles from "./ManageLibrary.module.css";
import { useMst } from "../../store";
import PlaylistSlider from "../search/component/playlist-slider/PlaylistSlider";
import FolksLoader from "../../components/folks-loader/FolksLoader";
import { observer } from "mobx-react-lite";
import ContentYouLike from "./ContentYouLike";
// import ContentYouWatched from "./ContentYouWatched";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { URLS } from "../../routes/routeURL";

const TABS = {
  MANAGE_PLAYLIST: {
    id: 1,
    name: "manage-playlist",
    title: "Playlist",
    route: URLS.MANAGE_PLAYLIST,
  },
  CONTENT_YOU_LIKE: {
    id: 2,
    name: "content-you-like",
    title: "Content You Like",
    route: URLS.MANAGE_LIKED_CONTENT,
  },
  // ALREADY_WATHCED: {
  //   id: 3,
  //   name: "already-watched",
  //   title: "Already Watched Content"
  // }
};

function ManageLibrary(props) {
  const location = useLocation();
  const history = useHistory();
  const { tab } = useParams();
  const [expandViewType, setExpandViewType] = useState("");
  const { playlistStore } = useMst();

  const onChangeExpandType = (type) => {
    setExpandViewType(type);
  };

  const activeTab = useMemo(() => {
    const isMathced = Object.values(TABS).some((item) => item.name === tab);
    return isMathced ? tab : TABS.MANAGE_PLAYLIST.name;
  }, [location]);

  const onChangeTab = (tab) => {
    if (tab?.route) {
      history.push(tab?.route);
    }
  };

  return (
    <div className={styles.libraryPage}>
      <main className={styles.mainContent}>
        <div
          className={styles.tabContainer}
          style={{
            visibility: expandViewType ? "hidden" : "visible",
          }}
        >
          {Object.values(TABS).map((tab) => {
            return (
              <button
                className={`${styles.tabButton} ${activeTab === tab.name ? styles.activeTab : ""}`}
                onClick={() => onChangeTab(tab)}
              >
                {tab.title}
              </button>
            );
          })}
        </div>
        {activeTab === TABS.MANAGE_PLAYLIST.name &&
          (playlistStore?.loadingStatus ? (
            <div className={styles.loadingWrapper}>{playlistStore?.loadingStatus ? <FolksLoader /> : null}</div>
          ) : (
            <>
              {playlistStore?.getSubscribedPlaylist?.length ? (
                <PlaylistSlider
                  type="public"
                  playlistData={playlistStore?.getSubscribedPlaylist}
                  title="Subscribed Playlists"
                  propStyles={{
                    container: {
                      marginTop: "30px",
                    },
                  }}
                  showExpandViewFeature={true}
                  expandView={expandViewType}
                  onChangeExpandType={onChangeExpandType}
                />
              ) : null}
              <PlaylistSlider
                type="normal"
                playlistData={playlistStore?.listOfPlaylists}
                title="My Playlist"
                isNewPlayList={true}
                showExpandViewFeature={true}
                expandView={expandViewType}
                onChangeExpandType={onChangeExpandType}
                propStyles={{
                  container: {
                    marginTop: "30px",
                  },
                }}
              />
            </>
          ))}
        {activeTab === TABS.CONTENT_YOU_LIKE.name && (
          <div className={styles.contentYouLike}>
            <ContentYouLike />
          </div>
        )}
        {/* {activeTab === "watched" && (
          <div className={styles.contentYouLike}>
            <ContentYouWatched />
          </div>
        )} */}
      </main>
    </div>
  );
}
ManageLibrary.propTypes = {};

export default observer(ManageLibrary);
