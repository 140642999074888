const _SETTINGS = {
  THUMB_IMG_SIZE: "h",
  POSTER_IMG_SIZE: "h",
  MEDIA_CARD_SCALE_FACT: 1.4,
  MEDIA_CARD_SIZE: { width: 128, height: 192 },
  GENRE_CARD_SIZE: { width: 150, height: 100 },
  SLING_CARD_SIZE: { width: 250, height: 141 },
  SLING_CROSS_CATEGORY: ["news", "sports"],
  SLING_ROW_POSITION: 4,
};

const _DEFAULT_SETTINGS = {
  THUMB_IMG_SIZE: "h",
  POSTER_IMG_SIZE: "h",
  MEDIA_CARD_SCALE_FACT: 1.4,
  MEDIA_CARD_SIZE: { width: 128, height: 192 },
  GENRE_CARD_SIZE: { width: 150, height: 100 },
  SLING_CARD_SIZE: { width: 200, height: 113 },
  SLING_CROSS_CATEGORY: ["news", "sports"],
  SLING_ROW_POSITION: 4,
};

const _thumbAssetKeys = ["h", "m", "l", "o"];
const _isValidThumbAssetKey = (image_key_str) => {
  if (_thumbAssetKeys.includes(image_key_str)) return true;
  return false;
};

const _posterAssetKeys = ["h", "m", "o"];
const _isValidPosterAssetKey = (image_key_str) => {
  if (_posterAssetKeys.includes(image_key_str)) return true;
  return false;
};

const _isValidMCScaleFactor = (factor_float) => {
  if (typeof factor_float === "number" && factor_float >= 1 && factor_float <= 2) return true;
  return false;
};

const _isValidMediaCardSize = (size_obj) => {
  if (size_obj?.height && size_obj?.width && typeof size_obj.height === "number" && typeof size_obj.width === "number")
    return true;
  return false;
};

const _slingCategoryKeys = ["news", "sports", "movie", "show"];
const _isValidSlingCategoryArr = (str_arr) => {
  if (str_arr?.length === 0) return true;

  let flag = true;
  str_arr?.forEach((str) => {
    if (!_slingCategoryKeys.includes(str)) flag = false;
  });
  return flag;
};

export const CONTENT_SETTINGS = {
  THUMB_IMG_SIZE: _isValidThumbAssetKey(_SETTINGS.THUMB_IMG_SIZE)
    ? _SETTINGS.THUMB_IMG_SIZE
    : _DEFAULT_SETTINGS.POSTER_IMG_SIZE,
  POSTER_IMG_SIZE: _isValidPosterAssetKey(_SETTINGS.POSTER_IMG_SIZE)
    ? _SETTINGS.POSTER_IMG_SIZE
    : _DEFAULT_SETTINGS.POSTER_IMG_SIZE,
  MEDIA_CARD_SCALE_FACT: _isValidMCScaleFactor(_SETTINGS.MEDIA_CARD_SCALE_FACT)
    ? _SETTINGS.MEDIA_CARD_SCALE_FACT
    : _DEFAULT_SETTINGS.MEDIA_CARD_SCALE_FACT,
  MEDIA_CARD_SIZE: _isValidMediaCardSize(_SETTINGS.MEDIA_CARD_SIZE)
    ? _SETTINGS.MEDIA_CARD_SIZE
    : _DEFAULT_SETTINGS.MEDIA_CARD_SIZE,
  GENRE_CARD_SIZE: _isValidMediaCardSize(_SETTINGS.GENRE_CARD_SIZE)
    ? _SETTINGS.GENRE_CARD_SIZE
    : _DEFAULT_SETTINGS.GENRE_CARD_SIZE,
  SLING_CARD_SIZE: _isValidMediaCardSize(_SETTINGS.SLING_CARD_SIZE)
    ? _SETTINGS.SLING_CARD_SIZE
    : _DEFAULT_SETTINGS.SLING_CARD_SIZE,
  SLING_CROSS_CATEGORY: _isValidSlingCategoryArr(_SETTINGS.SLING_CROSS_CATEGORY)
    ? _SETTINGS.SLING_CROSS_CATEGORY
    : _DEFAULT_SETTINGS.SLING_CROSS_CATEGORY,
  SLING_ROW_POSITION:
    typeof _SETTINGS.SLING_ROW_POSITION === "number"
      ? _SETTINGS.SLING_ROW_POSITION
      : _DEFAULT_SETTINGS.SLING_ROW_POSITION,
};
