import { Modal } from "antd";
import { IoMdCloseCircleOutline } from "react-icons/io";
import styled from "styled-components";

const FolksModal = styled(Modal).attrs(({ width, centered, zIndex }) => ({
  footer: null,
  width: width || "40vw",
  centered: true,
  zIndex: zIndex || 1000,
  closeIcon: <IoMdCloseCircleOutline style={{ color: "white", fontSize: "2vw" }} />,
}))`
  &&& {
    .ant-modal-body {
      padding: 2vw;
    }
    .ant-modal-content {
      background-color: #1a1a1c;
      border-radius: 1vw;
      margin-top: 2.5vw;
    }

    // .ant-modal-close-x {
    //   height: 1.3vw;
    //   width: 1.3vw;
    //   ${"" /* line-height: 3vw; */}
    //   font-size: 1vw;
    //   color: white;
    //   border-radius: 50%; /* Add circular border */
    //   border: 1px solid white; /* Optional: Add border style */
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   margin: 10px;
    // }
  }
`;

export default FolksModal;
