import React from "react";
import { useMst } from "../../store";
import { useHistory } from "react-router-dom";
import UnAuthorizedImage from "../../assets/images/Unauthorized.svg";
import { USER_TYPE } from "../../store/auth_store/models/authModel";
import { authRoutes, contentRoutes } from "../../routes/routes";
import styles from "./pageNotAuthorized.module.css";

const PageNotAuthorized = () => {
  const { auth, setAuthActiveTabIndex, setLoginModalVisible } = useMst();
  const history = useHistory();

  const handleSignup = () => {
    setAuthActiveTabIndex("register"); // set signUp active tab
    setLoginModalVisible(true); // open auth modal
  };

  return (
    <div className={styles.container}>
      <div className={styles.imageContainer}>
        {" "}
        <img src={UnAuthorizedImage} alt="" className={styles.unAuthorizedImage} />
      </div>

      {auth.folksUserType === USER_TYPE.NONE ? <h2> You are not authorized to access this page.</h2> : null}
      {auth.folksUserType === USER_TYPE.GUEST ? <h2>You are not authorized to access this page.</h2> : null}
      {auth.folksUserType === USER_TYPE.KID ? <h2>You are not authorized to access this page.</h2> : null}
      {auth.folksUserType === USER_TYPE.NORMAL ? <h2>You are already logged in.</h2> : null}

      {auth.folksUserType === USER_TYPE.NONE ? (
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              setLoginModalVisible(true);
            }}
            className={styles.button}
          >
            Login
          </button>
          <button onClick={handleSignup} className={styles.button}>
            Sign Up
          </button>
        </div>
      ) : null}
      {auth.folksUserType === USER_TYPE.GUEST ? (
        <div className={styles.buttonContainer}>
          <button
            onClick={() => {
              setLoginModalVisible(true);
            }}
            className={styles.button}
          >
            Login
          </button>
          <button onClick={handleSignup} className={styles.button}>
            Sign Up
          </button>
          <button onClick={() => history.push(contentRoutes.content_home.path)} className={styles.button}>
            Back to Homepage
          </button>
        </div>
      ) : null}
      {auth.folksUserType === USER_TYPE.KID ? (
        <button to={contentRoutes.content_home.path} className={styles.button}>
          Back to Homepage
        </button>
      ) : null}
      {auth.folksUserType === USER_TYPE.NORMAL ? (
        <button onClick={() => history.push(contentRoutes.content_home.path)} className={styles.button}>
          Back to Homepage
        </button>
      ) : null}
    </div>
  );
};

export default PageNotAuthorized;
