import { types } from "mobx-state-tree";

export const BackgroundImageStore = types
  .model({
    img: types.string,
  })
  .actions((self) => ({
    setBackgroundImage(backgroundImageurl) {
      self.img = backgroundImageurl
        ? backgroundImageurl
        : "https://sensara-static-files.sensara.co/pre-sized/images/1383x778/-7264802434459105556/https/sensara-static-files.sensara.tv/media/images/8967101969715478426/https/image.tmdb.org/t/p/original//xDnFlNrNUoSKPq4uptnhYmUZNpm.jpg";
    },
  }))
  .views((self) => ({
    get getBackgroundImage() {
      return self.img;
    },
  }));
