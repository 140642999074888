import jwt_decode from "jwt-decode";
import moment from "moment-timezone";
import { store as RootStore } from "../store";
import filter from "lodash.filter";
import forEach from "lodash.foreach";
import findIndex from "lodash.findindex";
import { LS_CACHE_KEY_FOLKS_HOME_FEED } from "../api/local-cache/lsCacheKeys";
import { lsReadObj, lsWriteObj } from "../api/local-cache/localCache";

export const flattenObject = (obj) => {
  const retunedObj = {};

  for (const i in obj) {
    if (!Object.prototype.hasOwnProperty.call(obj, i)) {
      continue;
    }
    if (typeof obj[i] === "object") {
      const flatObject = flattenObject(obj[i]);
      for (const x in flatObject) {
        if (!Object.prototype.hasOwnProperty.call(flatObject, x)) {
          continue;
        }
        retunedObj[`${i}.${x}`] = flatObject[x];
      }
    } else {
      retunedObj[i] = obj[i];
    }
  }
  return retunedObj;
};

const passwordRegex = /^(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,25}$/;
export const validatePassword = (password) => {
  if (!password) return false;
  if (!passwordRegex.test(password)) return false;
  return true;
};

const emailRegex =
  // eslint-disable-next-line
  /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/;

export const validateEmail = (email) => {
  if (!emailRegex.test(email)) return false;
  return true;
};

export const getPasswordMessage = (fieldName) => {
  return `${fieldName} length should be more than 8 characters and it should atleast include one lowercase letter and one digit!`;
};

export const jwtDecode = (token) => {
  try {
    const decodedToken = jwt_decode(token);
    return {
      success: true,
      data: decodedToken,
    };
  } catch (error) {
    return {
      success: false,
      message: "Decoding failed, Bad token!",
    };
  }
};

export const isJWTexpired = (token) => {
  const decodedToken = jwt_decode(token);

  const dateNow = new Date();
  if (decodedToken?.exp < dateNow.getTime()) {
    return true;
  } else {
    return false;
  }
};

// helper function: generate a new file from base64 String
export const dataURLtoFile = (dataurl, filename) => {
  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n) {
    u8arr[n - 1] = bstr.charCodeAt(n - 1);
    n -= 1; // to make eslint happy
  }
  return new File([u8arr], filename, { type: mime });
};

export const processUTCtoEST = (s, defaultFormat = "DD MMM YYYY") => {
  if (s) {
    if (typeof s !== "string") {
      return moment().tz("America/New_York").format(defaultFormat);
    }
    return moment(s).tz("America/New_York").format(defaultFormat);
  }
  return moment().tz("America/New_York").format(defaultFormat);
};

export const validatePasswordText = (password) => {
  if (!passwordRegex.test(password)) {
    return {
      status: false,
      message:
        "Password length should be more than 8 characters and it should atleast include one lowercase letter and one digit!",
    };
  } else {
    return { status: true };
  }
};

export const isValidRoute = (routeList, pathName) => {
  let isValid = false;
  if (routeList.some((route) => route.path === pathName)) {
    isValid = true;
  }
  return isValid;
};

export const isImdbId = (idStr) => {
  const regx_imdb_id = /ev\d{7}\/\d{4}(-\d)?|(ch|co|ev|nm|tt)\d{7}/;

  if (!idStr || typeof idStr !== "string") {
    return false;
  } else if (regx_imdb_id.test(idStr)) {
    return true;
  } else {
    return false;
  }
};

export const playlistShareUrl = (playlistId) => {
  return `${window.location.protocol + "//" + window.location.host}/content/playlist/${playlistId}`;
};

export const dateXyearsAgo = (numOfYears, date = new Date()) => {
  date.setFullYear(date.getFullYear() - numOfYears);
  return date;
};

export const analyticsUrl = "/events/web/analytics";

export const deProxyJSONData = (data) => JSON.parse(JSON.stringify(data));

export const getLocalDateTimeStr = () => {
  // Returns local date-time string in ISO8601 format.
  // Ex: "2014-01-01T23:28:56.782"
  const date = new Date();

  function pad(number) {
    var r = String(number);
    if (r.length === 1) r = "0" + r;
    return r;
  }

  return (
    date.getFullYear() +
    "-" +
    pad(date.getMonth() + 1) +
    "-" +
    pad(date.getDate()) +
    "T" +
    pad(date.getHours()) +
    ":" +
    pad(date.getMinutes()) +
    ":" +
    pad(date.getSeconds()) +
    "." +
    String((date.getMilliseconds() / 1000).toFixed(3)).slice(2, 5)
  );
};

export const checkAgent = () => {
  const agent_str = window.navigator.userAgent.toLowerCase();
  if (agent_str?.indexOf("iphone") !== -1) return "iphone";
  if (agent_str?.indexOf("android") !== -1) return "android";
  if (agent_str?.indexOf("ipad") !== -1) return "ipad";
  else return "default"; // Web.
};

export const getActionsData = (actions, deviceType = "E") => {
  try {
    const actionList = actions?.action_list?.length ? actions?.action_list : [];
    const contentIdLinkObj = actions?.content_ids
      ? JSON.parse(
          typeof actions?.content_ids === "string" ? actions?.content_ids : JSON.stringify(actions?.content_ids || {})
        )
      : {}; // -> actions.content_ids are stringified.
    if (!(actionList?.length && Object.keys(contentIdLinkObj)?.length)) return [];

    const availableDeviceActions = actionList?.filter((actionStr) => actionStr?.split(".")?.[1] === deviceType);
    if (!availableDeviceActions?.length) return [];

    // Deeplink generation
    const actionsDataArr = [];
    const ottAppsData = RootStore.contentSettingsStore.allOttApps;
    const deeplinkMetaData = JSON.parse(RootStore.feedsStore.deeplinkMeta);

    availableDeviceActions?.forEach((actionStr) => {
      const ottId = actionStr?.split(".")?.[0];
      const contentId = actionStr?.split(".")?.[2];
      const connnectionId = `${ottId}.${contentId}`;

      // is action data generation is possible:
      const deeplinkMetaId = `${ottId}.${deviceType}`;
      const deeplinkStr = deeplinkMetaData[deeplinkMetaId];
      const replacementStr = contentIdLinkObj[connnectionId];
      const ottAppDataObj = ottAppsData?.find((ottObj) => ottObj?.id?.toString() === ottId);

      if (deeplinkStr && replacementStr && ottAppDataObj?.id) {
        const itemIndex = actionsDataArr?.findIndex((item) => item?.id === ottAppDataObj?.id);
        let functionalDeeplink = deeplinkStr?.replace("**", replacementStr);
        if (itemIndex === -1) {
          actionsDataArr.push({
            id: ottAppDataObj?.id,
            icon: ottAppDataObj?.image,
            title: ottAppDataObj?.title,
            redirect_url: functionalDeeplink,
          });
        }
      }
    });

    return actionsDataArr;
  } catch (error) {
    return [];
  }
};

export const detailedMediaType = (mediaType, mediaId) => {
  if (mediaType === "deeplink") {
    return "deeplink";
  } else if (mediaType === "app_screenshot") {
    return "app_screenshot";
  } else if (mediaType === "episode") {
    return "episode";
  } else if (mediaType === "ott" || mediaType === "show" || mediaType === "facet") {
    if (isImdbId(mediaId)) {
      if (mediaType === "show") {
        return "show";
      } else if (mediaType === "facet") {
        return "facet";
      } else if (mediaType === "ott") {
        return "ott";
      } else {
        return "cross";
      }
    } else {
      if (mediaType === "facet") {
        return "genre";
      } else {
        return "cross";
      }
    }
  } else {
    return "cross";
  }
};

export const imagePreloader = (imgURLArr) => {
  if (!imgURLArr?.length) return;

  imgURLArr
    ?.filter((val) => typeof val === "string")
    ?.forEach((src_url) => {
      const img = new Image();
      img.src = src_url;
    });

  return;
};

export function safeObject(input) {
  if (
    typeof input === "object" &&
    // eslint-disable-next-line eqeqeq
    input?.constructor == Object &&
    Object.keys(input)?.length > 0
  ) {
    return input;
  } else {
    return {};
  }
}

export function safeArray(input) {
  if (Array.isArray(input)) {
    return input;
  } else {
    return [];
  }
}

export const filterListItemUpdated = (item, newSelectottapps) => {
  let itemCopy = JSON.parse(JSON.stringify(safeObject(item)));
  delete itemCopy?.actions;
  let customActions = [];
  forEach(safeArray(item?.actions?.action_list), (action) => {
    let index = -1;
    if (action && typeof action === "string") {
      index = findIndex(safeArray(newSelectottapps), function (appId) {
        return appId?.toString?.() === action.split(".")?.[0];
      });
    }
    if (index !== -1) {
      customActions.push(action);
    }
  });
  // Remove vudu only if there are other actions
  customActions = customOttFilter(customActions);
  // End of custom removal

  // If the content without OTT apps have to be removed, use below lines

  if (customActions?.length) {
    itemCopy.actions = { ...item?.actions, action_list: customActions };
    return itemCopy;
  } else {
    return null;
  }

  // If the content without OTT apps have to be showed, use commented lines

  // itemCopy['actions'] = customActions;
  // return itemCopy;
};

export const customOttFilter = (data) => {
  const filteredActions = safeArray(data)?.filter((action) => action?.split?.(".")?.[0] !== "8");
  if (filteredActions?.length) {
    return filteredActions;
  } else {
    return safeArray(data);
  }
};

// TODO: keep in common repo
export const filterMediaRow = (rows, newSelectottapps = [], showAllOttApps = false) => {
  if (showAllOttApps) {
    return rows;
  } else {
    let media_rows = [];

    forEach(safeArray(rows), (item) => {
      let media_items = [];
      const _filter = item?.app_filter === true || item?.app_filter === undefined ? true : false;
      if (_filter) {
        forEach(safeArray(item?.media_items), (mediaItem) => {
          if (mediaItem?.item_type === "ott" || mediaItem?.item_type === "show") {
            const filteredItems = filterListItemUpdated(mediaItem, newSelectottapps);
            if (filteredItems !== null) {
              media_items.push(filteredItems);
            }
          } else {
            media_items.push(mediaItem);
          }
        });
      } else {
        media_items = item?.media_items || [];
      }
      const updatedRow = { ...item, media_items: media_items };
      if (updatedRow?.media_rows && updatedRow?.media_rows?.length > 0) {
        updatedRow.media_rows = filterMediaRow(updatedRow.media_rows, newSelectottapps, showAllOttApps);
      }
      media_rows.push(updatedRow);
    });

    return filter(media_rows, function (item) {
      return item?.media_items?.length || safeArray(item?.media_rows)?.find((row) => row?.media_items?.length);
    });
  }
};

export function safeString(input) {
  if (typeof input === "string") {
    return input;
  } else {
    return "";
  }
}

export function handleRegexReplacements(value) {
  try {
    if (safeString(value)) {
      // const pattern = /᛫ 0hr 0min\b|(?<=\s)0hr\b|(?<=\s)0min\b/g;
      const pattern = /᛫ 0hr 0min/g;

      // Replace the matched pattern with an empty string
      const resultText = value.replace(pattern, "");
      return resultText;
    }
  } catch (error) {
    return value;
  }
}
export const trimObjectValues = (object) => {
  for (var key in object) {
    if (object[key].constructor && object[key].constructor === Object) {
      trimObjectValues(object[key]);
    } else if (object[key].trim) {
      object[key] = object[key].trim();
    }
  }
  return object;
};

export const trimPayload = (payload) => {
  if (typeof payload === "string") {
    return payload?.trim();
  } else {
    return trimObjectValues(payload);
  }
};

// Tested
export const emailValidation = (email) => {
  email = trimPayload(email);
  const reg =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (reg.test(email)) {
    return true;
  }
  return false;
};

const safeParse = (data) => {
  if (safeString(data)) {
    return JSON.parse(data);
  }
  return data;
};

export const getActionArray = (actionObject1, actionObject2) => {
  try {
    return {
      action_list: [...new Set([...safeArray(actionObject1?.action_list), ...safeArray(actionObject2?.action_list)])],
      content_ids: {
        ...safeObject(safeParse(actionObject1?.content_ids)),
        ...safeObject(actionObject2?.content_ids),
      },
    };
  } catch (error) {
    return actionObject2;
  }
};

export const filterRecentSlingShowsBasedOnTimeAndSort = (slingContent) => {
  const slingDataRow1 = { ...slingContent?.data?.[0] };
  const slingDataRow2 = { ...slingContent?.data?.[1] };
  const otherRows = slingContent?.data?.slice?.(2);
  const dateNow = new Date();
  const newRow2Titles = [];
  const newRow1Titles =
    slingDataRow1?.titles?.filter?.(
      (title) => dateNow > new Date(title?.start_datetime) && dateNow < new Date(title?.end_datetime)
    ) || [];
  slingDataRow2?.titles?.forEach((title) => {
    if (dateNow < new Date(title?.start_datetime)) {
      newRow2Titles.push(title);
    } else if (dateNow > new Date(title?.start_datetime) && dateNow < new Date(title?.end_datetime)) {
      newRow1Titles.push(title);
    }
  });
  slingDataRow2.titles = newRow2Titles?.sort?.(
    (tile1, tile2) => new Date(tile1?.start_datetime).getTime() - new Date(tile2?.start_datetime).getTime() //Sorted based on start time becaus of requirement and to make updates according to first title
  );
  slingDataRow1.titles = newRow1Titles?.sort?.(
    (tile1, tile2) => new Date(tile1?.start_datetime).getTime() - new Date(tile2?.start_datetime).getTime() //Sorted based on end time to make updates according to first title
  );
  return [slingDataRow1, slingDataRow2, ...otherRows];
};

export const sortByTime = (list, key) => {
  return safeArray(list).sort(
    (tile1, tile2) => new Date(tile1?.[key]).getTime() - new Date(tile2?.[key]).getTime() //Sorted based on end time to make updates according to first title
  );
};

// remove item and update localstorage
export const deleteItemfromFeedStorage = async (id) => {
  try {
    const { success, data } = await lsReadObj(LS_CACHE_KEY_FOLKS_HOME_FEED);
    if (success) {
      const folksDeeplinks = deleteMediaItemFromObject(data.data, id);

      if (folksDeeplinks) {
        lsWriteObj(LS_CACHE_KEY_FOLKS_HOME_FEED, {
          data: data.data,
          meta: data.meta, // Media row meta.
        });
      }
    }
  } catch (error) {}
};

export const addMediaItemsToLS = async (title, type, itemsToBeAdded, skip) => {
  try {
    const { success, data } = await lsReadObj(LS_CACHE_KEY_FOLKS_HOME_FEED);
    if (success) {
      const updatedData = data?.data || [];

      for (let i = 0; i < updatedData?.length; i++) {
        if (updatedData[i]?.type === type && updatedData[i]?.title === title) {
          updatedData[i] = {
            ...safeObject(updatedData[i]),
            media_items: [...(updatedData[i].media_items || []), ...itemsToBeAdded],
            skip: skip,
          };
          break;
        }
      }
      if (updatedData?.length) {
        await lsWriteObj(
          LS_CACHE_KEY_FOLKS_HOME_FEED,
          {
            data: updatedData,
            meta: data.meta,
          },
          true
        );
      }
    }
  } catch (error) {
    console.error("error on adding media items to local Storage", error);
  }
};

// find item by item_id and remove from object
// TODO: FM Native
export const deleteMediaItemFromObject = (object, item_id) => {
  let isContent = "";
  object?.forEach((row) => {
    row.media_items = row.media_items.filter((item) => {
      if (item?.external_ids?.imdb_id === item_id) {
        isContent = item_id;
      }
      return item?.external_ids?.imdb_id !== item_id;
    });
    if (row?.media_rows && row.media_rows?.length > 0) {
      row?.media_rows.forEach((subRow) => {
        subRow.media_items =
          subRow?.media_items?.filter((item) => {
            if (item?.external_ids?.imdb_id === item_id) {
              isContent = item_id;
            }
            return item?.external_ids?.imdb_id !== item_id;
          }) || [];
      });
    }
    if (row?.media_items?.length === 0 && row?.media_rows?.length > 1) {
      row.media_items = row?.media_rows?.find((mediaRow) => mediaRow?.media_items?.length > 0)?.media_items || [];
    }
  });
  if (isContent) {
    return true;
  }
};

export const limitText = (str, limit) => {
  if (str?.length > limit) {
    return str?.substring(0, limit) + "...";
  }
  return str;
};

// compare 2 array
export const arraysAreEqualUnordered = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  const sortedArr1 = arr1.slice().sort((a, b) => a - b);
  const sortedArr2 = arr2.slice().sort((a, b) => a - b);
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) return false;
  }
  return true;
};
export function extractMovieInfo(movieInfo) {
  try {
    const pattern1 = /(\d{4}) · ([\d.]+) ⭐ · .*/;
    const pattern2 = /([\d.]+) ⭐ · (\d{4}) · (\d+hr \d+min)/;

    let match1 = movieInfo.match(pattern1);
    if (match1) {
      let year = match1[1] || "";
      let rating = convertToFloat(match1[2]);
      let duration = "";
      return { year: year, rating: rating, duration: duration };
    }

    let match2 = movieInfo.match(pattern2);
    if (match2) {
      let rating = convertToFloat(match2[1]);
      let year = match2[2] || "";
      let duration = match2[3] || "";
      return { year: year, rating: rating, duration: duration };
    }
    return null;
  } catch (error) {
    return null;
  }
}

export function convertToFloat(num = 0) {
  try {
    const temp = parseFloat(num || 0) || 0;

    if (typeof temp === "number") {
      return temp.toFixed(1);
    }
    return num;
  } catch (error) {
    return num;
  }
}

export const pluralize = (num, word) => {
  if (!num) {
    return `No ${word}s`;
  }
  const isPlural = (num || 0) > 0;
  return isPlural ? `${num} ${word}s` : `${num} ${word}`;
};

export const getSubtitleString = (subtitle) => {
  const { rating = "", year = "", duration = "" } = extractMovieInfo(subtitle) || {};
  return `${rating} · ${year} · ${duration}`;
};

export const getStringify = (data) => {
  try {
    if (typeof data === "string") {
      return data;
    }
    return JSON.stringify(data);
  } catch (error) {
    console.error("Error stringifying data:", error);
    return data;
  }
};
