import { types, flow, getParent } from "mobx-state-tree";
import ContentAPI from "../../api/ContentServiceAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";

const publicProfileStoreModel = {};

const publicProfileStoreActions = (self) => ({
  // --------------- public user profile data -----------------
  getProfileData: flow(function* getProfileData(user_id, limit, skip) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_PROFILE_DATA({ user_id, limit, skip }));
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data });
      } else {
        throw new Error("Something went wrong while fetching public user profile data!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching public user profile data!",
      });
    }
  }),

  // --------------- public user all reviews -----------------
  getAllReviews: flow(function* getAllReviews(user_id, limit, skip) {
    try {
      const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_PROFILE_DATA({ user_id, service: "all", limit, skip }));
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data });
      } else {
        throw new Error("Something went wrong while fetching public user profile data!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching public user profile data!",
      });
    }
  }),

  // --------------- public user palylists -----------------
  getPlaylists: flow(function* getPlaylists(limit, skip, user_id) {
    try {
      const res = yield ContentAPI.get(
        __.CONTENT_SERVICE.GET_PROFILE_DATA({ user_id, service: "playlist", limit, skip })
      );
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data?.playlist_data?.my_data });
      } else {
        throw new Error("Something went wrong while fetching public user profile playlist!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching public user profile playlist!",
      });
    }
  }),

  // --------------- public user content reviews -----------------
  getContentReviews: flow(function* getContentReviews(limit, skip, user_id) {
    try {
      const res = yield ContentAPI.get(
        __.CONTENT_SERVICE.GET_PROFILE_DATA({ user_id, service: "content-review", limit, skip })
      );
      if (res?.status === 200 && res?.data) {
        return Promise.resolve({ success: true, data: res?.data?.content_review });
      } else {
        throw new Error("Something went wrong while fetching public user profile content reviews!");
      }
    } catch (error) {
      getParent(self).notificationStore.triggerError("Can't fetch the data from server");
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching public user profile content reviews!",
      });
    }
  }),
});

const publicProfileStoreViews = (self) => ({});

export const publicProfileStore = types
  .model("Public Profile Store", publicProfileStoreModel)
  .actions(publicProfileStoreActions)
  .views(publicProfileStoreViews);
