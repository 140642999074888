import { getParent, types } from "mobx-state-tree";

export const browserStore = types
  .model({
    isOnline: true,
    isMobileUser: false,
    isMobileScreen: false,
  })
  .actions((self) => ({
    setHandleOnline() {
      self.isOnline = true;
      getParent(self).notificationStore.triggerOnlineNotification();
    },
    setHandleOffline() {
      self.isOnline = false;
      getParent(self).notificationStore.triggerOfflineNotification();
    },
  }));
