export const initialMdpState = {
  currentItemImdbId: "",

  mediaHeader: undefined,

  mediaRows: {
    loading: false,
    success: false,
    message: "",
    data: [],
  },
};
