import React from "react";
import FolksModal from "../../../../../components/folks-modal/FolksModal";
import { ModalHeadingText } from "../../../../../components/typography/ModalText";
import styles from "./confirmModal.module.css";
import { LoadingOutlined } from "@ant-design/icons";

function ConfirmModal({ isVisible, handleCancel, isLoading, handleConfirm, text }) {
  return (
    <FolksModal visible={isVisible} onCancel={handleCancel}>
      <div className={styles.modalContainer}>
        <div>
          <ModalHeadingText>{text}</ModalHeadingText>
          <div className={styles.modalFooterContainer}>
            <div>
              <div className={styles.buttonContainer}>
                <div onClick={handleCancel}>
                  <button className={styles.noButton}>No</button>
                </div>
                <div>
                  <button className={styles.yesButton} onClick={handleConfirm} disabled={isLoading}>
                    {isLoading && <LoadingOutlined style={{ color: "white", margin: "2px" }} spin />}
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FolksModal>
  );
}

export default ConfirmModal;
