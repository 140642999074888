import { flow, types, destroy, getParent } from "mobx-state-tree";
import { toJS } from "mobx";
import ProductsAPI from "../../api/ProductsAPI";
import ContentAPI from "../../api/ContentServiceAPI";
import { EP_ROOT as __ } from "../../utils/endpoints";
import { lsReadObj, lsWriteObj } from "../../api/local-cache/localCache";
import {
  LS_CACHE_KEY_FOLKS_BANNER_DATA,
  LS_CACHE_KEY_FOLKS_HOME_FEED,
  LS_CACHE_KEY_GENRE_FEED,
  LS_CACHE_KEY_FOLKS_CONTENT_META,
} from "../../api/local-cache/lsCacheKeys";

import {
  deProxyJSONData,
  getLocalDateTimeStr,
  filterMediaRow,
  filterRecentSlingShowsBasedOnTimeAndSort,
  sortByTime,
  addMediaItemsToLS,
} from "../../utils/helper";
import { mediaRowModel, metaTitleModel, bannerModel, slingRowModel } from "./models/contentModel";
import { sanitizeMediaRows, sanitizeMetaTitles, sanitizeHomeBanners } from "./filters/contentFilter";
import { emotionModel } from "./models/actionModel";
import { getUTCDateTime } from "../../utils/datetime";
import { LS_KEY_FOLKS_USER_TOKEN, MIN_ITEMS_PER_ROW } from "../../utils/constants";

export const feedsStore = types
  .model("feed_store_model", {
    folksBannerData: types.optional(types.array(bannerModel), []),
    moviesBannerData: types.optional(types.array(bannerModel), []),
    showsBannerData: types.optional(types.array(bannerModel), []),

    selectedEmotionObj: types.maybeNull(emotionModel),
    emotionDeeplinks: types.maybe(
      types.model("emotion_deeplink_model", {
        loading: false,
        success: false,
        message: "",
        data: types.maybe(mediaRowModel),
      })
    ),

    genreDeeplinks: types.optional(types.array(mediaRowModel), []),
    folksDeeplinks: types.optional(types.array(mediaRowModel), []),
    homePageShownRows: types.optional(types.array(mediaRowModel), []),
    folksDeeplinkMetaTitles: types.optional(types.array(metaTitleModel), []),

    viewLevelCount: types.number,
    expandedData: types.optional(types.array(mediaRowModel), []),

    filterRowType: types.optional(types.string, ""),

    slingLiveRow: types.maybe(slingRowModel),
    slingUpcoRow: types.maybe(slingRowModel),
    slingAppIcon: types.optional(types.string, ""),

    deviceMeta: types.optional(types.string, ""),
    deeplinkMeta: types.optional(types.string, ""),
  })
  .actions((self) => ({
    setFilterRowType(rowType) {
      self.filterRowType = typeof rowType === "string" ? rowType : "";
    },

    setExpandedData(rowData) {
      self.expandedData = JSON.parse(JSON.stringify(rowData));
    },

    setViewLevelCount(count) {
      self.viewLevelCount = count;
    },

    removeMediaRow(mediaRowNode) {
      destroy(mediaRowNode);
    },

    setSelectedEmotionObj(emotion_obj) {
      self.selectedEmotionObj = JSON.parse(JSON.stringify(emotion_obj));
      // Load emotion feeds.
      const emotion_value = emotion_obj?.emotion_value;
      if (emotion_value) {
        self.getEmotionBasedDeeplinks({ emotion_value }); // background call
      }
    },

    // 1) Set Banners
    setFolksBannerData(bannerData) {
      // hard coded shoud be removed.
      // const bannerIdWithImdbIdMap = {
      //   166: {
      //     title: "Smurfs: The Lost Village",
      //     description: "",
      //     media_type: "ott",
      //     media_id: "tt2398241",
      //   },
      //   167: {
      //     title: "Baby Reindeer",
      //     description: "",
      //     media_type: "show",
      //     media_id: "tt13649112",
      //   },
      //   168: {
      //     title: "Dead Boy Detectives",
      //     description: "",
      //     media_type: "show",
      //     media_id: "tt15358446",
      //   },
      //   169: {
      //     title: "Anyone But You",
      //     description: "",
      //     media_type: "ott",
      //     media_id: "tt26047818",
      //   },
      //   170: {
      //     title: "American Fiction",
      //     description: "",
      //     media_type: "ott",
      //     media_id: "tt23561236",
      //   },
      // };

      try {
        self.folksBannerData = sanitizeHomeBanners(bannerData);
      } catch (e) {
        throw new Error(`MST Error: While storing folks banner data! \n\n ${e}`);
      }
    },

    setMovieShowsBanner(bannerData) {
      try {
        const banners = sanitizeHomeBanners(bannerData);
        self.moviesBannerData = banners.filter((banner) => banner.item_type === "ott" || banner.item_type === "movie");
        self.showsBannerData = banners.filter((banner) => banner.item_type === "show");
      } catch (e) {
        throw new Error(`MST Error: While storing folks banner data! \n\n ${e}`);
      }
    },
    // 2) OTT/Shows Deeplinks
    setFolksDeeplinks(mediaRows, metaTitles) {
      // Filter folks deeplinks:
      const { newSelectottapps, showAllOttApps } = getParent(self).contentSettingsStore.contentSettings;
      const sanitizedMetaTitles = sanitizeMetaTitles(metaTitles);
      const sanitizedMediaRows = sanitizeMediaRows(
        filterMediaRow(mediaRows, toJS(newSelectottapps), toJS(showAllOttApps))?.map((row) => ({
          ...row,
          has_pagination: true,
          pagination_module: "home",
        }))
      );
      try {
        self.folksDeeplinkMetaTitles = sanitizedMetaTitles;
        self.folksDeeplinks = self.updateHomePageShownRows(sanitizedMetaTitles, sanitizedMediaRows);
      } catch (e) {
        throw new Error(`MST Error: While storing folks deeplinks! \n\n ${e}`);
      }
    },

    // 3) Genre Deeplinks
    setGenreDeeplinks(mediaRows) {
      const sanitizedMediaRows = sanitizeMediaRows(mediaRows);
      const filteredGenreMediaRows = sanitizedMediaRows?.map((row) => ({
        ...row,
        type: "genre",
        media_items: row?.media_items?.map((item) => ({
          ...item,
          poster_assets: {
            image_assets: {
              o: {
                url: item?.image_hd,
                width: null,
                height: null,
              },
              h: {
                url: item?.image,
                width: 960,
                height: 540,
              },
              m: {
                url: item?.image,
                width: 480,
                height: 270,
              },
            },
          },
        })),
      }));

      try {
        self.genreDeeplinks = filteredGenreMediaRows;
      } catch (e) {
        throw new Error(`MST Error: While storing genre deeplinks! \n\n ${e}`);
      }
    },

    // 4) Sling Media Deeplinks
    setSlingRows(liveShowsObj, upcoShowsObj) {
      function isChannelInSelectedPackage(title) {
        return getParent(self).contentSettingsStore.getSlingSelectedPackChannelIdArr.includes(title?.channel_group_id);
      }

      const filteredLiveShowsObj = {
        ...liveShowsObj,
        isLiveRow: true,
        titles: sortByTime(liveShowsObj.titles.filter(isChannelInSelectedPackage), "start_datetime"),
      };
      const filteredUpcoShowsObj = {
        ...upcoShowsObj,
        isLiveRow: false,
        titles: sortByTime(upcoShowsObj.titles.filter(isChannelInSelectedPackage), "start_datetime"),
      };

      try {
        self.slingLiveRow = filteredLiveShowsObj;
      } catch (e) {
        throw new Error(`MST Insertion Error: Storing Sling live TV rows. \n\n ${e}`);
      }

      try {
        self.slingUpcoRow = filteredUpcoShowsObj;
      } catch (e) {
        throw new Error(`MST Insertion Error: Storing Sling upcoming TV rows. \n\n ${e}`);
      }
    },
    // 1) Get Banners
    getFolksBannerData: flow(function* getFolksBannerData() {
      // Cache fetch.
      try {
        const { success, data, message } = yield lsReadObj(LS_CACHE_KEY_FOLKS_BANNER_DATA);
        if (success) {
          self.setFolksBannerData(data?.data);
          return Promise.resolve({ success: true });
        } else {
          console.info(`Cache miss on fetching folks banner data! \nReason: ${message}`);
        }
      } catch (e) {
        console.error(e);
      }

      // API call.
      try {
        const res = yield ProductsAPI.get(__.PRODUCT_SERVICE.GET_BANNERS);
        if (res?.status === 200 && res?.data) {
          lsWriteObj(LS_CACHE_KEY_FOLKS_BANNER_DATA, { data: res?.data });
          self.setFolksBannerData(res?.data);
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching folksmedia banners!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),
    getMovieShowsBanners: flow(function* getMovieShowsBanners() {
      // API call.
      try {
        const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
        const res = yield ProductsAPI.get(__.PRODUCT_SERVICE.GET_MOVIE_SHOWS_BANNERS, {
          headers: {
            Authorization: folksUserToken ? `Bearer ${folksUserToken}` : "",
            dashboard: true,
          },
        });
        if (res?.status === 200 && res?.data) {
          self.setMovieShowsBanner(res?.data);
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching folksmedia banners!");
        }
      } catch (error) {
        console.log("aaya", { error });
        return Promise.reject(error);
      }
    }),
    // 2) Get OTT/Shows Deeplinks
    getFolksDeeplinks: flow(function* getFolksDeeplinks() {
      try {
        const { success, data, message } = yield lsReadObj(LS_CACHE_KEY_FOLKS_HOME_FEED);
        if (success) {
          self.setFolksDeeplinks(data?.data, data?.meta);
          return Promise.resolve({ success: true });
        } else {
          console.info(`Cache miss on fetching folks deeplinks! \nReason: ${message} `);
        }
      } catch (e) {
        console.error(e);
      }

      // API call.
      try {
        const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_BASKET_CONTENT);
        if (res?.status === 200 && res?.data) {
          lsWriteObj(LS_CACHE_KEY_FOLKS_HOME_FEED, {
            data: res?.data?.data?.media_rows,
            meta: res?.data?.data?.meta?.media_rows_meta, // Media row meta.
          });

          self.setFolksDeeplinks(res?.data?.data?.media_rows, res?.data?.data?.meta?.media_rows_meta);

          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching folksmedia deeplinks!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    // 2a) Get Paginated OTT/Shows Deeplinks
    getPaginatedFolksDeeplinks: flow(function* getPaginatedFolksDeeplinks(paginate_row_type, paginate_row_pos) {
      if (!(paginate_row_type || paginate_row_pos)) return Promise.reject("Params not present!");
      const observablePaginatedMediaRow = self.folksDeeplinks.find(
        (row) => row.type === paginate_row_type && row.position === paginate_row_pos
      );
      observablePaginatedMediaRow.setLoading(true);
      // const skipVal = observablePaginatedMediaRow.media_items.length || 0;
      const limitVal = observablePaginatedMediaRow.limit || 10;

      const skipVal = observablePaginatedMediaRow?.skip + limitVal || 0;
      // const countVal = observablePaginatedMediaRow.count;
      // Stop pagination.
      // if (skipVal >= countVal) return Promise.resolve({ success: true });
      const getParams = {
        params: { skip: skipVal, limit: limitVal, row_type: paginate_row_type, position: paginate_row_pos },
      };

      // API call ->  Fetch paginated data.
      try {
        const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_BASKET_CONTENT, getParams);
        if (res?.status === 200 && res.data?.data?.media_rows?.length) {
          const { newSelectottapps, showAllOttApps } = getParent(self).contentSettingsStore.contentSettings;
          const sanitizedMediaRow = sanitizeMediaRows(
            filterMediaRow(res.data.data.media_rows, toJS(newSelectottapps), toJS(showAllOttApps))
          );
          const rowMediaItems = sanitizedMediaRow?.[0]?.media_items;
          if (rowMediaItems?.length) {
            observablePaginatedMediaRow.addMediaItems(rowMediaItems);
            addMediaItemsToLS(observablePaginatedMediaRow?.title, paginate_row_type, rowMediaItems, skipVal);
          }
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching paginated folksmedia deeplinks!");
        }
      } catch (error) {
        observablePaginatedMediaRow.setLoading(false);
        return Promise.reject(error);
      }
    }),

    // 3) Get Genre Deeplinks
    getGenreDeeplinks: flow(function* getGenreDeeplinks() {
      // Cache fetch.
      try {
        const { success, data, message } = yield lsReadObj(LS_CACHE_KEY_GENRE_FEED);
        if (success) {
          self.setGenreDeeplinks(data?.data);
          return Promise.resolve({ success: true });
        } else {
          console.info(`Cache miss on fetching genre deeplinks! \nReason: ${message} `);
        }
      } catch (e) {
        console.error(e);
      }

      // API call.
      try {
        const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_GENRE_LIST);
        if (res?.status === 200 && res?.data?.data?.media_rows?.length) {
          lsWriteObj(LS_CACHE_KEY_GENRE_FEED, { data: res.data.data.media_rows });
          self.setGenreDeeplinks(res.data.data.media_rows);
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching genre deeplinks!");
        }
      } catch (error) {
        return Promise.reject({
          success: false,
          message: error?.message || "Something went wrong while fetching genre deeplinks!",
        });
      }
    }),

    // 4) Get Sling Media Deeplinks
    getSlingShows: flow(function* getSlingShows() {
      const dashedTZStr = Intl.DateTimeFormat()?.resolvedOptions()?.timeZone?.replaceAll("/", "-");
      const updatedTZStr = dashedTZStr.includes("Asia-Calcutta") ? "Asia-Kolkata" : dashedTZStr;
      try {
        const utcTime = getUTCDateTime();
        const res = yield ContentAPI.get(
          __.CONTENT_SERVICE.GET_SLING_SCHEDULE({ time_zone: updatedTZStr, utc_time: utcTime })
        );
        if (res?.status === 200 && res?.data) {
          const filteredByDateTime = filterRecentSlingShowsBasedOnTimeAndSort(res?.data);
          self.setSlingRows(filteredByDateTime[0], filteredByDateTime[1]); // (sling row object)
          self.slingAppIcon = res.data?.sling_app_icon;
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching sling shows!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    // 5) Get Emotion Deeplinks
    getEmotionBasedDeeplinks: flow(function* getEmotionBasedDeeplinks({ emotion_value }) {
      // API call.
      self.emotionDeeplinks = {
        loading: true,
        success: false,
        message: "",
        data: toJS(self.emotionDeeplinks.data) || undefined,
      };
      try {
        const { newSelectottapps, showAllOttApps } = getParent(self).contentSettingsStore.contentSettings;
        const res = yield ContentAPI.get(__.CONTENT_SERVICE.GET_EMOTION_CONTENT(parseInt(emotion_value)));
        if (res?.status === 200 && res?.data?.data?.media_rows?.length) {
          const sanitizedMediaRows = sanitizeMediaRows(
            filterMediaRow(res.data.data.media_rows, toJS(newSelectottapps), toJS(showAllOttApps))
          );
          const feedBackReadyMediaRows = sanitizedMediaRows?.map((row) => ({
            ...row,
            media_items: row?.media_items?.map((item) => ({ ...item, feedback_allowed: false })),
          }));

          self.emotionDeeplinks = {
            loading: false,
            success: true,
            message: "success",
            data: feedBackReadyMediaRows?.[0],
          };
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching genre deeplinks!");
        }
      } catch (error) {
        self.emotionDeeplinks = {
          loading: false,
          success: false,
          message: error?.message || "Something went wrong while fetching emotion based deeplinks!",
          data: undefined,
        };
        return Promise.reject({ success: false });
      }
    }),

    getContentMeta: flow(function* getContentMeta() {
      // Cache fetch.
      try {
        const { success, data, message } = yield lsReadObj(LS_CACHE_KEY_FOLKS_CONTENT_META);
        if (success) {
          self.deeplinkMeta = data.deeplink_meta;
          self.deviceMeta = data.device_meta;
          return Promise.resolve({ success: true });
        } else {
          console.info(`Cache miss on fetching folks deeplinks! \nReason: ${message} `);
        }
      } catch (e) {
        console.error(e);
      }
      // API call.
      try {
        const res = yield ContentAPI.get(__.CONTENT_SERVICE.CONTENT_META);
        if (res?.status === 200 && res?.data) {
          lsWriteObj(LS_CACHE_KEY_FOLKS_CONTENT_META, {
            deeplink_meta: JSON.stringify(res?.data?.data?.deeplink_meta),
            device_meta: JSON.stringify(res?.data?.data?.device_meta),
          });
          self.deeplinkMeta = JSON.stringify(res?.data?.data?.deeplink_meta);
          self.deviceMeta = JSON.stringify(res?.data?.data?.device_meta);
          return Promise.resolve({ success: true });
        } else {
          throw new Error("Something went wrong while fetching folksmedia deeplinks!");
        }
      } catch (error) {
        return Promise.reject(error);
      }
    }),

    updateSlingSchedule() {
      try {
        const currentDateTime = getLocalDateTimeStr(); // JSON Date string.
        const liveLeastEndTime = self.slingLiveRow?.titles?.[0]?.end_datetime || null;
        const upcomingLeastStartTime = self.slingUpcoRow?.titles?.[0]?.start_datetime || null;

        // if current time is greater then the slingLiveRow[0]'s end time then update the rows
        if (currentDateTime > liveLeastEndTime || currentDateTime > upcomingLeastStartTime) {
          // update live recommendations.
          const filteredLiveShows = deProxyJSONData(
            self.slingLiveRow.titles.filter((show) => currentDateTime < show.end_datetime)
          );
          const currentShows = deProxyJSONData(
            self.slingUpcoRow.titles.filter(
              (show) => show.start_datetime <= currentDateTime && currentDateTime < show.end_datetime
            )
          );

          const liveRowData = deProxyJSONData(
            [...filteredLiveShows, ...currentShows].sort(
              (tile1, tile2) => new Date(tile1?.start_datetime).getTime() - new Date(tile2?.start_datetime).getTime() //Sorted based on end time to make updates according to first title
            )
          );

          self.slingLiveRow.replaceTitles(liveRowData);
          // update upcoming recommendations.
          const laterShows = deProxyJSONData(
            self.slingUpcoRow.titles
              ?.filter((show) => show.start_datetime > currentDateTime)
              ?.sort(
                (tile1, tile2) =>
                  new Date(tile1?.start_datetime)?.getTime() - new Date(tile2?.start_datetime)?.getTime() //Sorted based on end time to make updates according to first title
              )
          );
          self.slingUpcoRow.replaceTitles(laterShows);
        }
      } catch (error) {
        console.error(error);
      }
    },
    updateHomePageShownRows(folksDeeplinkMetaTitles, folksDeeplinks) {
      try {
        const metaTitleMap = {};
        folksDeeplinkMetaTitles?.forEach((obj) => {
          metaTitleMap[obj?.type] = {
            title: obj?.title,
            cross_button_visiblity: obj?.cross_button_visiblity,
          };
        });

        folksDeeplinkMetaTitles?.forEach((metaObj) => {
          const index = folksDeeplinks.findIndex(
            (mediaRowObj) =>
              mediaRowObj?.type === metaObj?.type && mediaRowObj?.media_items?.length >= MIN_ITEMS_PER_ROW
          );
          if (index !== -1) {
            const item = folksDeeplinks[index];
            folksDeeplinks[index] = {
              ...item,
              meta_title: `${metaTitleMap[item?.type]?.title}`,
              cross_button_visiblity: metaTitleMap?.[item?.type]?.cross_button_visiblity,
            };
          }
        });
        return folksDeeplinks;
      } catch (error) {
        return folksDeeplinks;
      }
    },
    getMetaTitleFromRowType(rowType) {
      return self.folksDeeplinkMetaTitles?.find((meta) => meta?.type === rowType)?.title || "";
    },

    getRowTitle(rowType, rowPos) {
      return self.folksDeeplinks?.find((row) => row?.type === rowType && row.position === rowPos)?.title || "";
    },

    getMetaRows(rowType) {
      return self.folksDeeplinks?.filter((row) => row?.type === rowType);
    },

    getMediaRow(rowType, rowPos) {
      return [self.folksDeeplinks?.find((row) => row?.type === rowType && row.position === rowPos)];
    },
  }))
  .views((self) => ({
    get homePageBanners() {
      // Banner data shuffle.
      return [...self.folksBannerData]
        ?.map((value) => ({ value, sort: Math.random() }))
        ?.sort((a, b) => a.sort - b.sort)
        ?.map(({ value }) => value);
    },

    get homePageFeed() {
      // Filter folksDeeplinks to get rows with a meta_title
      const homePageShownRows = self.folksDeeplinks.filter((item) => item?.meta_title);
      const homePageFeed = self.folksDeeplinkMetaTitles
        .map((item) => homePageShownRows.find((feedRow) => feedRow.meta_title === item.title))
        .filter(Boolean);

      const data = [
        ...homePageFeed?.slice(0, 4),
        ...self.genreDeeplinks,
        self.slingLiveRow,
        self.slingUpcoRow,
        ...homePageFeed?.slice(4),
      ];
      return data;
    },

    get metaFilteredRows() {
      if (self.filterRowType === "genre") {
        return self.genreDeeplinks;
      } else {
        return [...self.folksDeeplinks.filter((obj) => obj.type === self.filterRowType)];
      }
    },

    get tvShowRows() {
      // FM fav shows
      return self.folksDeeplinks.filter((mediaRowObj) => mediaRowObj?.type === "fm_fav_show");
    },

    get movieRows() {
      // FM fav movies
      return self.folksDeeplinks.filter((mediaRowObj) => mediaRowObj?.type === "fm_fav_movie");
    },

    get SearchMediaRows() {
      const searchfolksDeeplinks = self.folksDeeplinks.slice(0, 2)?.map((row) => ({
        ...row,
        is_title_clickable: false,
        media_items: row?.media_items?.map((item) => ({ ...item, feedback_allowed: false })),
      }));

      return searchfolksDeeplinks;
    },
    get getMoviesBannerData() {
      return self.moviesBannerData;
    },
    get getShowsBannerData() {
      return self.showsBannerData;
    },
  }));
