import {
  LS_KEY_COGNITO_ID_TOKEN,
  LS_KEY_FOLKS_USER_TOKEN,
  LS_KEY_FOLKS_USER_TYPE,
  LS_KEY_FOLKS_USER_ID,
} from "../../../utils/constants";

export const USER_TYPE = {
  GUEST: "GUEST", // user signed in as guest.
  NORMAL: "NORMAL", // user signed in as normal user.
  KID: "KID", // user signed in as kid user.
  NONE: "NONE", // user not signed in / logged out.
  RESTRICTED: "RESTRICTED", // Only allowed landing page routes.
};

export const allowedUserTypes = Object.values(USER_TYPE);

export const initialAuthStoreState = {
  cognitoIdToken: localStorage.getItem(LS_KEY_COGNITO_ID_TOKEN) || "",
  folksUserToken: localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "",
  folksUserType: localStorage.getItem(LS_KEY_FOLKS_USER_TYPE) || USER_TYPE.NONE,
  folksUserId: localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "",
};

export const logoutAuthStoreState = {
  cognitoIdToken: "",
  folksUserToken: "",
  folksUserType: USER_TYPE.NONE,
  folksUserId: "",
};
