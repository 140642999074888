import { flow, types } from "mobx-state-tree";
import AnalyticsApi from "../../api/AnalyticsApi";
import { analyticsUrl } from "../../utils/helper";

const AnalyticsStoreModel = {};

const AnalyticsStoreActions = (self) => ({
  getAnalyticsPageView: flow(function* getAnalyticsPageView(eventType, eventDetails = {}) {
    const data = {
      type: "event",
      event_type: "user_action",
      name: eventType, //array of events or single events,
      ...eventDetails,
    };
    const payload = {
      "Adcuratio Token": localStorage.getItem("folks_user_token"),
      "App Name": "FolksMedia",
      source: "web",
      env: process.env.NODE_ENV,

      data: Array.isArray(data) ? data : [{ ...data }],
    };
    try {
      const res = yield AnalyticsApi.post(analyticsUrl, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),

  getSubscribeAnalytics: flow(function* getSubscribeAnalytics(watchlist_id, eventType) {
    const data = {
      type: "event",
      event_type: "user_action",
      name: "watchlist_subscription", //array of events or single events,
      data: {
        watchlist_id: watchlist_id,
        subscription: eventType,
      },
    };

    const payload = {
      "Adcuratio Token": localStorage.getItem("folks_user_token"),
      "App Name": "FolksMedia",
      source: "web",
      env: process.env.NODE_ENV,
      data: Array.isArray(data) ? data : [{ ...data }],
    };

    try {
      const res = yield AnalyticsApi.post(analyticsUrl, payload);
      if (res?.status === 200) {
        return Promise.resolve({ success: true });
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }),
});

const AnalyticsStoreViews = () => ({});

export const AnalyticsStore = types
  .model("AnalyticsStore", AnalyticsStoreModel)
  .actions(AnalyticsStoreActions)
  .views(AnalyticsStoreViews);
