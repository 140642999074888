// export const maxSearchSuggestionsLength = 4;
// export const defaultSearchSuggestions = ["English Movies", "Science Fiction", "English Crime movies", "Superhero"];

export const initialSearchStoreState = {
  searchVal: "",
  searchMediaRows: [],
  searchPlaylists: [],
  trendingMediaRows: [],
  skip: 0,
  limit: 14,
  category: "",
  // searchSuggestions: defaultSearchSuggestions,
  // lastSearchSuggestions: defaultSearchSuggestions,
  // noSuggestionsFlag: false,
};
