import { Button } from "antd";
import styled from "styled-components";

export const AuthButton = styled(Button)`
  &&& {
    background: transparent;
    color: #fff;
    font-size: 1.1vw;
    padding: 0 1vw;
    height: 2.5vw;
    margin: 20px auto;
    border-radius: 8px;
    min-width: 100px;

    &:hover {
      border: 1px solid #ffffff;
    }
  }
  @media (max-width: 480px) {
    &&& {
      height: 10vw;
      font-size: 3vw;
      min-width: 120px;
  }
`;
