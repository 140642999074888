import { types } from "mobx-state-tree";

export const modalControllerStore = types
  .model({
    isGuestModalVisible: false,
  })
  .actions((self) => ({
    setGuestModalVisible(status) {
      self.isGuestModalVisible = status;
    },
  }));
