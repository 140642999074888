import axios from "axios";
import { MAX_REQ_TIME_OUT_MS, LS_KEY_FOLKS_USER_TOKEN } from "../utils/constants";
import { EP_ROOT as __ } from "../utils/endpoints";
import { handleRefreshToken } from "./helperAPI";

const axiosInstance = () => {
  const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
  const defaultOptions = {
    baseURL: `${process.env.REACT_APP_AUTH_BACKEND}`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: folksUserToken ? `Bearer ${folksUserToken}` : "",
    },
  };
  const instance = axios.create(defaultOptions);
  const response = processInstanceRequest(instance);
  return processInstanceResponse(response);
};

const processInstanceRequest = (instance) => {
  instance.defaults.timeout = MAX_REQ_TIME_OUT_MS;
  instance.interceptors.request.use((config) => {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    config.headers["Authorization"] = folksUserToken ? `Bearer ${folksUserToken}` : "";
    // if (config.url.includes("web_user_login")) {
    //   config.transformRequest = (data, headers) => {delete headers.common['Authorization']; return data;};
    // }
    return config;
  });
  return instance;
};

const processInstanceResponse = (instance) => {
  instance.interceptors.response.use(
    (res) => res,
    async (error) => {
      // All the 401 requests require token refresh except these.
      if (
        error.response.config.url.includes(__.AUTH_SERVICE.FORGOT_PASSWORD || __.AUTH_SERVICE.CONFIRM_FORGOT_PASSWORD)
      ) {
        return Promise.reject(error);
      }
      return handleRefreshToken(error, instance);
    }
  );
  return instance;
};

export default axiosInstance();
