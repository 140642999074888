import { flow, types } from "mobx-state-tree";
import PaymentAPI from "../api/PaymentAPI";
import { EP_ROOT as __ } from "../utils/endpoints";

const PaymentsStoreModel = {};

const PaymentsStoreActions = (self) => ({
  addCard: flow(function* addCard() {
    try {
      const res = yield PaymentAPI.post(__.PAYMENT_SERVICE.ADD_CARD);
      if (res?.status === 200) {
        return { success: true, data: res?.data };
      } else {
        throw res;
      }
    } catch {
      return { success: false, message: "Unable to fetch secret key" };
    }
  }),
  associatePaymentMethod: flow(function* associatePaymentMethod(payload) {
    try {
      const res = yield PaymentAPI.post(__.PAYMENT_SERVICE.ADD_PAYMENT_METHOD, payload);
      if (res?.status === 200) {
        return {
          success: true,
          data: res.data,
        };
      } else {
        throw res;
      }
    } catch {
      return { success: false, message: "Unable to associate payment method" };
    }
  }),
});

const PaymentsStoreViews = () => ({});

export const paymentsStore = types
  .model("PaymentsStore", PaymentsStoreModel)
  .actions(PaymentsStoreActions)
  .views(PaymentsStoreViews);
