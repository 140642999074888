import cloneDeep from "lodash.clonedeep";
import { store as rootStore } from "../store";
import { flattenObject } from "../utils/helper";
import { REDIRECTION_ROUTES } from "../utils/redirectionSettings";

export const handleRefreshToken = async (error, instance) => {
  if (error?.response?.status !== 401) {
    return Promise.reject(error);
  }

  const maxRefreshRetries = 2;
  const config = cloneDeep(error.response.config);

  // This additional key will help in eliminating the possiblity of infinite loop.
  if (!config.hasOwnProperty("numRefreshRetries")) {
    config.numRefreshRetries = 0;
  }

  if (config.hasOwnProperty("numRefreshRetries") && config?.numRefreshRetries < maxRefreshRetries) {
    try {
      const refreshRes = await rootStore.auth.refreshAllTokens();
      if (refreshRes?.success) {
        config.numRefreshRetries += 1;
        return instance(config);
      } else {
        throw new Error("Refresh token error!");
      }
    } catch (e) {
      await rootStore.auth.logout();
      window.location.href = REDIRECTION_ROUTES.REFRESH_FAIL_LOGOUT;
    }
  } else {
    await rootStore.auth.logout();
    window.location.href = REDIRECTION_ROUTES.REFRESH_FAIL_LOGOUT;
  }
};

export const processAxiosErrors = (error) => {
  const processedErrors = [];
  if (error?.messages) {
    processedErrors.push(error?.messages);
  } else {
    processedErrors.push(
      "An unexpected error occurred. If the problem persists, please contact the Adcuratio administrator for further support."
    );
  }
  const errors = flattenObject(processedErrors || []);
  const errorMessage = Object.values(errors)?.[0];
  return { success: false, data: errorMessage };
};
