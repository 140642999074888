import { LazyLoadImage } from "react-lazy-load-image-component";
import styled from "styled-components";

export const ContentCardImage = styled(LazyLoadImage)`
  height: 100%;
  width: 100%;
  cursor: pointer;
  object-fit: cover;
  display: ${(props) => props.display};
`;
