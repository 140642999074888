import React, { useEffect, useState } from "react";
import { useMst } from "../../store";
import { emailValidation, validateEmail, validatePasswordText } from "../../utils/helper";

import { Row, Col } from "antd";
import { MailOutlined } from "@ant-design/icons";
import Logo from "../component/logo/Logo";
import Footer from "../component/footer/Footer";
import { AuthButton } from "../component/AuthButton";
import { AuthInput } from "../component/AuthInput";

import styles from "./otp.module.css";
import { useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import { Auth } from "aws-amplify";
import { URLS } from "../../routes/routeURL";

const OTP = ({ history, resetPassPage, setResetPassPage }) => {
  const { state } = useLocation();
  const { notificationStore, setLoginModalVisible } = useMst();

  const [email, setEmail] = useState(state?.email || "");

  const [OTP, setOTP] = useState("");
  const [seconds, setSeconds] = useState(59);

  const [loading, setLoading] = useState(false);

  const backToLogin = () => {
    // reset fields.
    setEmail("");
    setOTP("");

    setLoading(false);

    // Go to login screen.
    history.push(URLS.CONTENT_HOME, { email: state?.email });
    setLoginModalVisible(true);

    // //  Handle this on modal
    // setResetPassPage({
    //   status: false,
    //   email: resetPassPage?.email,
    // });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = (e) => {
    e.preventDefault();
    if (loading || seconds > 0) {
      return;
    }

    if (!(email && OTP)) {
      notificationStore.triggerError("Please fill all the fields");
      return;
    }

    Auth.resendSignUp(email)
      .then(() => {
        setSeconds(59);
        notificationStore.triggerSuccess("OTP resent successfully");
      })
      .catch((err) => {
        notificationStore.triggerError(err?.message || "Request failed! Please try again later.");
      });
  };

  const confirmSignup = (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    if (!(email && OTP)) {
      notificationStore.triggerError("Please fill all the fields");
      return;
    }

    if (!emailValidation(email)) {
      notificationStore.triggerError("Please enter valid email");
      return;
    }

    setLoading(true);

    Auth.confirmSignUp(email, OTP)
      .then(() => {
        setLoading(false);
        notificationStore.triggerSuccess("Reset successful, Login with your new credentials.");
        backToLogin();
      })
      .catch((err) => {
        setLoading(false);
        setSeconds(0);
        notificationStore.triggerError(err?.message || JSON.stringify(err));
        return;
      });
  };

  const OTPPassForm = () => (
    <form className={styles.form}>
      <>
        <div>
          <AuthInput
            type="name"
            value={OTP}
            onChange={(e) => {
              setOTP(e.target.value);
            }}
            placeholder="OTP"
          />
        </div>
        <div className={styles.resendOTPWrapper}>
          <span className={`${styles.resendOTP} ${seconds > 0 ? styles.disabled : ""}`} clasonClick={resendOTP}>
            Resend Code {seconds > 0 ? `(${seconds}s)` : ""}
          </span>
        </div>
      </>

      <Row justify="space-between">
        <AuthButton className={styles.button} onClick={backToLogin}>
          Cancel
        </AuthButton>
        <AuthButton className={styles.button} onClick={confirmSignup} loading={loading} disabled={loading}>
          Confirm
        </AuthButton>
      </Row>
    </form>
  );

  return (
    <div className={styles.formContainer}>
      <Logo />
      <Row justify="center">
        <Col span={12}>
          <Row justify="center">
            <div>
              {/* <Row justify="center" style={{ fontSize: "25px", marginBottom: "120px" }}>
                Reset Password
              </Row> */}
              <Row justify="center" className={styles.tabContainer}>
                <span>OTP has been sent your emaill. Please enter it below to complete verification.</span>
              </Row>
              <Row justify="center">{OTPPassForm()}</Row>
            </div>
          </Row>
        </Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default OTP;
