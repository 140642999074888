import { types } from "mobx-state-tree";

export const loaderStore = types
  .model({
    isLoading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setLoadingTrue() {
      self.isLoading = true;
    },
    setLoadingFalse() {
      self.isLoading = false;
    },
  }))
  .views((self) => ({
    get loadingStatus() {
      return self.isLoading;
    },
  }));
