import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import { useMst } from "../../store";
import { validateEmail } from "../../utils/helper";
import { USER_TYPE } from "../../store/auth_store/models/authModel";
import Footer from "../component/footer/Footer";
import { AuthInput } from "../component/AuthInput";
import { AuthButton } from "../component/AuthButton";
import { AuthInputPassWord } from "../component/AuthInputPassWord";
import { URLS } from "../../routes/routeURL";

import styles from "./login.module.css";
import { Auth } from "aws-amplify";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Login = ({ setForgotpage, setResetPassPage }) => {
  const { auth, notificationStore, setLoginModalVisible } = useMst();
  const rootStore = useMst();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { browserStore } = rootStore;
  const [apiValidationError, setApiValidationError] = useState("");
  const history = useHistory();

  const confirmSignup = () => {
    setLoginModalVisible(true); // close auth modal
    setLoading(true);
    Auth.resendSignUp(email)
      .then(() => {
        history.push({
          pathname: "/reset-password",
          state: { email },
        });

        // //  Handle this on modal
        // setResetPassPage({
        //   status: true,
        //   email: email,
        // });
      })
      .catch((err) => {
        notificationStore.triggerError(err?.message || JSON.stringify(err));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      await auth.login(email, password, true);
      setLoading(false);
      if (browserStore.isMobileUser) {
        window.location = "/message/Streaming Guide";
      } else {
        notificationStore.triggerSuccess("Login Successful!");
        setTimeout(() => {
          window.location = URLS.CONTENT_HOME;
        }, 0);
      }
    } catch (e) {
      if (e?.error?.code === "UserNotConfirmedException") {
        confirmSignup();
        return;
      }
      setLoading(false);
      // notificationStore.triggerError(e?.message || "Login Error");
      setApiValidationError(e?.message || "Login Error");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      notificationStore.triggerError("Email is required");
      return;
    }
    if (!password) {
      notificationStore.triggerError("password is required");
      return;
    }

    if (!validateEmail(email)) {
      notificationStore.triggerError("Please Enter a valid email address");
      return;
    }

    handleLogin();
  };

  const gotoForgotPass = () => {
    setForgotpage(true);
    // history.push("/forgot-password");
  };

  const loginForm = () => (
    <form onSubmit={handleSubmit} className={styles.form}>
      <div>
        <div className={styles.authText}>Email</div>
        <AuthInput
          type="email"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            if (apiValidationError) {
              setApiValidationError("");
            }
          }}
          placeholder="example@gmail.com"
          autoFocus
          onPressEnter={handleSubmit}
        />
      </div>

      <div>
        <div className={styles.authText}>Password</div>
        <AuthInputPassWord
          type="password"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            if (apiValidationError) {
              setApiValidationError("");
            }
          }}
          placeholder="Enter Your Password"
          onPressEnter={handleSubmit}
        />
      </div>
      <div>
        <p className={styles.forgotPassword} onClick={gotoForgotPass}>
          Forgot Password ?
        </p>
      </div>
      <AuthButton onClick={handleSubmit} disabled={loading} loading={loading} className={styles.loginBtn}>
        Login
      </AuthButton>

      {/* {auth.folksUserType === USER_TYPE.GUEST ? ( */}
      <div className={styles.guestUserNotifierText}>You are a Guest User!</div>
      {/* ) : null} */}
      {apiValidationError && <div className={styles.apiValidationError}>{apiValidationError}</div>}
    </form>
  );

  return (
    <div className={styles.loginContainer}>
      <Row justify="center">
        <Col span={12}>
          <Row justify="center">
            <div>
              <div>{loginForm()}</div>
            </div>
          </Row>
        </Col>
      </Row>
      {/* <Footer /> */}
    </div>
  );
};

export default Login;
