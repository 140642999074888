import styled from "styled-components";

export const ModalFooterButton = styled.button`
  padding: 0.3vw 1.2vw;
  background-color: ${(props) => props.color};
  border-radius: 0.5vw;
  border: grey 2px solid;
  font-size: 1vw;
  font-weight: bold;
  color: white;
  &:hover {
    cursor: pointer;
    border: darkgrey 2px solid;
  }
`;

export const FeedBackBtnP = styled.button`
  padding: 0.3vw 1.6vw;
  background-color: ${(props) => (props.positive ? "#EB391B" : props.disabled ? "grey" : "#1A1A1C")};
  border-radius: 0.3vw;
  border: ${(props) => (props.positive ? "#EB391B" : "grey 1px solid")};
  font-size: 18px;
  font-weight: bold;
  color: white;
  &:hover {
    cursor: pointer;
    border: darkgrey 2px solid;
  }
`;
