import Amplify from "aws-amplify";
import React, { useState, useEffect } from "react";
import FrontLoader from "./components/front-loader/FrontLoader";
import { useMst } from "./store";
import { USER_TYPE } from "./store/auth_store/models/authModel";
import { isSupported } from "firebase/messaging";

import AppLayout from "./layout/Layout";
import { movies } from "./content-discovery/movies/movie";
import { shows } from "./content-discovery/shows/show";
import { imagePreloader } from "./utils/helper";

const App = () => {
  const rootStore = useMst();
  const { auth, browserStore } = rootStore;
  const [isCheckingAuth, setIsCheckingAuth] = useState(true);

  const [width, setWidth] = React.useState(window.innerWidth);
  const isMobile = width <= 768;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const authenticationCheck = async () => {
    try {
      await auth.isLoggedIn();
      checkAuthentication(auth?.folksUserType);
      return Promise.resolve({ success: true });
    } catch (e) {
      // Auth error/No auth found. Reset auth data then login as guest.
      await auth.logoutWithGuestReEntry();
      checkAuthentication(auth?.folksUserType);
      return Promise.reject({ success: false });
    }
  };

  const restrictedLoginForMobileUser = async () => {
    try {
      if (auth.folksUserType === USER_TYPE.GUEST || auth.folksUserType === USER_TYPE.NONE) {
        setIsCheckingAuth(false);
      } else {
        await auth.logout();
        auth.setUserType(USER_TYPE.NONE);
        setIsCheckingAuth(false);
        return Promise.resolve({ success: true });
      }
    } catch (e) {
      // Logout/data reset error, redirect to play/app store.
      window.location = `https://www.google.com`;
    }
  };

  // setter function for agent online Status
  useEffect(() => {
    window.addEventListener("online", browserStore.setHandleOnline);
    window.addEventListener("offline", browserStore.setHandleOffline);
    return () => {
      window.removeEventListener("online", browserStore.setHandleOnline);
      window.removeEventListener("offline", browserStore.setHandleOffline);
    };
  }, []);

  useEffect(() => {
    if (browserStore.isMobileUser || isMobile) {
      restrictedLoginForMobileUser();
    } else {
      authenticationCheck();
    }
  }, []);

  useEffect(() => {
    Amplify.configure({
      Auth: {
        region: process.env.REACT_APP_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
      },
    });
  }, []);

  // useEffect Firebase Support
  useEffect(() => {
    (async () => {
      const hasFirebaseMessagingSupport = await isSupported();
      if (!browserStore.isMobileUser && hasFirebaseMessagingSupport) {
        const { requestForToken } = await import("./api/cloud-notification/firebase");
        await requestForToken();
      }
    })();
  }, []);

  const preLoad = async (type) => {
    try {
      await rootStore.loadData();
      if (type === USER_TYPE.NORMAL) {
        rootStore.lazyLoad(); // This will always resolve.
      }
      setIsCheckingAuth(false);
    } catch (err) {
      setIsCheckingAuth(false);
    }
  };

  // TODO: Remove when TV Show & Movie banners API Integrated
  const preLoadImages = () => {
    try {
      const images = [];
      movies.forEach((movie) => images.push(movie?.banner_web_m));
      shows.forEach((movie) => images.push(movie?.banner_web_m));
      imagePreloader(images);
    } catch (error) {
      // do nothing
    }
  };

  const checkAuthentication = (userType) => {
    // Why?: Data load not required for the Unauthenticated User.
    if ([USER_TYPE.NORMAL, USER_TYPE.GUEST].includes(userType)) {
      preLoadImages();
      preLoad(userType);
    } else {
      setIsCheckingAuth(false);
    }
  };

  if (isCheckingAuth) {
    return <FrontLoader />;
  }
  return <AppLayout />;
};

export default App;
