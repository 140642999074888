import React, { useEffect, useState } from "react";
import styles from "./ContentCard.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useMst } from "../../store";
import { FaTrashAlt } from "react-icons/fa";
import { ContentCardImage } from "../../user-account/manage-library/components/content-card/styledComponent";
import { observer } from "mobx-react-lite";
import { getSubtitleString, safeObject } from "../../utils/helper";

const ContentCard = ({ mediaId, handleDelete }) => {
  const { auditStore, notificationStore } = useMst();
  const history = useHistory();

  const [cardData, setCardData] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(true);

  const getCardData = async () => {
    try {
      const res = await auditStore.getMoviesBasicDetails(mediaId);
      setImageLoaded(false);
      if (res?.success && res?.data) {
        setCardData(res.data);
      }
    } catch (e) {
      setImageLoaded(false);
    }
  };

  const handleCardClick = () => {
    const subtitleString = getSubtitleString(cardData?.subtitle) || {};
    const payload = safeObject(cardData) ? { ...cardData, subtitle: subtitleString } : {};

    if (cardData?.item_type && (cardData?.item_id || cardData?.external_ids?.imdb_id)) {
      if (cardData?.item_type === "ott" || cardData?.item_type === "show") {
        history.push({
          pathname: `/content/details/${cardData?.item_type}/${cardData?.external_ids?.imdb_id || mediaId}`,
          state: JSON.parse(JSON.stringify(payload)),
        });
      }
    } else {
      notificationStore.triggerInfo("Launch screen actions not supported");
    }
  };

  useEffect(() => {
    getCardData();
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(false);
  };

  return (
    <div
      className={styles.cardWrapper}
      onClick={() => {
        handleCardClick();
      }}
    >
      {imageLoaded && <div className={styles.skeleton}></div>}

      <ContentCardImage
        src={cardData?.image}
        alt="banner"
        display={imageLoaded === true ? "none" : "block"}
        onLoad={handleImageLoad}
        className={styles.cardImage}
      />
      <div
        className={styles.deleteButton}
        onClick={(e) => {
          e.stopPropagation();
          handleDelete(cardData?.external_ids?.imdb_id || mediaId);
        }}
      >
        <FaTrashAlt className={styles.deleteIcon} />
      </div>
    </div>
  );
};

export default observer(ContentCard);
