import { flow, types } from "mobx-state-tree";
import rewardsAPI from "../api/RewardsAPI";
import { EP_ROOT as __ } from "../utils/endpoints";

const RewardData = types.model({
  month: types.number,
  last_month_potential: types.boolean,
  months_number: types.number,
  last_month_Unearned_potential: types.boolean,
  year: types.number,
  category: types.boolean,
  teir: types.string,
  category_name: types.string,
  potential_earning: types.boolean,
  details: types.boolean,
  estimated_earning: types.boolean,
});

const RewardsStoreModel = {
  data: types.optional(types.array(RewardData), []),
};

const RewardsStoreActions = (self) => ({
  getTokenEarnings: flow(function* getTokenEarnings(month) {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_TOKEN_EARNINGS({ months: month }));
      return res;
    } catch (error) {
      return error;
    }
  }),
  getReferralEarnings: flow(function* getReferralEarnings() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_REFFERAL_EARNINGS());
      return res;
    } catch (error) {
      return error;
    }
  }),
  getChartDistribution: flow(function* getChartDistribution() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_CHART_DISTRIBUTION());
      return res;
    } catch (error) {
      return error;
    }
  }),
  getActions: flow(function* getActions() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_ACTIONS);
      return res;
    } catch (error) {
      return error;
    }
  }),
  getDashboardData: flow(function* getActions() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_DASHBOARD_DATA());
      return res;
    } catch (error) {
      return error;
    }
  }),
  getEarnRewards: flow(function* getEarnRewards() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_EARN_REWARD_ACTIONS);
      return res;
    } catch (error) {
      return error;
    }
  }),

  getLeaderboardData: flow(function* getLeaderboardData() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_LEADERBOARD_DATA);
      if (res?.status === 200) {
        return { success: true, data: res?.data?.data };
      } else {
        throw res;
      }
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.more_info || "Something went wrong in fetching leaderboardData.",
      };
    }
  }),

  getUserActivity: flow(function* getUserActivity() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_USER_ACTIVITY());
      if (res?.status === 200) {
        return { success: true, data: res.data };
      } else {
        throw res;
      }
    } catch (error) {
      return {
        success: false,
        error: error?.response?.data?.more_info || "Something went wrong in fetching user activity.",
      };
    }
  }),

  getUserAchievements: flow(function* getUserAchievements() {
    try {
      const res = yield rewardsAPI.get(__.REWARDS_SERVICE.GET_USER_ACHIEVEMENTS);
      if (res?.status === 200) {
        return Promise.resolve({ success: true, data: res?.data });
      } else {
        throw new Error("Something went wrong while fetching data");
      }
    } catch (error) {
      return Promise.reject({
        success: false,
        message: error.message || "Something went wrong while fetching data!",
      });
    }
  }),
});

const RewardsStoreViews = () => ({});

export const rewardsStore = types
  .model("RewardsStore", RewardsStoreModel)
  .actions(RewardsStoreActions)
  .views(RewardsStoreViews);
