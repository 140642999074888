import React, { useState } from "react";
import PropTypes from "prop-types";
import { parseSize, getInitialAlphabet } from "./utils";

const FolksAvatar = (props) => {
  const [isBadImage, setIsBadImage] = useState(false);
  const { src, name, bgColor, size, round } = props;

  // parsing and type checking of round value
  let _round = round;
  if (typeof round === "string") _round = parseSize(round).str;

  // parsing of size value
  const _size = parseSize(size).str;

  //   getting initials alphabet from the name
  const initialAlphabet = getInitialAlphabet(name);

  const containerStyle = {
    maxWidth: "100%",
    width: _size,
    height: _size,
  };

  const textContainerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: _size,
    height: _size,
    color: "#fff",
    background: bgColor,
    fontSize: `calc(${_size} * .40 )`,
    borderRadius: _round === true ? "100%" : _round,
  };

  const imageStyle = {
    maxWidth: "100%",
    width: _size,
    height: _size,
    borderRadius: _round === true ? "100%" : _round,
  };

  return (
    <div style={containerStyle} title={name}>
      {src && !isBadImage ? (
        <img
          width={_size}
          height={_size}
          style={imageStyle}
          src={src}
          alt={name}
          title={name}
          onError={() => setIsBadImage(!isBadImage)}
        />
      ) : (
        <div style={textContainerStyle}>
          <div>{initialAlphabet}</div>
        </div>
      )}
    </div>
  );
};

FolksAvatar.propTypes = {
  src: PropTypes.string,
  name: PropTypes.string,
  bgColor: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  round: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.number]),
};

FolksAvatar.defaultProps = {
  src: "",
  name: "Anonymous",
  bgColor: "#FC5252",
  size: "6rem",
  round: false,
};

export default FolksAvatar;
