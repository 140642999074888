import { types, getParent, detach } from "mobx-state-tree";
import { deProxyJSONData } from "../../../utils/helper";

const imageModel = types.model("image_item_model", {
  url: types.maybeNull(types.string),
  width: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
});

const thumbImageAssetsModel = types.model("thumb_image_assets_model", {
  h: types.maybe(imageModel),
  m: types.maybe(imageModel),
  l: types.maybe(imageModel),
  o: types.maybe(imageModel),
});

const posterImageAssetsModel = types.model("poster_image_assets_model", {
  h: types.maybe(imageModel),
  m: types.maybe(imageModel),
  o: types.maybe(imageModel),
});

const thumbnailAssetModel = types.model("thumbnail_asset_model", {
  image_assets: types.maybe(thumbImageAssetsModel),
});

const posterAssetModel = types.model("poster_asset_model", {
  image_assets: types.maybe(posterImageAssetsModel),
});

const mediaItemModel = types
  .model("media_item_model", {
    item_type: types.maybe(types.string), // Imp: media-item, Imp: genre-item
    item_id: types.maybe(types.string), // Imp: media-item, Imp: genre-item
    image: types.string, // Imp: media-item, Imp: genre-item
    title: types.maybeNull(types.string), // Imp: media-item, Opt: genre-item
    subtitle: types.maybeNull(types.string), // Imp: media-item, Opt: genre-item
    image_hd: types.maybeNull(types.string), // Opt: media-item, Opt: genre-item
    image_blurhash: types.maybeNull(types.string), // Opt: media-item, Opt: genre-item
    description: types.optional(types.maybeNull(types.string)), // Imp: media-item, Opt: genre-item
    video: types.maybeNull(types.string), // Opt: media-item, Opt: genre-item
    item_id_source: types.maybe(types.string), // Opt: media-item, Opt: genre-item
    feedback_allowed: types.maybe(types.boolean), // Opt: media-item, Opt: genre-item && custom parameter, flag for (X) button.
    external_ids: types.maybeNull(
      types.model("external id model", {
        imdb_id: types.maybeNull(types.string), // Imp: media-item, Opt: genre-item
      })
    ),
    actions: types.maybeNull(
      types.model("actions_model", {
        action_list: types.optional(types.array(types.string), []),
        content_ids: types.maybeNull(types.string),
      })
    ),
    thumbnail_asset: types.maybe(thumbnailAssetModel),
    poster_assets: types.maybe(posterAssetModel),
    meta: types.maybe(types.frozen()),
  })
  .actions((self) => ({
    remove() {
      getParent(self, 2).removeMediaItem(self);
    },
  }));

export const mediaRowModel = types
  .model("media_row_model", {
    meta_title: types.maybe(types.string), // Opt. Custom field, shows meta title if available.
    is_title_clickable: types.maybe(types.boolean), // Opt. Custom field, conveys if title is clickable.
    content_type: types.maybe(types.number), //Imp.
    app_filter: types.maybe(types.boolean), // Imp.
    display_config: types.maybe(
      types.model({
        // Imp.
        width: types.maybe(types.number), // Imp.
        height: types.maybe(types.number), // Imp.
      })
    ),
    title: types.string, // Imp. // Pagination parameter. (search)
    pagination_module: types.maybe(types.string), // Imp  // Pagination parameter. Custom: used to identify which module will be used to paginate. (search/home)
    has_pagination: types.maybe(types.boolean), // Imp      // Pagination parameter. Custom: used to identify if this row is paginated.
    type: types.maybeNull(types.string), // Imp.  // Pagination parameter. (home)
    position: types.maybe(types.number), // Imp.  // Pagination parameter. Not getting for genre/search feed. (home)
    limit: types.maybe(types.number), // Imp.  // Pagination parameter. Not getting for genre/search feed.
    count: types.maybe(types.number), // Imp.  // Pagination parameter. Not getting for genre/search feed.
    skip: types.maybe(types.number), // Imp.  // Pagination parameter. Not getting for genre/search feed.

    media_items: types.optional(types.array(mediaItemModel), []), // Imp.
    cross_button_visiblity: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    // obsolete
    removeMediaItem(itemNode) {
      try {
        detach(itemNode);
        // On remove tiles, fetching data if media tiles is less than 10
        if (self.media_items?.length < 10 && self.type && self.position) {
          getParent(self, 2)?.getPaginatedFolksDeeplinks(self.type, self.position);
        }
      } catch (error) {}
    },
    addMediaItems(mediaItemArr) {
      self.media_items = [...self.media_items, ...mediaItemArr];
      // update skip (limit/count are const.)
      if (typeof self.skip === "number") {
        self.skip = self.skip + self.limit;
      }
      self.setLoading(false);
    },
    setLoading(status) {
      self.isLoading = !!status;
    },
  }));

export const metaTitleModel = types.model("meta_title_model", {
  type: types.string, // Imp.
  title: types.string, // Imp.
  position: types.number, // Imp.
});

export const bannerModel = types.model("banner_model", {
  action: types.model({
    action_id: types.string, // Imp.
    action_type: types.string, // Imp.
  }),
  image_l: types.string, // Imp.
  thumbnail: types.optional(types.string, ""),
  intended_application: types.string, // Imp.
  meta: types.maybe(types.frozen()),
  title: types.string,
  subtitle: types.optional(types.string, ""),
  description: types.string,
  item_type: types.optional(types.string, ""),
  item_id: types.optional(types.string, ""),
  banner_web_m: types.optional(types.string, ""),
});

const slingProviderModel = types.model("sling_provider_model", {
  provider_id: types.number,
  name: types.string,
  provider_deeplink: types.string,
  logo: types.string,
  channels_id: types.model({
    fubo_channel_id: types.maybeNull(types.string),
    sling_oid: types.maybeNull(types.string),
    sling_bid: types.maybeNull(types.string),
    sling_obid: types.maybeNull(types.string),
    // orange_pkg_id: types.string,
    // blue_pkg_id: types.number,
    // ob_pkg_id: types.string
  }),
});

const slingTitleModel = types
  .model("sling_title_model", {
    id: types.number,
    start_datetime: types.string, // program start time
    end_datetime: types.string, // program end time
    program_name: types.string, // program name
    program_image: types.string, // Program Image
    // timezone: types.string,
    // imdb_id: types.null,
    channel_group_name: types.string, // Channel name
    channel_group_id: types.string, // Channel id (for redirection url)
    // created_at: types.string,
    channel_logo: types.string, // Channel logo
    category: types.string, // Program type: [news, sports, movie, show]
    providers: types.optional(types.array(slingProviderModel), []),
  })
  .actions((self) => ({
    moveToLast() {
      getParent(self, 2).moveTitleToLast(self.id, self);
    },
  }));

export const slingRowModel = types
  .model("sling_row_model", {
    row_title: types.string,
    row_type: types.string,
    isLiveRow: types.maybe(types.boolean), // Custom key, for Live row.
    titles: types.optional(types.array(slingTitleModel), []),
  })
  .actions((self) => ({
    removeMediaItem(itemNode) {
      detach(itemNode);
    },
    replaceTitles(titleArr) {
      self.titles = titleArr;
    },
    moveTitleToLast(titleId, node) {
      if (self.titles?.length <= 3) {
        self.removeMediaItem(node);
      } else {
        const titleIdx = self.titles.findIndex((show) => show.id === titleId);
        if (titleIdx === -1) return;
        self.titles = deProxyJSONData([
          ...self.titles.slice(0, titleIdx),
          ...self.titles.slice(titleIdx + 1),
          self.titles[titleIdx],
        ]);
      }
    },
  }));

export const initialFeedsStoreState = {
  folksBannerData: [],
  selectedEmotionObj: null,
  emotionDeeplinks: {
    loading: false,
    success: false,
    message: "",
    data: undefined,
  },

  genreDeeplinks: [],
  folksDeeplinks: [],
  folksDeeplinkMetaTitles: [],

  viewLevelCount: 0,
  expandedData: [],

  filterRowType: "",

  slingLiveRow: undefined,
  slingUpcoRow: undefined,
  slingAppIcon: "",
};
