import { Input, Modal, Typography, Radio } from "antd";
import React from "react";
import styled from "styled-components";
import { IoMdCloseCircleOutline } from "react-icons/io";

const { Paragraph } = Typography;

export const PlaylistInput = (props) => {
  return (
    <InputContainer>
      <Input {...props} />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  .ant-input {
    background: white;
    padding: 6px px;
    border: 0px;
    margin-bottom: 5px;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 1px;
    color: black;

    &:hover,
    &:focus {
      box-shadow: none;
    }
    &::placeholder {
      color: grey; /* Change this to your desired grey color */
    }
  }
`;

export const PlaylistParagraph = styled(Paragraph)`
  &&& {
    margin: 0px;
    color: white;
    margin-left: 14px;
    max-width: 280px;
  }
`;

export const CustomPlaylistModal = styled(Modal).attrs((props) => ({
  footer: null,
  closeIcon: <IoMdCloseCircleOutline style={{ color: "white", fontSize: "2vw" }} />,
  centered: true,
}))`
  &&& {
    .ant-modal-body {
      padding: 2vw;
    }
    .ant-modal-content {
      width: 100%;
      background-color: #1a1a1c;
      border-radius: 1vw;
      margin-top: 2.5vw;
    }
  }
`;

export const CustomRadio = styled(Radio)`
  .ant-radio-inner {
    border-color: white;
  }
  .ant-radio-inner::after {
    background-color: #eb391b;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #eb391b;
  }
  .ant-radio-checked .ant-radio-inner::after {
    background-color: #eb391b;
  }
`;
