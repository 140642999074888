import React, { useState, useEffect, useMemo } from "react";
import styles from "./RecentActivity.module.css";
import ActivityCard from "./ActivityCard";
import { useMst } from "../../../store";
import FolksLoader from "../../../components/folks-loader/FolksLoader";
import RightContainer from "../../common-components/RightContainer";
import FolksEmptyMessage from "../../../components/folks-empty-message/FolksEmptyMessage";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const LIMIT = 5; // API LIMIT due to inconsistency of result count
const ITEMS_PER_PAGE = 6; // number of activities to be shown per page

const RecentActivity = () => {
  const { profileStore, notificationStore } = useMst();
  const history = useHistory();
  const [activities, setActivities] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [pageNum, setPageNum] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasReachedEnd, setHasReachedEnd] = useState(false);

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentItems = activities.slice(indexOfFirstItem, indexOfLastItem);
  const isPrevDisabled = currentPage === 1 || isLoading;

  // determine to call API if we don't have enough data to load on next page
  const shouldCallAPI = () => !(indexOfLastItem + ITEMS_PER_PAGE <= activities.length);

  // disabling next button if there is no more item to load
  const isNextDisabled = useMemo(() => {
    const moreItemsToLoad = activities.length - indexOfLastItem > 0;
    return !moreItemsToLoad || isLoading || (!hasNextPage && indexOfLastItem === activities.length);
  }, [activities, hasNextPage, currentPage, isLoading]);

  useEffect(() => {
    fetchActivity(0);
  }, []);

  const fetchActivity = async (skip, limit = LIMIT) => {
    if (skip < pageNum) return;

    setIsLoading(true);
    try {
      const res = await profileStore.getUserContentReviews(limit, skip);
      setIsLoading(false);
      if (res?.data?.length) {
        const results = res?.data?.filter((item) => item?.content_source !== "sensara");

        setActivities((prevActivities) => [...prevActivities, ...results]);
        setPageNum(skip + limit);
        if (skip > 0 && activities?.length - indexOfLastItem >= 0) {
          setCurrentPage((prev) => prev + 1);
        }
        setHasNextPage(res?.data?.length > ITEMS_PER_PAGE ? true : false);
      } else {
        handleNoMoreData(skip);
      }
    } catch (error) {
      setIsLoading(false);
      setActivities([]);
    }
  };

  const handleNoMoreData = (skip) => {
    if (skip > 0 && activities?.length - indexOfLastItem > 0) {
      setCurrentPage((prev) => prev + 1);
    } else {
      notificationStore.triggerInfo("You've reached the end of the list.", {
        position: "bottom-center",
      });
    }
    setHasNextPage(false);
    setHasReachedEnd(true);
  };

  const onNext = (e) => {
    e.preventDefault();
    if (isLoading) return;

    //  fetching data one page before otherwise move to the next page
    if (shouldCallAPI() && !hasReachedEnd) {
      fetchActivity(pageNum);
    } else {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const onPrev = (e) => {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  const handleCardClick = (content_type, content_id) => {
    if (content_id && content_type) {
      if (content_type === "ott" || content_type === "show") {
        history.push({
          pathname: `/content/details/${content_type}/${content_id}`,
          state: null,
        });
      }
    } else {
      notificationStore.triggerInfo("Launch screen actions not supported");
    }
  };

  return (
    <RightContainer>
      <section className={styles.recentActivityContainer}>
        <h2 className={styles.title}>Recent Activity</h2>
        {/* for testing it can be enabled  */}
        {/* <h2 className={styles.title}>
        currentPage: {currentPage} &nbsp; indexOfFirstItem: {indexOfFirstItem} &nbsp; indexOfLastItem: {indexOfLastItem}{" "}
        &nbsp; pageNum: {pageNum} &nbsp; Activities: {activities.length} &nbsp; hasNextPage: {String(hasNextPage)}
      </h2> */}
        <div className={styles.activityGridWrapper}>
          <div className={styles.activityGrid}>
            <>
              {isLoading ? <FolksLoader /> : null}
              {!activities?.length && !isLoading ? <FolksEmptyMessage /> : null}
            </>

            {!isLoading &&
              currentItems?.map((activity, index) => (
                <div key={`${currentPage}-${index}`} className={styles.activityColumn}>
                  <ActivityCard
                    {...activity}
                    userName={profileStore.userName}
                    userId={profileStore.userId}
                    handleCardClick={handleCardClick}
                  />
                </div>
              ))}
          </div>
          <div className={styles.navigationButtons}>
            <button
              className={`${styles.previousButton} ${isPrevDisabled ? styles.disable : ""}`}
              disabled={isPrevDisabled}
              onClick={onPrev}
            >
              Previous
            </button>
            <button
              className={`${styles.nextButton} ${isNextDisabled ? styles.disable : ""}`}
              disabled={isNextDisabled}
              onClick={onNext}
            >
              Next
            </button>
          </div>
        </div>
      </section>
    </RightContainer>
  );
};

export default RecentActivity;
