import { Input } from "antd";
import styled from "styled-components";

export const AuthInput = styled(Input)`
  &&& {
    background-color: #000000;
    height: 2.5vw;
    font-size: 1.1vw;
    color: #ffffff;
    border-radius: 4px;
    margin: 0.6vw 0;
    padding: 0.5vw;

    ::placeholder {
      color: #b1b1b1;
      font-size: 1vw;
    }
  }
  @media (max-width: 480px) {
    &&& {
      height: 10vw;
      font-size: 3vw;
      border-radius: 5px;
      margin-bottom: 10px;
      padding-left: 8px;
    }
  }
`;
