import { Row, Col, Form } from "antd";
import { Auth } from "aws-amplify";
import React, { useRef, useState } from "react";
import { validateEmail, validatePasswordText } from "../../utils/helper";
import { AuthButton } from "../component/AuthButton";
import { AuthInput } from "../component/AuthInput";
import { AuthInputPassWord } from "../component/AuthInputPassWord";
import styles from "./register.module.css";
import { AuthCheckBox } from "../component/AuthCheckBox";
import { useMst } from "../../store";
import { Link } from "react-router-dom";

const Register = ({ history, setTermsOfUse, waitingForOTP, setWaitingForOTP, setPrivacyPolicy }) => {
  const { referralStore, notificationStore, setAuthActiveTabIndex } = useMst();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [referralCode, setReferralCode] = useState("");
  // const [waitingForOTP, setWaitingForOTP] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [otpLoader, setOtpLoader] = useState(false);
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const [apiValidationError, setApiValidationError] = useState("");
  const [fieldValidationError, setfieldValidationError] = useState("");
  const inputRefs = useRef([]);
  let OTP = otp?.join("");

  const signUp = async (e) => {
    e.preventDefault();
    // Step:1 Basic Field check.
    if (!name.trim()) {
      // notificationStore.triggerError("Name is required");
      setfieldValidationError("Name is required");
      return;
    }
    if (!/^[a-zA-Z\s]+$/.test(name)) {
      // notificationStore.triggerError("Name should contains only alphabetic characters");
      setfieldValidationError("Name should contains only alphabetic characters");
      return;
    }
    if (!email) {
      // notificationStore.triggerError("Email is required");
      setfieldValidationError("Email is required");
      return;
    }
    if (!validateEmail(email)) {
      // notificationStore.triggerError("Please Enter a valid email address");
      setfieldValidationError("Please Enter a valid email address");
      return;
    }

    if (!password) {
      // notificationStore.triggerError("Password is required");
      setfieldValidationError("Password is required");
      return;
    }

    if (!validatePasswordText(password).status) {
      // notificationStore.triggerError(validatePasswordText(password).message);
      setfieldValidationError(validatePasswordText(password).message);
      return;
    }

    if (password !== confirmPassword) {
      // notificationStore.triggerError("Password does not match");
      setfieldValidationError("Password does not match");
      return;
    }

    if (!checkBox) {
      notificationStore.triggerError(
        "Folksmedia requires cookie consent for accesing it's content. Please accept the cookie policy to proceed further."
      );
      return;
    }

    // Step2: Referral code check. Blocks if invalid code provided.
    if (referralCode) {
      setLoading(true);
      try {
        await referralStore.validateReferralCode(referralCode);
        setLoading(false);
      } catch (e) {
        setLoading(false);
        return;
      }
    }

    // Step3: Finally, call to Cognito Sign up funciton.
    try {
      setLoading(true);
      await Auth.signUp({
        username: email,
        password: password,
        attributes: {
          email: email,
          name: name,
          "custom:referral_code": referralCode,
        },
      });
      setWaitingForOTP(true);
      notificationStore.triggerSuccess("A secure OTP is delivered to your registered mail address, Please check.");
      setLoading(false);
    } catch (error) {
      notificationStore.triggerError(error.message);
      setLoading(false);
    }
  };

  const confirmSignUp = async (e) => {
    e.preventDefault();
    setLoading(true);
    await Auth.confirmSignUp(email, OTP)
      .then(() => {
        setLoading(false);
        setWaitingForOTP(false);
        notificationStore.triggerSuccess("Sign up successful, Login with your credentials.");
        setAuthActiveTabIndex("login"); // set login active tab
      })
      .catch((err) => {
        setLoading(false);
        // notificationStore.triggerError(err.message);
        setApiValidationError(err.message);
      });
  };

  const resendCode = async () => {
    setOtpLoader(true);
    await Auth.resendSignUp(email)
      .then(() => {
        notificationStore.triggerSuccess("OTP resent successfully");
        setOtpLoader(false);
      })
      .catch((e) => {
        notificationStore.triggerError(e.message);
        setOtpLoader(false);
      });
  };

  const handleCheckBox = () => {
    setCheckBox(!checkBox);
  };

  const handleInputChange = (index, value, e) => {
    if (Number(value) || Number(value) === 0 || e?.key === "Backspace" || value === "") {
      setApiValidationError(null);
      const newOtp = [...otp];
      newOtp[index] = value;

      if (value !== "" && index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }

      setOtp(newOtp);
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === "Backspace" && index > 0 && otp[index] === "") {
      inputRefs.current[index - 1].focus();
    }
  };

  const RegisterForm = () => {
    if (waitingForOTP) {
      return (
        <form id="otp" className={styles.form}>
          <div className={styles.otpValidContainer}>
            <div className={styles.otpContainerHeading}>OTP Verification</div>
            <div>
              <div>
                <div className={styles.otpContainerText}>
                  Enter 6 digit code sent to <br /> {email}
                </div>
                <div className={styles.otpBoxContainer}>
                  {otp?.map((digit, index) => (
                    <input
                      key={index}
                      id={`otp-input-${index}`}
                      type="text"
                      maxLength="1"
                      style={{
                        border: `1px solid ${apiValidationError ? "red" : "#DBDADA"}`,
                      }}
                      className={styles.otpField}
                      value={digit}
                      onChange={(e) => handleInputChange(index, e.target.value, e)}
                      onKeyDown={(e) => handleKeyDown(index, e)}
                      ref={(inputRef) => (inputRefs.current[index] = inputRef)}
                    />
                  ))}
                </div>
              </div>
              <div className={styles.btnContainer}>
                <AuthButton
                  className={styles.submitbutton}
                  onClick={confirmSignUp}
                  disabled={loading || !OTP}
                  loading={loading}
                >
                  Submit
                </AuthButton>
                {!OTP && (
                  <AuthButton
                    className={styles.button}
                    onClick={() => {
                      setWaitingForOTP(false);
                    }}
                    disabled={otpLoader}
                  >
                    Back
                  </AuthButton>
                )}
              </div>
              <div>
                Didn't receive OTP?{" "}
                <a onClick={resendCode} style={{ color: "#ea3a1b" }}>
                  {"  "}
                  Resend
                </a>
              </div>
              <div>{apiValidationError && <div className={styles.apiValidationError}>{apiValidationError}</div>}</div>
            </div>
          </div>
        </form>
      );
    } else {
      return (
        <Form id="signup" className={styles.form} autoComplete="off">
          <div>
            <div className={styles.authText}>Full Name</div>
            <AuthInput
              type="name"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                setfieldValidationError("");
              }}
              placeholder="Name"
              autoFocus
              onPressEnter={signUp}
            />
          </div>
          <Form.Item name="email">
            <div className={styles.authText}>Email</div>
            <AuthInput
              type="email"
              name="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                setfieldValidationError("");
              }}
              placeholder="example@gmail.com"
              onPressEnter={signUp}
              autoComplete="off"
              id="email"
            />
          </Form.Item>
          <Form.Item name="password">
            <div className={styles.authText}>Password</div>
            <AuthInputPassWord
              type="password"
              name="password"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
                setfieldValidationError("");
              }}
              placeholder="Password"
              onPressEnter={signUp}
              autoComplete="new-password"
            />
          </Form.Item>
          <Form.Item name="confirm-password">
            <div className={styles.authText}>Confirm Password</div>
            <AuthInputPassWord
              type="password"
              name="confirm-password"
              value={confirmPassword}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setfieldValidationError("");
              }}
              placeholder="Confirm Password"
              onPressEnter={signUp}
              autoComplete="new-password"
              id="confirm-password"
            />
          </Form.Item>
          {/* Shows referral input box only when a valid referral is present. */}
          {(!waitingForOTP || referralCode) && (
            <div>
              <div className={styles.authText}>Referral Code (Optional)</div>
              <AuthInput
                type="text"
                value={referralCode}
                onChange={(e) => {
                  setReferralCode(e.target.value.trim());
                  setfieldValidationError("");
                }}
                placeholder="Referral Code (Optional)"
                onPressEnter={signUp}
              />
            </div>
          )}
          <div style={{ marginTop: "-15px" }}>
            <AuthCheckBox checked={checkBox} onChange={handleCheckBox}>
              <span style={{ marginLeft: "0.3vw" }}>I agree to the</span>{" "}
              <span className="checkbox-link">
                <Link to="/terms-of-use" target="_blank" rel="noopener noreferrer">
                  terms of use
                </Link>
                <span> and</span> {""}
                <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                  privacy policy
                </Link>
              </span>
              {/* terms of use or privacy policy open on modal */}
              {/* <span className="checkbox-link">
                <span
                  style={{ color: "red" }}
                  onClick={() => {
                    setTermsOfUse(true);
                  }}
                >
                  terms of use
                </span>
                <span> and</span> {""}
                <span
                  style={{ color: "red" }}
                  onClick={() => {
                    setPrivacyPolicy(true);
                  }}
                >
                  privacy policy
                </span>
              </span> */}
            </AuthCheckBox>
          </div>
          <AuthButton
            onClick={signUp}
            loading={loading}
            disabled={loading || !checkBox}
            className={styles.registerBtn}
            style={{ opacity: !checkBox ? 0.5 : 1 }}
          >
            Sign up
          </AuthButton>

          <div>{fieldValidationError && <div className={styles.apiValidationError}>{fieldValidationError}</div>}</div>
        </Form>
      );
    }
  };

  return (
    <div className={styles.formContainer}>
      <div>
        <Row justify="center">
          <Col span={12}>
            <Row justify="center">
              <div>
                <div>{RegisterForm()}</div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Register;
