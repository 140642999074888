import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { URLS } from "../../../routes/routeURL";
import styles from "./tab.module.css";
import { useMst } from "../../../store";

const Tab = (props) => {
  const { authActiveTabIndex, setAuthActiveTabIndex } = useMst();

  const tabData = [
    {
      item_type: "tab",
      item_id: "login",
      title: "Login",
      // path: URLS.LOGIN,
    },
    {
      item_type: "tab",
      item_id: "register",
      title: "Signup",
      // path: URLS.REGISTER,
    },
  ];

  const handleTabClick = (item_id) => {
    setAuthActiveTabIndex(item_id);
  };

  return (
    <div className={styles.tabContainer}>
      {tabData?.map((item) => {
        return (
          <div
            key={item.item_id}
            className={`${styles.menu} ${item.item_id === authActiveTabIndex && styles.selected}`}
          >
            <div
              className={styles.tabLink}
              onClick={() => {
                handleTabClick(item.item_id);
              }}
            >
              {item.title}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Tab;
