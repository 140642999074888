import { getParent, types, flow } from "mobx-state-tree";
import { toast } from "react-toastify";
import { getCookie } from "../api/cookie/cookie";
import NotificationApi from "../api/NotificationApi";
import { CO_KEY_FIREBASE_DEVICE_TOKEN } from "../utils/constants";
import { EP_ROOT as __ } from "../utils/endpoints";

const notificationStoreModel = {};

const notificationStoreActions = (self) => ({
  triggerInfo(message, options) {
    getParent(self).browserStore.isOnline && toast.info(message, options ? options : undefined);
  },
  triggerSuccess(message, options) {
    getParent(self).browserStore.isOnline && toast.success(message, options ? options : undefined);
  },
  triggerWarning(message, options) {
    getParent(self).browserStore.isOnline && toast.warn(message, options ? options : undefined);
  },
  triggerError(message, options) {
    getParent(self).browserStore.isOnline && toast.error(message, options ? options : undefined);
  },
  triggerDefault(message, options) {
    getParent(self).browserStore.isOnline && toast(message, options ? options : undefined);
  },

  triggerOfflineNotification() {
    toast.warn("You are offline kindly connect to the internet", {
      autoClose: false,
      closeButton: false,
      closeOnClick: false,
    });
  },

  triggerOnlineNotification() {
    toast.dismiss();
    setTimeout(() => {
      toast.success("You are online", { autoClose: 2000 });
    }, 500);
  },

  sendDeviceToken: flow(function* sendDeviceToken({ is_delete }) {
    const firebase_token = getCookie(CO_KEY_FIREBASE_DEVICE_TOKEN);
    if (!firebase_token) return Promise.reject({ success: false, error: "firebase token not found." });

    const payload = {
      fb_registration_token: firebase_token,
      device_type: "web",
      is_delete: is_delete,
    };

    try {
      const res = yield NotificationApi.post(__.NOTIFICATION_SERVICE.RECEIVE_REGISTRATION_TOKEN, payload);
      return Promise.resolve({ success: true, msg: res?.data?.message });
    } catch (error) {
      return Promise.reject({ success: false, error: error });
    }
  }),
});

const notificationStoreViews = (self) => ({});

export const notificationStore = types
  .model("Notification Store", notificationStoreModel)
  .actions(notificationStoreActions)
  .views(notificationStoreViews);
