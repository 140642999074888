import React from "react";

const RightContainer = ({ children }) => {
  return (
    <div
      style={{
        height: "90vh",
        width: "94%",
        overflow: "hidden",
        background: "#1E1E1E",
        margin: "5.5vw 2vw ",
        padding: "2.5vw",
        borderRadius: "4px",
        border: "1px solid #3F3F3F",
        position: "relative",
        paddingBottom: "5px",
        overflowY: "scroll",
        scrollbarWidth: "none",
      }}
    >
      <div
        style={{
          backgroundImage: `
            linear-gradient(to right, #3F3F3F 1px, transparent 1px),
            linear-gradient(to bottom, #3F3F3F 1px, transparent 1px)
          `,
          backgroundSize: "30px 30px",
          position: "absolute",
          top: "-35%",
          right: "-10%",
          transform: "rotate(23deg)",
          width: "50%",
          height: "50%",
          color: "#ffff",
          pointerEvents: "none",
          opacity: "0.7",
        }}
      ></div>
      <div style={{ position: "relative", zIndex: "1", height: "100%" }}>{children}</div>
    </div>
  );
};

export default RightContainer;
