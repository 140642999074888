import React from "react";
import styles from "./RecentActivity.module.css";
import { FaEye, FaEyeSlash, FaHeart, FaHeartBroken } from "react-icons/fa";
import moment from "moment-timezone";
import { Tooltip } from "antd";

const ActivityType = {
  USER_REVIEW: "user_review",
  USER_EMOTION: "user_emotion",
  USER_SEEN: "user_seen",
  USER_LIKE: "user_like",
};

const TITLES = {
  user_review: ` posted a review on this movie`,
  user_emotion: ` reacted on this title`,
  user_seen: {
    true: ` marked this title as watched`,
    false: ` marked this title as not watched`,
  },
  user_rating: ` rated this title`,
  user_like: {
    true: ` liked this title`,
    false: ` disliked this title`,
  },
};

const formComponent = ({ activity_type, userData, title, activity, userName, userId, user_id }) => {
  let text = "";
  let IconComponent = null;

  const subject = user_id === userId ? "You" : userName;
  // eslint-disable-next-line default-case
  switch (activity_type) {
    case ActivityType.USER_REVIEW:
      text = `${subject} posted a review on this movie`;
      break;
    case ActivityType.USER_EMOTION:
      text = `${subject} reacted to this movie with ${activity?.emoji}`;
      // IconComponent = () => <span className={styles.activityIcon}>{activity?.emoji}</span>;
      break;
    case ActivityType.USER_SEEN:
      text = `${subject} marked this movie as ${activity?.seen ? "watched " : "not watched "}`;
      IconComponent = () =>
        activity?.seen ? <FaEye color="#FFBC28" size={24} /> : <FaEyeSlash color="#FFBC28" size={24} />;
      break;
    case ActivityType.USER_LIKE:
      text = `${subject} ${activity?.is_liked ? "liked " : "disliked"} this movie`;
      IconComponent = () =>
        activity?.is_liked ? <FaHeart color="#FA3958" size={24} /> : <FaHeartBroken color="#FA3958" size={24} />;
      break;
    default:
      text = "";
      break;
  }

  return {
    text,
    IconComponent,
  };
};

const ActivityCard = ({
  activity_type,
  title,
  activity,
  timestamp,
  userName,
  image_hd,
  user_id,
  userId,
  content_type,
  content_id,
  handleCardClick,
}) => {
  const onHandleCardClick = (e) => {
    e.preventDefault();
    handleCardClick(content_type, content_id);
  };
  const { text, IconComponent } = formComponent({ activity_type, title, activity, userName, userId, user_id });
  return (
    <div className={styles.activityCard} onClick={onHandleCardClick}>
      <div className={styles.activityHeader}>
        <Tooltip title={text}>
          <p className={styles.ellipsis}>{text}</p>
        </Tooltip>
        {IconComponent ? <IconComponent /> : null}
      </div>
      <img src={image_hd} alt="" className={styles.activityImage} />
      <div className={styles.cardBotttomSection}>
        <Tooltip title={title}>
          <p className={`${styles.ellipsis} ${styles.cardTitle}`}>{title}</p>
        </Tooltip>
        <p className={styles.activityTimestamp}>{moment(timestamp).fromNow()}</p>
      </div>
    </div>
  );
};

export default ActivityCard;
