import { Input } from "antd";
import styled from "styled-components";

export const AuthInputPassWord = (props) => {
  return (
    <InputPasswordContainer>
      <Input.Password {...props} />
    </InputPasswordContainer>
  );
};

const InputPasswordContainer = styled.div`
  .ant-input-password > .ant-input {
    color: #ffffff;
    font-size: 1.1vw;
    padding: 0 5px;
    ::-webkit-input-placeholder {
      font-size: 1vw;
      color: #b1b1b1;
    }
      &&& > input ::placeholder {
      color: #b1b1b1;
      font-size: 1vw;
      }
  }
  }
  .ant-input-affix-wrapper {
    background-color: #000;
    border-radius: 4px;
    height: 2.5vw;
    padding: 0;
    margin: 0.6vw 0;
  }
  .ant-input-suffix {
    margin: 5px;
  }
  .anticon {
    color: #ffff;
    font-size: 1.1vw;
    &:hover,
    &:focus {
      color: #ffff;
    }
  }
  @media (max-width: 480px) {
    .ant-input-affix-wrapper {
      height: 10vw;
      border-radius: 5px;
      margin-bottom: 10px;
      padding-left: 8px;
    }
    .anticon {
      color: #ffff;
      font-size: 3.5vw;
      padding-right: 8px;
      &:hover,
      &:focus {
        color: #ffff;
      }
    }
    .ant-input-password > .ant-input {
      color: #000;
      font-size: 3vw;
      ::-webkit-input-placeholder {
        font-size: 3vw;
        color: #b1b1b1;
      }
    }
  }
`;
