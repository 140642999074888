import { flow, getParent, types } from "mobx-state-tree";
import ReferralAPI from "../api/ReferralAPI";
import AuthAPI from "../api/AuthDev";
import { LS_KEY_FOLKS_USER_ID } from "../utils/constants";
import { EP_ROOT as __ } from "../utils/endpoints";

export const referralStore = types
  .model({ referralCode: "" })
  .actions((self) => ({
    getReferralCode: flow(function* getReferralCode(emitToast = false) {
      const folksUserId = localStorage.getItem(LS_KEY_FOLKS_USER_ID) || "";
      try {
        const res = yield AuthAPI.get(__.AUTH_SERVICE.GET_REFERRAL_CODE(folksUserId));
        if (res?.status === 200) {
          self.referralCode = res?.data?.referral_code;
          if (emitToast) {
            getParent(self).notificationStore.triggerSuccess("Referral code fetch successful!");
          }
          return Promise.resolve({ success: true, msg: res?.data?.message });
        } else {
          throw new Error("Something went wrong while fetching referral code!");
        }
      } catch (error) {
        if (emitToast) {
          getParent(self).notificationStore.triggerError("Something went wrong while fetching referral code!");
        }
        return Promise.reject(error);
      }
    }),

    validateReferralCode: flow(function* validateReferralCode(referralCode) {
      try {
        const res = yield ReferralAPI.get(__.REFERRAL_SERVICE.VAL_REF_CODE(referralCode));
        if (res?.status === 200) {
          getParent(self).notificationStore.triggerSuccess("A valid referral code found.");
          return Promise.resolve({ success: true });
        } else {
          getParent(self).notificationStore.triggerInfo("Can't fetch the referral data.");
          throw new Error(`Can't fetch the referral data`);
        }
      } catch (error) {
        if (error.response) {
          if (error.response?.status === 400) {
            getParent(self).notificationStore.triggerError("Invalid Referral code.");
          } else if (error.response?.status === 404) {
            getParent(self).notificationStore.triggerError("Bad URL");
          }
        } else {
          getParent(self).notificationStore.triggerInfo("Can't reach the servers, Retry Again.");
        }
        return Promise.reject(error);
      }
    }),

    loadReferralData: flow(function* loadReferralData() {
      try {
        yield self.getReferralCode();
      } catch (e) {
        // Do something.
      }

      return Promise.resolve({ success: true });
    }),
  }))
  .views((self) => ({}));
