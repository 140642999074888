import { useLayoutEffect, useState } from "react";

const useWindowSize = (mediaContainerRef) => {
  const [parentDivWidth, setParentDivWidth] = useState(mediaContainerRef?.current?.offsetWidth);

  function debounce(func) {
    let timer;
    return function (event) {
      if (timer) clearTimeout(timer);
      timer = setTimeout(func, 100, event);
    };
  }

  const handleSize = () => {
    setParentDivWidth(mediaContainerRef?.current?.offsetWidth);
  };

  useLayoutEffect(() => {
    try {
      handleSize();

      window.addEventListener(
        "resize",
        debounce(function (e) {
          handleSize();
        })
      );
    } catch (error) {}
    return () => window.removeEventListener("resize", handleSize);
  }, []);

  return parentDivWidth;
};

export default useWindowSize;
