import styled from "styled-components";
import { Layout } from "antd";

const { Header } = Layout;

export const FolksMobileHeader = styled(Header)`
  &&& {
    position: fixed;
    z-index: 999;

    width: 100%;
    height: 14vw;

    background: rgb(19, 18, 18);
    padding: 0px;
  }
  /* Media Query for low resolution  Tablets, Ipads */
  @media (min-width: 481px) and (max-width: 767px) {
    &&& {
      height: 8vw;
    }
  }

  /* Media Query for Tablets Ipads portrait mode */
  @media (min-width: 768px) and (max-width: 1024px) {
    &&& {
      height: 8vw;
    }
  }
`;
