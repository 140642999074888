import React from "react";
import styles from "./ContentYouLike.module.css";
import { observer } from "mobx-react-lite";
import PlaylistCard from "../search/component/playlist-card/PlaylistCard";
import { FaRegArrowAltCircleLeft } from "react-icons/fa";

const PlaylistExpand = ({ list, title, type, handleClose, onShare, onEdit, onDeletePlaylist }) => {
  return (
    <section
      className={styles.contentSection}
      style={{
        padding: 0,
      }}
    >
      <header
        className={styles.backButtonContainer}
        style={{
          display: "flex",
          alignItems: "center",
        }}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          handleClose();
        }}
      >
        <FaRegArrowAltCircleLeft className={styles.arrowStyles} />
        <h2
          className={styles.sectionTitle}
          style={{
            marginBottom: 0,
          }}
        >
          {title}
        </h2>
      </header>
      <div className={styles.expandGrid}>
        {list
          ?.filter((item) => item?.author_name?.length)
          .map((data) => {
            return (
              <PlaylistCard
                key={data.watchlist_id}
                playlistItemData={data}
                onShare={onShare}
                title={title}
                type={type}
                onEdit={onEdit}
                onDeletePlaylist={onDeletePlaylist}
              />
            );
          })}
      </div>
    </section>
  );
};

export default observer(PlaylistExpand);
