import axios from "axios";
import { LS_KEY_FOLKS_USER_TOKEN } from "../utils/constants";
import { handleRefreshToken } from "./helperAPI";

const axiosInstance = () => {
  const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
  const defaultOptions = {
    baseURL: `http://54.83.167.214:8045`,
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: folksUserToken ? `Bearer ${folksUserToken}` : "",
    },
  };
  const instance = axios.create(defaultOptions);
  const response = processInstanceRequest(instance);
  return processInstanceResponse(response);
};

const processInstanceRequest = (instance) => {
  instance.interceptors.request.use((config) => {
    const folksUserToken = localStorage.getItem(LS_KEY_FOLKS_USER_TOKEN) || "";
    config.headers["Authorization"] = folksUserToken ? `Bearer ${folksUserToken}` : "";
    return config;
  });
  return instance;
};

const processInstanceResponse = (instance) => {
  instance.interceptors.response.use(undefined, async (error) => {
    // All the 401 requests require token refresh...
    return handleRefreshToken(error, instance);
    // return Promise.reject(error);
  });
  return instance;
};

export default axiosInstance();
