import { Row } from "antd";
import React from "react";
import styles from "./logo.module.css";
import logo from "../../../assets/images/folks-logo-with-title.svg";
import { useHistory } from "react-router-dom";

export default function Logo() {
  const history = useHistory();
  return (
    <Row justify="center">
      <div className={styles.logoContainer}>
        <img src={logo} className={styles.iconLogo} alt="" onClick={() => history.push("/")} />
      </div>
    </Row>
  );
}
